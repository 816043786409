import React, { useState, useEffect, useCallback, useContext } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { UserContext } from "../../../UserContext";
import { Select, MenuItem } from "@material-ui/core";
import { VideoCall } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .location-select": {
      border: "none !important",
    },
  },
  conferencingSelect: {
    width: "100%",
    padding: "10px",
    textAlign: "left",
  },
}));
/**
 * @description Component for choosing Event Location
 * @export
 * @param {*} {location, getLocation, readOnly }
 * @return {React.ReactElement}
 */
export default function EventLocationSelect({
  location,
  onLocationChange,
  readOnly,
}) {
  const classes = useStyles();
  const { settings } = useContext(UserContext);
  const [locations, setLocations] = useState<string[]>([]);
  const [conferencing, setConferencing] = React.useState("GoogleMeet");

  const handleConferencingChange = (event) => {
    setConferencing(event.target.value);
  };

  useEffect(() => {
    const locations: string[] = [];
    (settings?.locations ?? []).forEach((location) => {
      locations.push(location);
    });
    setLocations(locations);
  }, [settings]);

  const [searchBarLocation, setSearchBarLocation] = useState(
    (locations.some((l) => l === location) ? "" : location) || ""
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (readOnly) {
        e.preventDefault();
      }
    },
    [readOnly]
  );

  useEffect(() => {
    if (!readOnly && !location && locations.length) {
      onLocationChange(locations[0]);
    }
    // eslint-disable-next-line
  }, []);

  const handleFavoriteLocationSelected = useCallback(
    (value) => {
      setSearchBarLocation("");
      if (onLocationChange) onLocationChange(value);
    },
    [onLocationChange]
  );

  const handleLocationChange = useCallback(
    (value) => {
      if (locations.some((l) => l === value)) {
        setSearchBarLocation("");
      } else {
        setSearchBarLocation(value);
      }
      if (onLocationChange) onLocationChange(value);
    },
    [locations, onLocationChange]
  );

  return (
    <div
      className={`text-field mb-3 ${classes.root}`}
      onMouseMove={handleMouseMove}
    >
      <PlacesAutocomplete
        value={
          (locations.some((l) => l === searchBarLocation)
            ? ""
            : searchBarLocation) || ""
        }
        onChange={handleLocationChange}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            className="autocomplete-select select"
            style={{ border: "1px solid #DCF2FE" }}
          >
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getInputProps({
                placeholder: "Add location",
                className: "ellipsis location-select",
                disabled: readOnly,
              })}
            />
            {(suggestions.length || loading) && (
              <div className="autocomplete-dropdown-container select__menu">
                {loading && <div className="loading">Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "select__option--is-selected select__option"
                    : "select__option";
                  return (
                    <div
                      key={suggestion.description}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
      {locations.map((thisLocation) => (
        <label key={thisLocation} className="">
          <input
            type="radio"
            radioGroup="location"
            checked={location === thisLocation}
            onChange={() => handleFavoriteLocationSelected(thisLocation)}
          />{" "}
          {thisLocation}
        </label>
      ))}

      {location && location.length > 0 && !locations.includes(location) && (
        <label key={location} className="">
          <input
            type="radio"
            radioGroup="location"
            checked
            onChange={() => handleFavoriteLocationSelected(location)}
          />{" "}
          {location}
        </label>
      )}

      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ display: "table" }}>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              marginRight: 10,
            }}
          >
            <VideoCall />
          </div>
        </div>
        <div style={{ display: "table", width: "100%" }}>
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={conferencing}
              onChange={handleConferencingChange}
              className={classes.conferencingSelect}
            >
              <MenuItem value="GoogleMeet">Google Meet</MenuItem>
              <MenuItem value="OtherLink">Other conferencing link</MenuItem>
              <MenuItem value="Phone">Phone</MenuItem>
              <MenuItem value="NoConferencing">No conferencing</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
