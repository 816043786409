import React, { FC, useCallback, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import AvailableDays from "../../../components/settings/AvailableDays/AvailableDays";
import AvailableHours from "../../../components/settings/AvailableHours/AvailableHours";
import { UserContext } from "../../../UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { workingHoursDayDefault } from "@trysmarty/shared";
import {
  Collapse,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  showcaseRelevantLinks: {
    marginBottom: "25px",
  },
}));

const WorkingHours: FC = () => {
  const styles = useStyles();
  const { settings, changeSettings } = useContext(UserContext);

  const toggleWorkingHoursEnabled = useCallback(() => {
    changeSettings({
      ...settings!,
      workingHoursEnabled: !settings?.workingHoursEnabled,
    });
  }, [changeSettings, settings]);

  const handleAddInterval = useCallback(
    (day: number): void => {
      const newWorkingHours = [...settings!.workingHours];
      const day_ = newWorkingHours[day];
      if (day_ !== null) {
        const start = day_[day_.length - 1].end;
        if (start < 24) {
          const end = start + 0.5;
          day_.push({ start, end });
        }
      }
      changeSettings({ ...settings!, workingHours: newWorkingHours });
    },
    [settings, changeSettings]
  );

  const handleRemoveInterval = useCallback(
    (day: number, interval: number): void => {
      const newWorkingHours = [...settings!.workingHours];
      const day_ = newWorkingHours[day];
      if (day_ !== null) {
        day_.splice(interval, 1);
      }
      changeSettings({ ...settings!, workingHours: newWorkingHours });
    },
    [changeSettings, settings]
  );

  // noinspection DuplicatedCode
  const handleUpdateStartTime = useCallback(
    (day: number, interval: number, start: number) => {
      const newWorkingHours = [...settings!.workingHours];
      const workingHourDay = newWorkingHours[day];
      if (workingHourDay !== null) {
        workingHourDay[interval].start = start;
      }
      changeSettings({ ...settings!, workingHours: newWorkingHours });
    },
    [changeSettings, settings]
  );

  // noinspection DuplicatedCode
  const handleUpdateEndTime = useCallback(
    (day: number, interval: number, end: number) => {
      const newWorkingHours = [...settings!.workingHours];
      const workingHourDay = newWorkingHours[day];
      if (workingHourDay !== null) {
        workingHourDay[interval].end = end;
      }
      changeSettings({ ...settings!, workingHours: newWorkingHours });
    },
    [changeSettings, settings]
  );

  const handleToggleDay = useCallback(
    (day: number) => {
      const newWorkingHours = [...settings!.workingHours];
      newWorkingHours[day] = !newWorkingHours[day]
        ? workingHoursDayDefault
        : null;
      changeSettings({ ...settings!, workingHours: newWorkingHours });
    },
    [changeSettings, settings]
  );

  if (!settings) return null;

  return (
    <Grid container alignItems="center" direction="column">
      <Grid
        item
        className={styles.showcaseRelevantLinks}
        style={{ width: "100%" }}
      >
        <Typography variant="h6">Edit Working Hours</Typography>
        Select your preferences on when you like to work.
        <br />
        Smarty uses this information to offer you recommendations personalized
        for you!
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <FormControlLabel
          control={
            <Switch
              checked={settings.workingHoursEnabled}
              onChange={toggleWorkingHoursEnabled}
              color="primary"
            />
          }
          label={
            <Typography style={{ width: 170 }}>
              {settings.workingHoursEnabled
                ? "Working Hours Enabled"
                : "Working Hours Disabled"}
            </Typography>
          }
        />
      </Grid>
      <Grid item>
        <Collapse in={settings.workingHoursEnabled}>
          <AvailableDays
            workingHours={settings.workingHours}
            onToggleDay={handleToggleDay}
          />
          <AvailableHours
            workingHours={settings.workingHours}
            onAddInterval={handleAddInterval}
            onRemoveInterval={handleRemoveInterval}
            onUpdateStartTime={handleUpdateStartTime}
            onUpdateEndTime={handleUpdateEndTime}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default WorkingHours;
