import {
  IAvailableSlots,
  IBookAppointmentParams,
  MeetingLink$Params,
} from "@trysmarty/shared";
import { del, get, patch, post } from "./network";

export const getAvailableSlots = async (
  username: string,
  timeZone: string,
  start?: Date,
  end?: Date,
  meetingLinkIdOrSlug?: string
): Promise<IAvailableSlots> => {
  let url = `/backend/api/v1/scheduling/${username}/meet/${meetingLinkIdOrSlug}/slots?`;
  if (timeZone) url += `tz=${timeZone}&`;
  if (start) url += `start=${start.getTime()}&`;
  if (end) url += `end=${end.getTime()}&`;
  return get(url);
};

export const bookAppointment = async (
  timezone: string,
  params: IBookAppointmentParams
): Promise<{
  success: boolean;
  reason?: string;
  changeToken?: string;
}> => post(`/backend/api/v1/scheduling/events?tz=${timezone}`, params);

export const updateBooking = async (
  timezone: string,
  changeToken: string,
  params: IBookAppointmentParams
): Promise<{
  success: boolean;
  reason?: string;
}> => patch(`/api/v1/scheduling/events/${changeToken}?tz=${timezone}`, params);

export const cancelBooking = async (
  changeToken: string
): Promise<{
  success: boolean;
  reason?: string;
}> => del(`/backend/api/v1/scheduling/events/${changeToken}`);

export const getMeetingLinkEventParams = async (
  changeToken: string
): Promise<MeetingLink$Params> =>
  get(`/backend/api/v1/scheduling/events/${changeToken}`);

export const getMeetingLinkParams = async (
  username: string,
  meetingLinkSlug: string
): Promise<MeetingLink$Params> =>
  get(`/backend/api/v1/scheduling/${username}/meet/${meetingLinkSlug}`);
