import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PublicIcon from "@material-ui/icons/Public";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import RoomIcon from "@material-ui/icons/Room";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  page: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  bookedIcon: {
    backgroundColor: "#ff0081",
    width: 56,
    height: 56,
    transform: "rotate(-15deg)",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      backgroundColor: "#fff",
      width: 56,
      height: 56,
      borderRadius: theme.spacing(1),
      transform: "rotate(15deg)",
    },
  },
  done: {
    marginTop: theme.spacing(2),
    backgroundColor: "#ff0081",
    width: 120,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff3a9f",
    },
  },
  primaryButton: {
    background: "#FF339A",
    color: "white",
    display: "inline-block",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    fontWeight: 500,
    fontSize: "14px",
    width: "150px",
    textAlign: "center",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    margin: "0 10px",
  },
  bookedEventDetails: {
    borderBottom: "solid 1px #F1F1F1",
    width: "100%",
    margin: "100px",
    textAlign: "left",
    fontSize: "18px",
    padding: "50px 50px 25px",
    lineHeight: "50px",
  },
}));

interface IComponentProps {
  eventTextDate: string;
  reset: () => void;
  timeZone: string;
  eventChangeToken?: string;
  eventName: string;
  location?: string;
  handleReschedule: () => void;
  handleDelete: () => void;
}

export const EventBookedView = ({
  eventTextDate,
  reset,
  timeZone,
  eventChangeToken,
  eventName,
  location,
  handleReschedule,
  handleDelete,
}: IComponentProps) => {
  const styles = useStyles();

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const handleDeleteClick = (): void => {
    handleDelete();
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        <Grid
          className={styles.page}
          style={{ height: 300 }}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          wrap="nowrap"
        >
          <div className={styles.bookedEventDetails}>
            {eventName && (
              <div style={{ fontSize: 18, textAlign: "center" }}>
                <CheckCircleOutlineIcon
                  width={6}
                  height={6}
                  style={{ marginRight: 15, color: "rgb(30 103 143)" }}
                />
                {eventName}
              </div>
            )}
            <div style={{ fontSize: 18 }}>
              <CalendarTodayIcon
                style={{ marginRight: 15, color: "#4ABAF9" }}
              />
              {`${eventTextDate}`}
            </div>
            <div>
              <PublicIcon
                width={6}
                height={6}
                style={{ marginRight: 15, color: "#4ABAF9" }}
              />
              {timeZone}
            </div>
            {location && (
              <div>
                <RoomIcon
                  width={6}
                  height={6}
                  style={{ marginRight: 15, color: "#4ABAF9" }}
                />
                {location}
              </div>
            )}
          </div>
          <div className={styles.bookedIcon}>
            <CheckBoxIcon viewBox="4 4 16 16" htmlColor="#4ABAF9" />
          </div>
          <Typography gutterBottom variant="h5" style={{ fontWeight: 600 }}>
            Confirmed
          </Typography>
          <Typography gutterBottom>
            A calendar invitation has been sent to your email address
          </Typography>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Button
                onClick={handleConfirmDialogOpen}
                className={styles.primaryButton}
              >
                CANCEL
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                onClick={handleScheduleAnotherMeet}
                className={styles.done}
              >
                SCHEDULE ANOTHER MEETING
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                onClick={!eventChangeToken ? reset : handleReschedule}
                className={styles.primaryButton}
              >
                RESCHEDULE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ConfirmDialog
        title="Delete Event"
        content="The event will be permanently deleted"
        open={confirmDialogOpen}
        onConfirm={handleDeleteClick}
        onCancel={handleConfirmDialogClose}
      />
    </>
  );
};
