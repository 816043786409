import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Badge, Grid, Typography } from "@material-ui/core";
import { CalendarItem } from "@trysmarty/shared";
// todo(hmassad): text overflow ellipsis on calendar names

interface CalendarSelectorWebProps {
  calendarIds: string[];
  calendarList: CalendarItem[];
  onCalendarIdsChange: (calendarId: string, added: boolean) => void;
  fixedPrimary: boolean;
  fullWidth: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: "100%",
  },
  labelColor: {
    height: 8,
    width: 8,
    minWidth: 8,
    marginRight: 8,
    borderRadius: 50,
    display: "inline-block",
  },
  searchInput: {
    paddingLeft: 14,
    width: "100%",

    marginBottom: 6,
  },
  calendarOptions: {
    width: "100%",

    // maxHeight: 150,
    overflow: "hidden",
    // overflowY: "scroll",
  },
}));

export const CalendarSelectorWeb = ({
  calendarIds = [],
  calendarList = [],
  onCalendarIdsChange = () => {},
  fixedPrimary,
  fullWidth,
}: CalendarSelectorWebProps) => {
  const [calendars, setCalendars] = useState<CalendarItem[]>([]);

  const selectedIds = useMemo(() => {
    const primaryCalendarId = calendars.find((x) => x.primary)?.id;
    if (primaryCalendarId) {
      const newCalendarIds = [...calendarIds];
      const index = calendarIds.findIndex((x) => x === "primary");
      newCalendarIds[index] = primaryCalendarId;
      return newCalendarIds;
    }
    return calendarIds;
  }, [calendarIds, calendars]);

  const styles = useStyles({ fullWidth });

  useEffect(() => {
    setCalendars(calendarList);
  }, [calendarList]);

  const calendarSelectorItems = useMemo(
    () =>
      calendars?.map((calendar: CalendarItem) => {
        const checked =
          (fixedPrimary && calendar.primary) ||
          selectedIds.indexOf(calendar.id) > -1;
        return (
          <MenuItem
            id="calendar-selector-menuitem"
            key={calendar.id}
            value={calendar.id}
            disabled={fixedPrimary && calendar.primary}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onCalendarIdsChange) {
                onCalendarIdsChange(calendar.id, !checked);
              }
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={fixedPrimary && calendar.primary}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled color="primary" />}
                  checked={checked}
                />
              }
              label={
                <Box display="flex" flexDirection="row" alignItems="center">
                  <span
                    className={styles.labelColor}
                    style={{ backgroundColor: calendar.color }}
                  />
                  <ListItemText primary={calendar.name} title={calendar.name} />
                </Box>
              }
            />
          </MenuItem>
        );
      }),
    [
      calendars,
      fixedPrimary,
      selectedIds,
      styles.labelColor,
      onCalendarIdsChange,
    ]
  );

  if (!calendars) return null;

  return (
    <Grid container direction="column" className={styles.container}>
      <Grid item style={{ paddingLeft: 14, marginBottom: 10 }}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Badge color="secondary" badgeContent={`${selectedIds.length}`}>
              <DateRangeIcon color="primary" fontSize="small" />
            </Badge>
          </Grid>
          <Grid item>
            {fullWidth ? (
              <Typography variant="h6">Calendars</Typography>
            ) : (
              <Typography>Calendars</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <FormControl
          variant="outlined"
          className={styles.formControl}
          style={{ maxWidth: fullWidth ? "100%" : 300 }}
        >
          <Grid container direction="column" alignItems="flex-start">
            <Grid
              item
              className={styles.calendarOptions}
              style={{ maxWidth: fullWidth ? "100%" : 300 }}
            >
              {calendarSelectorItems}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};
