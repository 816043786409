import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IComponentProps {
  title: string;
  description: string;
  content: string;
  onCopy: () => void;
  onClose: () => void;
}

const useStyles = makeStyles({
  container: {
    background: "#272728",
    color: "white",
    maxWidth: 450,
    width: "100%",
    minHeight: 350,
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  code: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    padding: 10,
    backgroundColor: "#3b3b3d",
    fontFamily: "monospace",
  },
});

export const CopyEmbedCode = ({
  title,
  description,
  content,
  onCopy,
  onClose,
}: IComponentProps) => {
  const styles = useStyles();

  const handleCopy = () => {
    onCopy();
  };
  return (
    <Card className={styles.container}>
      <CardHeader
        action={<CloseIcon onClick={onClose} fontSize="small" />}
        title={title}
      />
      <CardContent>
        <Typography style={{ textAlign: "justify" }}>{description}</Typography>
        <pre className={styles.code}>{content}</pre>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleCopy}
          style={{ marginLeft: "auto" }}
        >
          Copy Code
        </Button>
      </CardActions>
    </Card>
  );
};
