import Button from "@material-ui/core/Button";
import React, { FC } from "react";

const TestErrorBoundary: FC = () => {
  const handleError = () => {
    try {
      const state = {
        items: ["Banana", "Mango"],
      };

      const wrongItem = state.items[3].toLowerCase();

      console.debug(wrongItem);
    } catch (err) {
      throw new Error("ErrorBoundary success");
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleError}>
        Hangle ERROR
      </Button>
      ;
    </div>
  );
};

export default TestErrorBoundary;
