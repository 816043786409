import React, { FC, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import UserBanner from "../components/UserBanner";
import { getUser } from "../services/User";
import { User$DTO } from "@trysmarty/shared";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    gridRowGap: theme.spacing(2),
  },
}));

const AboutPage: FC = () => {
  const history = useHistory();
  const styles = useStyles();
  const { username } = useParams<Record<string, string | undefined>>();
  const [user, setUser] = useState<User$DTO>();

  useEffect(() => {
    if (!username) return;
    async function init() {
      // todo(hmassad) replace with NotFound component after merging backend calls into a single one
      try {
        setUser(await getUser(username));
      } catch (error) {
        history.push(`/404`);
      }
    }
    init().then();
  }, [username, history]);

  if (!user) return null;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <UserBanner
          email={user.email}
          firstName={user.firstName}
          lastName={user.lastName}
          image={user.image}
          links={user.links}
        />
      </div>
    </div>
  );
};

export default AboutPage;
