import React from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import { useHours } from "../../../configs/ui";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: 120,
    textAlign: "center",
    margin: "0 10px",
  },
}));

interface IComponentProps extends SelectProps {
  filterOptions?: ((time: number) => boolean) | undefined;
}

const TimeSelect: React.FC<IComponentProps> | null = ({
  filterOptions,
  ...rest
}: IComponentProps) => {
  const hours = useHours();
  const classes = useStyles();
  const filter = (time: number) => (filterOptions ? filterOptions(time) : true);

  return (
    <FormControl className={classes.root}>
      <Select {...rest}>
        {hours.map(({ value, label }) =>
          filter(value) ? (
            <MenuItem key={label + value} value={value}>
              {label}
            </MenuItem>
          ) : null
        )}
      </Select>
    </FormControl>
  );
};

export default TimeSelect as React.ComponentType<IComponentProps>;
