import React from "react";
import { IAvailableSlots } from "@trysmarty/shared";
import { Grid, Typography } from "@material-ui/core";
import { CalendarView, TimesView } from "..";

interface IComponentProps {
  minDate?: Date;
  maxDate?: Date;
  disabled: boolean;
  availableSlots: IAvailableSlots | undefined;
  selectedDate: Date;
  selectedTime: number;
  timeZone: string;
  onSelectedDateChange: (date: Date) => void;
  onSelectedTimeChange: (number) => void;
  onConfirmTime: () => void;
}

export const EventDateView = ({
  minDate,
  maxDate,
  disabled,
  availableSlots,
  timeZone,
  selectedDate,
  selectedTime,
  onSelectedDateChange,
  onSelectedTimeChange,
  onConfirmTime,
}: IComponentProps) => (
  <Grid container spacing={2}>
    <Grid
      item
      sm={6}
      xs={12}
      container
      justifyContent="center"
      className="calendar-datepicker-container"
    >
      <Typography variant="h6">Select a Date & Time</Typography>
      <CalendarView
        availableSlots={availableSlots}
        selectedDate={selectedDate}
        onSelectedDateChange={onSelectedDateChange}
        timeZone={timeZone}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Grid>
    <Grid item sm={6} xs={12} className="calendar-datepicker-confirm">
      <TimesView
        disabled={disabled}
        selectedDate={selectedDate}
        timeZone={timeZone}
        availableSlots={availableSlots}
        selectedTime={selectedTime}
        onSelectedTimeChange={onSelectedTimeChange}
        onConfirm={onConfirmTime}
      />
    </Grid>
  </Grid>
);
