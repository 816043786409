import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import { SmartyTheme } from "@trysmarty/components";
import "./styles/styles.css";

import { App } from "./App";
import { UserProvider } from "./UserContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import { initTracking } from "./services/Tracking";

initTracking();

ReactDOM.render(
  <ThemeProvider theme={SmartyTheme}>
    <UserProvider>
      <CssBaseline />
      <App />
    </UserProvider>
  </ThemeProvider>,
  document.getElementById("app")
);
