import React from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  FormControlLabel,
  Grid,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VideocamIcon from "@material-ui/icons/Videocam";

interface LocationSelectorProps {
  location?: string;
  hangoutLink?: string;
  locations: string[];
  onLocationChange: (location?: string) => void;
  onOpenActiveLink: (location?: string) => void;
  readonly: boolean;
}

const useStyles = makeStyles(() => ({
  root: {},
  autocomplete: {
    width: 219,
  },
}));

export const LocationSelector = ({
  location,
  hangoutLink,
  locations = [],
  onLocationChange = () => {},
  onOpenActiveLink = () => {},
  readonly = false,
}: LocationSelectorProps) => {
  const styles = useStyles();

  const {
    ready,
    value,
    suggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleRadioClick = (event) => {
    onLocationChange(event.target.value);
  };


  return (
    <div className={`${styles.root}`}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {hangoutLink && (
            <div style={{ display: "flex", marginBottom: 10 }}>
              <VideocamIcon
                style={{
                  marginRight: 10,
                  color: "#808080",
                  verticalAlign: "middle",
                }}
              />
              <div
                onClick={() => onOpenActiveLink(hangoutLink)}
                style={{ color: "#0645AD", cursor: "pointer" }}
              >
                {hangoutLink}
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            aria-label="locationOption"
            name="locationOption"
            value={location}
          >
            <FormControlLabel
              control={<Radio onClick={handleRadioClick} value={value} />}
              label={
                <Autocomplete
                  className={styles.autocomplete}
                  freeSolo
                  disabled={readonly}
                  options={suggestions.data.map((p) => p.description)}
                  getOptionLabel={(description) => description}
                  onChange={(e, option) => {
                    // is triggered when a location is selected from the drop down list
                    e.preventDefault();
                    e.stopPropagation();
                    setValue(option || "", false);
                    onLocationChange(option || "");
                    clearSuggestions();
                  }}
                  onInputChange={(e, option) => {
                    // is triggered when the user types in the box
                    setValue(option);
                    onLocationChange(option || "");
                  }}
                  renderOption={(option: string) => (
                    <ListItemText
                      key={option}
                      title={option}
                      primary={<Typography noWrap>{option}</Typography>}
                    />
                  )}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      disabled={!ready}
                      variant="outlined"
                    />
                  )}
                />
              }
            />
            {locations &&
              locations.map((favLocation) => (
                <FormControlLabel
                  key={favLocation}
                  value={favLocation}
                  control={
                    <Radio disabled={readonly} onClick={handleRadioClick} />
                  }
                  label={favLocation}
                />
              ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </div>
  );
};
