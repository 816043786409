import React, { FC, useState, useContext, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  useMediaQuery,
  Snackbar,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ChevronLeft, ChevronRight, Schedule, Event } from "@material-ui/icons";
import draggingTutorial from "../../assets/draggingAvailabilityTutorial.gif";
import {
  Calendar,
  IconCalendarSelector,
  CalendarViewType,
} from "@trysmarty/components";
import { UserContext } from "../../UserContext";
import {
  getCalendarEventList,
  getCalendarList,
  getFreeTimeSlots,
} from "../../services/temporary";
import {
  Calendar$DTO,
  CalendarEvent$DTO,
  colorLuminance,
  IInterval,
  TimePeriod,
} from "@trysmarty/shared";
import { SlotsMode } from "../calendar/CalendarPage/ShareAvailability";
import { CreateEventMode } from "../calendar/CalendarPage/CalendarEvent";

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  button: {
    width: "125px",
    margin: "10px",
  },
  calHeader: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 10%)",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    "& .calendar-bar .react-datepicker-wrapper": {
      display: "table-cell",
      verticalAlign: "middle",
    },
    "& button": {
      backgroundColor: "transparent",
      border: "transparent",
    },
  },
  calContainer: {
    height: "calc(100vh - 80px)",
  },
  calLeftSide: {
    maxWidth: (props: { mobile: boolean; narrow: boolean }): string =>
      props.mobile ? "100%" : "calc(100vw - 400px)",
    flexBasis: (props: { mobile: boolean; narrow: boolean }): string =>
      props.mobile ? "100%" : "calc(100vw - 400px)",
  },
  calendarPicker: {
    textAlign: "center",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 10px)",
    "& .MuiPickersBasePicker-container": {
      backgroundColor: "#fafafa",
    },
    "& .MuiPickersDatePickerRoot-toolbar": {
      height: "107px",
      backgroundColor: "#fafafa",
      "& .MuiPickersToolbarText-toolbarTxt": {
        color: "black",
      },
      "& .MuiTypography-root": {
        color: "black",
      },
    },
    "& .MuiPickersBasePicker-pickerView": {
      maxWidth: 325,
      margin: "auto",
    },
    "& .MuiPickersCalendarHeader-iconButton": {
      backgroundColor: "#fafafa",
    },
  },
  dottedSpaced: {
    height: "5px",
    textAlign: "center",
    lineHeight: "100px",
    background: "linear-gradient(90deg,#d8d8d8 50%,hsla(0,0%,100%,0) 0)",
    backgroundPosition: "top,100%,bottom,0",
    backgroundRepeat: "repeat-x,repeat-y",
    backgroundSize: "10px 1px,1px 10px",
    fontSize: "1px",
    marginTop: "20px",
  },
});

const recurringOptions = [
  { value: "dates", label: "Specific dates" },
  { value: "weekdays", label: "Days of the week" },
];

const MyCalendarPage: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const narrow = useMediaQuery("(max-width: 950px)");

  const { settings } = useContext(UserContext);
  const duration = settings?.duration ?? 60;

  const classes = useStyles({ mobile, narrow });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorMessageToShow, setErrorMessageToShow] = React.useState("");
  const [modeView, setModeView] = React.useState("nothing");
  const [calendarList, setCalendarList] = useState<Calendar$DTO[]>([]);
  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    if (settings) {
      setTimeZone(settings.timezone);
    }
  }, [settings]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const [isRecurring, setRecurring] = useState(false);

  const [valView, setCalView] = useState<CalendarViewType>("WEEK");

  const [current, setCurrent] = useState(new Date());
  const startDate = moment().add(1, "day").startOf("days").format("YYYY-MM-DD");
  const endDateDate = moment().startOf("weeks").endOf("weeks").toDate();
  const endDate =
    Math.abs(moment(startDate).diff(endDateDate, "days")) < 3
      ? moment(endDateDate).add(1, "week").format("YYYY-MM-DD")
      : moment(endDateDate).format("YYYY-MM-DD");

  const [rangePeriodWorkingHours, setRangePeriodWorkingHours] = useState([
    startDate,
    endDate,
  ]);
  const [workingHourSlots, setWorkingHourSlots] = useState<IInterval<Date>[]>(
    []
  );

  const [isUsingWorkingHours, setUsingWorkingHours] = useState(false);

  const handleSetUseWorkingHoursSlots = (val) => {
    setUsingWorkingHours(val);
  };

  const handleSetStartWHDate = () => {};

  const handleSetEndWHDate = () => {};

  useEffect(() => {
    if (
      !isUsingWorkingHours ||
      !rangePeriodWorkingHours[0] ||
      !rangePeriodWorkingHours[1]
    )
      return;
    let newStartDate = new Date(rangePeriodWorkingHours[0]);
    newStartDate = new Date(newStartDate.setDate(newStartDate.getDate() - 1));

    const calendarIds = calendarList.map((item) => item.id);
    const meetingLinkToMake = {
      rangeStart: newStartDate.getTime(),
      rangeEnd: moment(rangePeriodWorkingHours[1]).toDate().getTime(),
      duration,
      eventName: "Select a time on my calendar",
      contacts: [],
      validContacts: [],
      invalidContacts: [],
      singleTime: true,
      startDate: moment(rangePeriodWorkingHours[0]).toDate(),
      oneTimeLink: true,
      calendars: calendarIds,
      timePeriod: TimePeriod.RANGE,
      timeZone,
    };
    getFreeTimeSlots(
      duration,
      timeZone,
      moment(rangePeriodWorkingHours[0]).toDate().getTime(),
      moment(rangePeriodWorkingHours[1]).toDate().getTime(),
      JSON.stringify(meetingLinkToMake)
    )
      .then((freeTimeIntervals) => {
        setWorkingHourSlots(
          freeTimeIntervals.slots.map((x) => ({
            start: new Date(x.start),
            end: new Date(x.end),
          }))
        );
      })
      .catch((error) => {
        // todo handle getnotes error, message?
        console.error(error);
      });
  }, [
    calendarList,
    duration,
    isUsingWorkingHours,
    rangePeriodWorkingHours,
    timeZone,
  ]);

  const calendarArgs = {
    pixelsPerHour: 48,
    scrollbarWidth: 22,
    defaultDurationMinutes: 60,
    editionMode: "SLOTS",
    minHour: 0,
    maxHour: 24,
    step: 15,
    minEventDurationMinutes: 15,
    minEventHeight: 10,
    dayMinWidth: 60,
    topHandleHeight: 10,
    bottomHandleHeight: 10,
    hoursContainerWidth: 40,
    defaultEventColor: "#05283A",
    defaultEventBackgroundColor: "#DBF1FE",
    slotColor: "#05283A",
    slotBackgroundColor: "#DCF2FE",
    recommendedSlotColor: "#05283A",
    recommendedSlotBackgroundColor: "#99FF99AF",
    minSummaryDuration: 30,
    onCreateEvent: () => {},
    onChangeEvent: () => {},
    onDeleteEvent: () => {},
    onCreateSlot: () => {},
    onChangeSlot: () => {},
    onDeleteSlot: () => {},
    onRecommendedSlotClick: () => {},
    onCreateWeeklyRecurringSlot: () => {},
    onChangeWeeklyRecurringSlot: () => {},
    onDeleteWeeklyRecurringSlot: () => {},
    slots: [],
    recommendedSlots: [],
  };

  const [events, setEvents] = useState<CalendarEvent$DTO[]>([]);
  const [slots, setSlots] = useState<any[]>([]);
  const [timezoneToUse, setTimezoneToUse] = useState(settings?.timezone);
  const [calendars, setCalendars] = useState<string[]>([]);
  const [recurringOption, setRecurringOption] = useState(recurringOptions[0]);

  // todo(hmassad): prevent double call at start
  useEffect(() => {
    const fetchEvents = async (timeMin, timeMax) => {
      const eventList = await getCalendarEventList({
        timeMin,
        timeMax,
        contacts: [],
        calendars,
      });
      setEvents(
        eventList.map((event) => ({
          ...event,
          borderColor: event.background
            ? colorLuminance(event.background, -0.2)
            : "#3f50b5",
          backgroundColor: event.background || "#3f50b5",
          color: event.foreground || "#ffffff",
          allDay: !!event.start.date,
          start: event.start.date
            ? moment(event.start.date).tz(timeZone).toDate()
            : new Date(event.startTime),
          end: event.start.date ? null : new Date(event.endTime),
        }))
      );
    };

    // todo(hmassad): keep using the date that's selected
    const startOfWeek = moment()?.tz(settings!.timezone!)?.startOf("weeks");
    const endOfWeek = startOfWeek?.clone()?.add(2, "weeks");

    fetchEvents(startOfWeek, endOfWeek).catch(console.error);
  }, [calendars, settings, timeZone]);

  useEffect(
    () => {
      setTimezoneToUse(settings?.timezone);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings]
  );

  useEffect(() => {
    if (timeZone) {
      const startDate = moment()
        .tz(timeZone)
        .add(1, "day")
        .startOf("days")
        .format("YYYY-MM-DD");
      const endDateDate = moment().startOf("weeks").endOf("weeks").toDate();
      const endDate =
        Math.abs(moment(startDate).diff(endDateDate, "days")) < 3
          ? moment(endDateDate).add(1, "week").format("YYYY-MM-DD")
          : moment(endDateDate).format("YYYY-MM-DD");
      setRangePeriodWorkingHours([startDate, endDate]);
    }
  }, [timeZone]);

  useEffect(() => {
    if (mobile) {
      setCalView("SINGLE_DAY");
    } else if (narrow) {
      setCalView("WORK_WEEK");
    }
  }, [mobile, narrow]);

  useEffect(() => {
    getCalendarList().then((availableCalendarList) => {
      setCalendarList(availableCalendarList);

      const calendarIds: string[] = [];
      (settings?.selectedCalendarIds ?? []).forEach((calendarId) => {
        if (calendarId === "primary") {
          const calendar = availableCalendarList.find((c) => c.primary);
          if (calendar) calendarIds.push(calendar.id);
        }
        if (availableCalendarList.find((c) => c.id === calendarId))
          calendarIds.push(calendarId);
      });
      setCalendars(calendarIds);
    });
  }, [settings]);

  const handleCalendarsChange = (calendarId: string, added: boolean) => {
    setCalendars((calendars) => {
      const newSelectedCalendarIds = [...calendars];
      if (added) {
        if (newSelectedCalendarIds.indexOf(calendarId) < 0) {
          newSelectedCalendarIds.push(calendarId);
        }
      } else {
        newSelectedCalendarIds.splice(
          newSelectedCalendarIds.indexOf(calendarId),
          1
        );
      }
      return newSelectedCalendarIds;
    });
  };

  const handlePrevWeekClick = useCallback(() => {
    const d = new Date(current.getTime());
    d.setDate(d.getDate() - 7);
    setCurrent(d);
  }, [current]);

  const handleNextWeekClick = useCallback(() => {
    const d = new Date(current.getTime());
    d.setDate(d.getDate() + 7);
    setCurrent(d);
  }, [current]);

  const handleTodayClick = () => {
    setCurrent(new Date());
  };

  const handleCurrentDateChange = (date) => {
    setCurrent(date);
  };

  const handleCalViewChange = (event) => {
    setCalView(event.target.value);
  };

  const handleModeViewChange = (event) => {
    setModeView(event.target.value);
  };

  const handleCreateSlot = useCallback(
    (event) => {
      if (isRecurring) {
        setSlots((prev) => [...prev, event]);
      } else {
        const slotsInPast = slots.filter(
          (slot) => moment(slot.start) < moment()
        );
        if (slotsInPast.length > 1) {
          // how did it get in this state?
          setErrorMessageToShow(
            "Trying to share your availability? You're choosing a time in the past."
          );
          setOpenSnackbar(true);
          return;
        }
        if (moment(event.start) < moment()) {
          if (slots.length > 0) {
            setErrorMessageToShow(
              "Trying to share your availability? You're choosing a time in the past."
            );
            setOpenSnackbar(true);
            return;
          }
          // event is in the past so can only be in create mode
          setModeView("create_event");
        }
        if (slots.length > 0 || workingHourSlots.length > 0) {
          setModeView("share_availability");
        } else {
          setModeView("create_event");
        }
        if (isUsingWorkingHours) {
          setWorkingHourSlots((prev) => [...prev, event]);
        } else {
          setSlots((prev) => [...prev, event]);
        }
      }
    },
    [isRecurring, slots, workingHourSlots.length, isUsingWorkingHours]
  );

  const handleChangeSlot = useCallback(
    (originalSlot, updatedSlot) => {
      if (isUsingWorkingHours) {
        setWorkingHourSlots((prev) => {
          const arr = prev.filter((slot) => slot !== originalSlot);
          arr.push({ ...originalSlot, ...updatedSlot });
          return arr;
        });
      } else {
        setSlots((prev) => {
          const arr = prev.filter((slot) => slot !== originalSlot);
          arr.push({ ...originalSlot, ...updatedSlot });
          return arr;
        });
      }
    },
    [isUsingWorkingHours]
  );

  const handleDeleteSlot = useCallback(
    (deletedSlot) => {
      if (isUsingWorkingHours) {
        setWorkingHourSlots((prev) =>
          prev.filter((event1) => deletedSlot !== event1)
        );
      } else {
        setSlots((prev) => prev.filter((event1) => deletedSlot !== event1));
        if (slots.filter((event1) => deletedSlot !== event1).length === 0) {
          setModeView("nothing");
        }
      }
    },
    [isUsingWorkingHours, slots]
  );

  const handleSetRecurringClick = (isRecurring) => {
    setRecurring(isRecurring);
    if (isRecurring) {
      setRecurringOption(recurringOptions[1]);
    } else {
      setRecurringOption(recurringOptions[0]);
    }
  };

  const handleSlotsTabChange = (newValue) => {
    if (newValue === "events") {
      handleSetRecurringClick(true);
      setUsingWorkingHours(false);
      setSlots([]);
    } else if (newValue === "ranges") {
      handleSetRecurringClick(false);
      setSlots([]);
    } else if (newValue === "polls_days") {
      handleSetRecurringClick(true);
      setUsingWorkingHours(false);
      setSlots([]);
    } else {
      handleSetRecurringClick(false);
      setUsingWorkingHours(false);
      setSlots([]);
    }
  };

  if (!events) return null;
  return (
    <Grid container>
      <Grid item xs={!mobile ? 8 : 12} sm={6} md={7} lg={9} xl={9}>
        <div className={classes.calHeader}>
          {!narrow && !mobile && (
            <div style={{ padding: "5px" }}>
              <IconCalendarSelector
                calendarIds={calendars}
                calendarList={calendarList}
                onCalendarIdsChange={handleCalendarsChange}
                fixedPrimary={false}
              />
            </div>
          )}
          <div className="flex align-center" style={{ display: "inline-flex" }}>
            <button
              type="button"
              onClick={handlePrevWeekClick}
              className="btn-icon-cornered secondary-matisse"
            >
              <ChevronLeft />
            </button>

            <div className="pos-rel calendar-bar" style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  fontSize: "21px",
                }}
              >
                {moment(current).format("MMM yyy")}
              </div>
            </div>

            <button
              type="button"
              onClick={handleNextWeekClick}
              className="btn-icon-cornered secondary-matisse"
            >
              <ChevronRight />
            </button>
          </div>
          <div>
            <FormControl variant="outlined" style={{ margin: 5 }}>
              <Select
                value={valView}
                input={<OutlinedInput />}
                displayEmpty
                onChange={handleCalViewChange}
              >
                <MenuItem value="SINGLE_DAY">Day</MenuItem>
                <MenuItem value="WEEK">Week</MenuItem>
                <MenuItem value="WORK_WEEK">Work Week</MenuItem>
                <MenuItem value="THREE_DAYS">Three Days</MenuItem>
              </Select>
            </FormControl>
            {!mobile && !narrow && (
              <button
                type="button"
                onClick={handleTodayClick}
                className={`btn-icon btn-icon-medium ${
                  moment().isSame(moment(current), "days")
                    ? "secondary-blue"
                    : "btn-icon-secondary"
                } mr-4`}
                style={{
                  paddingLeft: 14,
                  paddingRight: 14,
                  width: "auto",
                  border: "solid 1px #cccccc",
                  margin: 5,
                  borderRadius: "5px",
                  cursor: "pointer",
                  height: "56px",
                }}
              >
                <span className="icon">Today</span>
              </button>
            )}
          </div>
        </div>
        <div className={classes.calContainer}>
          <Calendar
            currentDate={current}
            calendarView={valView}
            timeZone={timezoneToUse || "America/Argentina/Buenos_Aires"}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...calendarArgs}
            events={events}
            slots={isUsingWorkingHours ? workingHourSlots : slots}
            weeklyRecurringSlots={slots}
            editionMode="SLOTS"
            calendarType={isRecurring ? "GENERIC" : "SPECIFIC"}
            onCreateSlot={handleCreateSlot}
            onChangeSlot={handleChangeSlot}
            onDeleteSlot={handleDeleteSlot}
            onCreateWeeklyRecurringSlot={handleCreateSlot}
            onChangeWeeklyRecurringSlot={handleChangeSlot}
            onDeleteWeeklyRecurringSlot={handleDeleteSlot}
          />
        </div>
      </Grid>
      {!mobile && (
        <Grid
          item
          xs={4}
          sm={6}
          md={5}
          lg={3}
          xl={3}
          className={classes.calendarPicker}
        >
          {modeView === "nothing" ? (
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="static"
                  openTo="date"
                  value={current}
                  onChange={handleCurrentDateChange}
                />
              </MuiPickersUtilsProvider>
              <div>
                <div style={{ textAlign: "left", padding: 10, fontSize: 16 }}>
                  Try dragging to get started on creating an event or sharing
                  your availability.
                </div>
                <img
                  alt=""
                  src={draggingTutorial}
                  style={{ maxWidth: "200px", margin: "auto" }}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  className={classes.button}
                  color="primary"
                  startIcon={<Schedule />}
                  onClick={() => {
                    setModeView("share_availability");
                    setUsingWorkingHours(true);
                  }}
                >
                  Share Availability
                </Button>
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  startIcon={<Event />}
                  onClick={() => setModeView("create_event")}
                >
                  Create Event
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ height: "100%", overflow: "hidden" }}>
              <FormControl variant="outlined" style={{ margin: 5 }}>
                <Select
                  value={modeView}
                  input={<OutlinedInput />}
                  displayEmpty
                  onChange={handleModeViewChange}
                  style={{ width: "100%", background: "white" }}
                >
                  <MenuItem value="create_event">Create Event</MenuItem>
                  <MenuItem value="share_availability">
                    Share Availability
                  </MenuItem>
                </Select>
              </FormControl>
              {modeView === "share_availability" && (
                <div style={{ height: "calc(100% - 60px)" }}>
                  <SlotsMode
                    slots={isUsingWorkingHours ? workingHourSlots : slots}
                    timeZone={settings ? settings.timezone : ""}
                    calendarList={calendarList}
                    startDateString={rangePeriodWorkingHours[0]}
                    endDateString={rangePeriodWorkingHours[1]}
                    setUseWorkingHoursSlotsParent={
                      handleSetUseWorkingHoursSlots
                    }
                    setStartWHDate={handleSetStartWHDate}
                    setEndWHDate={handleSetEndWHDate}
                    useWorkingHours={isUsingWorkingHours}
                    handleTabChange={handleSlotsTabChange}
                  />
                </div>
              )}
              {modeView === "create_event" && (
                <CreateEventMode slot={slots[0]} calendarList={calendarList} />
              )}
            </div>
          )}
        </Grid>
      )}
      {mobile && (
        <Grid item xs={12} className={classes.calendarPicker}>
          mobile experience
        </Grid>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info">
          {errorMessageToShow}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default MyCalendarPage;
