import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Typography,
  Paper,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  AccordionActions,
  Button,
} from "@material-ui/core";
import {
  Today,
  Beenhere,
  ExpandMore,
  CheckCircleOutline,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: "#ff0081",
    borderRadius: "5px",
    width: 120,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff3a9f",
    },
  },
  margin: {
    marginLeft: 25,
  },
  fuchsiaButton: {
    backgroundColor: "#FF339A",
    fontSize: 18,
    width: 150,
    color: "white",
    "&:hover": {
      backgroundColor: "#b2236b",
    },
    "&:active": {
      backgroundColor: "#ff5bae",
    },
  },
  buttonFont: {
    width: 150,
    fontSize: 18,
  },
  // fullWidth: {
  //   width: "100%",
  // },
  // floatingWidth: {
  //   width: "600px",
  //   top: "25%",
  //   left: "calc(50vw - 300px)",
  // },
  paperHeader: {
    display: "inline-flex",
    alignItems: "center",
    borderBottom: "1px solid #e7e7e7",
    width: "100%",
    padding: "15px 10px",
  },
  gridItem: {
    margin: 10,
    width: (props: { mobile: boolean }): string =>
      props.mobile ? "calc(100% - 20px)" : "auto",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "20%",
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

const MyDashboardPage: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const styles = useStyles({ mobile });

  return (
    <div className={styles.root}>
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item className={styles.gridItem}>
          <TodayCalendarWidget />
        </Grid>
        <Grid item className={styles.gridItem}>
          <GettingStartedWidget />
        </Grid>
      </Grid>
    </div>
  );
};

const TodayCalendarWidget: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const styles = useStyles({ mobile });
  const [moduleWidth, setModuleWidth] = useState("100%");

  useEffect(() => {
    if (mobile) {
      setModuleWidth("100%");
    } else {
      setModuleWidth("600px");
    }
  }, [mobile]);

  return (
    <Paper className={styles.wrapper} style={{ width: moduleWidth }}>
      <div>
        <div className={styles.paperHeader}>
          <Today style={{ marginRight: 10 }} />
          <Typography variant="h4" component="h2">
            Schedule Faster
          </Typography>
        </div>
        <div className={styles.paperHeader}>
          <Typography variant="h6" component="h2">
            We take the work out of connecting with others so you can focus on
            what matters.
          </Typography>
        </div>
        <div style={{ padding: 10 }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={styles.heading}>
                <CheckCircleOutline
                  style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                    marginRight: 10,
                  }}
                />
                2 mins
              </Typography>
              <Typography className={styles.secondaryHeading}>
                Tour Scheduling Links
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                See what you can do with Smarty&apos;s scheduling features.
                Smarty enables the same functions as other tools, but offers the
                advantage of bringing everything together in one organized
                place.
              </Typography>
            </AccordionDetails>
            <Divider />
            <AccordionActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button size="small">Watch a Video</Button>
              <Button size="small" color="primary">
                Start Tour
              </Button>
            </AccordionActions>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={styles.heading}>
                <CheckCircleOutline
                  style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                    marginRight: 10,
                  }}
                />
                2 mins
              </Typography>
              <Typography className={styles.secondaryHeading}>
                Customize Your Preferences
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Smarty will auto-check your calendar for optimal times to meet
                and add new events as they are scheduled. Personalize your
                experience by choosing which calendars to use, your typical
                availability, and more.
              </Typography>
            </AccordionDetails>
            <Divider />
            <AccordionActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button size="small">Watch a Video</Button>
              <Button size="small" color="primary">
                Set Up Now
              </Button>
            </AccordionActions>
          </Accordion>
        </div>
      </div>
    </Paper>
  );
};

const GettingStartedWidget: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const styles = useStyles({ mobile });
  const [moduleWidth, setModuleWidth] = React.useState("100%");

  useEffect(() => {
    if (mobile) {
      setModuleWidth("100%");
    } else {
      setModuleWidth("600px");
    }
  }, [mobile]);

  return (
    <Paper className={styles.wrapper} style={{ width: moduleWidth }}>
      <div>
        <div className={styles.paperHeader}>
          <Beenhere style={{ marginRight: 10 }} />
          <Typography variant="h4" component="h2">
            Getting Started
          </Typography>
        </div>
        <div style={{ padding: 10 }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={styles.heading}>
                <CheckCircleOutline
                  style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                    marginRight: 10,
                  }}
                />
                1 min
              </Typography>
              <Typography className={styles.secondaryHeading}>
                Build your first event type
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Define the type of event you want people to schedule with you.
                This includes the duration, location, and your availability.
              </Typography>
            </AccordionDetails>
            <Divider />
            <AccordionActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button size="small">Watch a Video</Button>
              <Button size="small" color="primary">
                Create It Now
              </Button>
            </AccordionActions>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={styles.heading}>
                <CheckCircleOutline
                  style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                    marginRight: 10,
                  }}
                />
                1 min
              </Typography>
              <Typography className={styles.secondaryHeading}>
                Share Times Without Links
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Booking links too impersonal? Copy your availabilities as a text
                message for a personal touch.
              </Typography>
            </AccordionDetails>
            <Divider />
            <AccordionActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button size="small">Watch a Video</Button>
              <Button size="small" color="primary">
                Share Times
              </Button>
            </AccordionActions>
          </Accordion>
        </div>
      </div>
    </Paper>
  );
};

export default MyDashboardPage;
