import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Contact$DTO, isEmail } from "@trysmarty/shared";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  autocomplete: {
    width: "100%",
    maxWidth: 300,
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    maxHeight: (props: { maxHeight?: number }) => props.maxHeight,
    overflow: "auto",
    "& .MuiListItem-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export const GuestsSelector = ({
  contacts = [],
  onChange = () => {},
  onSearch,
  maxHeight,
  readOnly,
}: {
  contacts: Contact$DTO[];
  onChange: (contact: Contact$DTO[]) => void;
  onSearch: (contact: string) => Promise<Contact$DTO[]>;
  maxHeight?: number;
  readOnly: boolean;
}) => {
  const styles = useStyles({ maxHeight });

  const [guestsList, setGuestsList] = useState<Contact$DTO[]>([]);

  // use to re render the autocomplete component to clear the input text after click a option
  const [reRender, setReRender] = useState<number>(new Date().getTime());

  const handleRemove = (contact: Contact$DTO) => {
    const newContacts = [...contacts];
    newContacts.splice(newContacts.indexOf(contact), 1);
    onChange([...newContacts]);
  };

  const handleOnChange = (contact: Contact$DTO) => {
    if (!isEmail(contact.email)) {
      return;
    }
    const newContacts = [
      ...contacts.filter((ad) => contact.email !== ad.email),
      contact,
    ];

    onChange([...newContacts]);
    setReRender(new Date().getTime());
  };

  const handleContactsSearch = useCallback(
    async (searchValue: string) => {
      if (onSearch) {
        const newGuestsList = await onSearch(searchValue);
        setGuestsList([...newGuestsList]);
      }
    },
    [onSearch]
  );

  useEffect(() => {
    handleContactsSearch("").catch();
  }, [handleContactsSearch]);

  return (
    <Grid container direction="column" className={styles.container}>
      <Grid item>
        <Autocomplete
          id="guests-selector-autocomplete"
          disabled={readOnly}
          key={reRender}
          className={styles.autocomplete}
          freeSolo
          options={guestsList.filter((ad) =>
            contacts.every((fd) => fd.email !== ad.email)
          )}
          getOptionLabel={(option) => option.email || ""}
          onChange={(e, newValue) => {
            e.preventDefault();
            e.stopPropagation();
            if (newValue) {
              if (typeof newValue === "string") {
                handleOnChange({
                  name: undefined,
                  email: newValue,
                  img: undefined,
                });
              } else {
                handleOnChange(newValue);
              }
            }
          }}
          onInputChange={(e, newValue) => {
            handleContactsSearch(newValue).catch();
          }}
          onClick={() => {
            handleContactsSearch("").catch();
          }}
          renderOption={(contact) => (
            <>
              <ListItemAvatar>
                <Avatar src={contact.img} alt={contact.email} />
              </ListItemAvatar>
              <ListItemText
                title={`${contact.name}\n${contact.email}`}
                primary={
                  <Typography noWrap>{`${
                    contact.name ? contact.name : contact.email
                  }`}</Typography>
                }
                secondary={
                  contact.name && (
                    <Typography noWrap>{contact.email}</Typography>
                  )
                }
              />
            </>
          )}
          renderInput={(props) => <TextField {...props} />}
        />
      </Grid>

      <Grid item className={`${styles.autocomplete} ${styles.list}`}>
        {contacts.length > 0 && (
          <List id="guests-selector-list">
            {contacts.map((contact: Contact$DTO) => (
              <ListItem key={contact.email} disableGutters>
                <ListItemAvatar style={{ minWidth: 0 }}>
                  <Avatar
                    src={contact.img}
                    alt={contact.email}
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  title={`${contact.name}\n${contact.email}`}
                  primary={
                    <Typography
                      noWrap
                      style={{ fontSize: 14, paddingRight: 30 }}
                    >
                      {contact.email}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemove(contact)}
                  >
                    <Clear style={{ width: 18, padding: 0 }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
    </Grid>
  );
};
