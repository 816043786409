import React, { useCallback, useMemo } from "react";
import moment, { max } from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import { IAvailableSlots } from "@trysmarty/shared";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    textAlign: "center",
    alignItems: "center",
    gridGap: theme.spacing(1),
    width: "100%",
    minWidth: 280,
    maxWidth: 340,

    "& > *:nth-child(11)": {
      gridColumnStart: (props: { startSpace: number }): number =>
        props.startSpace + 1,
    },
  },
  month: {
    gridColumnStart: 1,
    gridColumnEnd: 6,
    fontWeight: "bold",
  },
  button: {
    width: "fit-content",
    justifySelf: "center",
    background: "#1E678F",
    color: "white",
    borderRadius: "10px",
  },
  date: {
    minWidth: "40px !important",
    width: 40,
    height: 40,
    borderRadius: "10px",
    justifySelf: "center",
    padding: 0,
  },
  today: {
    position: "absolute",
    fontSize: "2em",
    top: 6,
  },
}));

interface IComponentProps {
  availableSlots: IAvailableSlots | undefined;
  selectedDate: Date;
  onSelectedDateChange: (date: Date) => void;
  timeZone: string;
  minDate?: Date;
  maxDate?: Date;
}

export const CalendarView = ({
  availableSlots,
  selectedDate,
  onSelectedDateChange = () => {},
  timeZone,
  minDate,
  maxDate,
}: IComponentProps) => {
  const styles = useStyles({
    startSpace: moment(selectedDate)
      .tz(timeZone)
      .clone()
      .startOf("months")
      .weekday(),
  });

  const availableDays: number[] = useMemo(() => {
    if (!availableSlots || !availableSlots.slots) return [];
    const tzDate = moment(selectedDate).tz(timeZone);
    return availableSlots.slots
      .map((slot) => moment(slot).tz(timeZone))
      .filter((slot) => slot.isSame(tzDate, "months"))
      .map((slot) => slot.date());
  }, [availableSlots, timeZone, selectedDate]);

  const canGoToPreviousMonth = useCallback((): boolean => {
    const startOfMonth = moment(selectedDate).tz(timeZone).startOf("months");
    if (minDate && startOfMonth.isBefore(moment(minDate))) return false;
    return moment(startOfMonth).isAfter(moment().tz(timeZone).startOf("days"));
  }, [minDate, selectedDate, timeZone]);

  const canGoToNextMonth = useCallback((): boolean => {
    const nextMonth = moment(selectedDate)
      .tz(timeZone)
      .startOf("months")
      .add(1, "months");
    return !(maxDate && nextMonth.isAfter(moment(maxDate)));
  }, [maxDate, selectedDate, timeZone]);

  const handlePrevMonthClick = useCallback((): void => {
    if (!canGoToPreviousMonth()) return;
    const prevMonthSelectedDate = moment(selectedDate)
      .tz(timeZone)
      .subtract(1, "months");
    if (!minDate) {
      onSelectedDateChange(prevMonthSelectedDate.toDate());
    } else {
      onSelectedDateChange(
        max(prevMonthSelectedDate, moment(minDate)).toDate()
      );
    }
  }, [
    canGoToPreviousMonth,
    minDate,
    onSelectedDateChange,
    selectedDate,
    timeZone,
  ]);

  const handleNextMonthClick = useCallback((): void => {
    if (!canGoToNextMonth()) return;
    onSelectedDateChange(
      moment(selectedDate).tz(timeZone).add(1, "months").toDate()
    );
  }, [canGoToNextMonth, onSelectedDateChange, selectedDate, timeZone]);

  return (
    <div className={styles.grid}>
      <Typography align="left" className={styles.month} aria-label="date">
        {moment(selectedDate).format("MMMM YYYY")}
      </Typography>
      <IconButton
        size="small"
        className={styles.button}
        disabled={!canGoToPreviousMonth()}
        onClick={handlePrevMonthClick}
        aria-label="go to previous month"
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        size="small"
        className={styles.button}
        onClick={handleNextMonthClick}
        disabled={!canGoToNextMonth()}
        aria-label="go to next month"
      >
        <ChevronRightIcon />
      </IconButton>
      {moment.weekdays().map((day) => (
        <Typography key={day} variant="caption">
          {day.slice(0, 3).toUpperCase()}
        </Typography>
      ))}
      {availableDays && availableDays.length
        ? Array.from(
            { length: moment(selectedDate).tz(timeZone).daysInMonth() },
            (v, k) => k + 1
          ).map((dayOfMonth) => {
            const currentDate = moment(selectedDate)
              .tz(timeZone)
              .date(dayOfMonth);
            const isToday = currentDate.isSame(moment().tz(timeZone), "days");
            const isSelected = currentDate.isSame(
              moment(selectedDate).tz(timeZone),
              "days"
            );
            const disabled = !availableDays.includes(dayOfMonth);
            return (
              <Button
                fullWidth
                disableElevation
                disabled={disabled}
                color={disabled ? "default" : "primary"}
                variant={
                  // eslint-disable-next-line no-nested-ternary
                  isSelected ? "contained" : disabled ? "text" : "outlined"
                }
                onClick={(): void =>
                  onSelectedDateChange &&
                  onSelectedDateChange(currentDate.toDate())
                }
                className={styles.date}
                key={dayOfMonth}
              >
                {dayOfMonth}
                {isToday && <span className={styles.today}>&#8226;</span>}
              </Button>
            );
          })
        : Array.from(
            { length: moment(selectedDate).tz(timeZone).daysInMonth() },
            (v, k) => k + 1
          ).map((dayOfMonth) => (
            <Button
              disableElevation
              disabled
              color="default"
              variant="text"
              className={styles.date}
              key={dayOfMonth}
            >
              {dayOfMonth}
            </Button>
          ))}
    </div>
  );
};
