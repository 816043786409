import React, { FC } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import one from "../../assets/animations/one.gif";
import two from "../../assets/animations/two.gif";
import three from "../../assets/animations/three.gif";
import four from "../../assets/animations/four.gif";
import five from "../../assets/animations/five.gif";
import six from "../../assets/animations/six.gif";
import seven from "../../assets/animations/seven.gif";
import eight from "../../assets/animations/eight.gif";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    height: (props: { mobile: boolean }) => (props.mobile ? "100%" : "auto"),
    minHeight: "100vh",
    flexGow: 1,
    padding: theme.spacing(2),
    "& h1": {
      fontWeight: 1000,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    padding: theme.spacing(4),
    marginTop: 10,
    backgroundColor: "black",
    color: "white",
  },
  imgContainer: {
    textAlign: "center",
  },
  features: {
    maxWidth: 1000,
    "& h6": {
      marginTop: theme.spacing(4),
    },
  },
  verticalAlignCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    fontWeight: 1000,
    width: "80%",
    margin: "0 10%",
    height: 56,
  },
  link: {
    display: "flex",
    lineHeight: "2em",
  },
  guide: {
    display: "flex",
    lineHeight: "2em",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& svg": {
      marginRight: theme.spacing(2),
    },
  },
  img: {
    width: "100%",
    height: "auto",
    borderRadius: theme.spacing(1),
  },
  backdrop: {
    width: "100%",
    backgroundColor: "#64b5f6",
    transform: "skew(0deg, -5deg) scale(0.85, 1.05)",
    borderRadius: "10%",
    "& > *": {
      transform: "skew(0deg, 4deg) scale(calc(1/0.85), calc(1/1.05))",
    },
  },
  stepper: {
    "& .MuiMobileStepper-dot": {
      backgroundColor: "white",
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: "rgb(255, 0, 129)",
    },
  },
}));

const items = [
  { label: "Schedule Easier", value: "Item ", img: one },
  { label: "Reply Faster", value: "Item ", img: two },
  { label: "Network Smarter", value: "Item ", img: three },
  { label: "Anywhere Online", value: "Item ", img: four },
  { label: "Contextual Predictions ", value: "Item ", img: five },
  { label: "1-Click Group Polls ", value: "Item ", img: six },
  { label: "Team Insights", value: "Item ", img: seven },
  { label: "All In One Place ", value: "Item ", img: eight },
];

const FeaturesCarousel: FC = () => {
  const classes = useStyles({ mobile: false });

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = items.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid item xs={12} md={8} style={{ backgroundColor: "black" }}>
      <Paper square elevation={0} className={classes.header}>
        <Typography component="h1" variant="h3" style={{ fontWeight: 800 }}>
          {items[activeStep].label}
        </Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        className={classes.stepper}
        style={{ backgroundColor: "black", color: "white" }}
        activeStep={activeStep}
        nextButton={
          <Button
            size="large"
            onClick={handleNext}
            style={{ color: "white" }}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="large"
            onClick={handleBack}
            style={{ color: "white" }}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
      <AutoPlaySwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={15000}
      >
        {items.map((step, index) => (
          <div key={step.label} className={classes.imgContainer}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                className={classes.img}
                src={step.img}
                alt={step.label}
                style={{
                  height: "75vh",
                  width: "auto",
                  maxWidth: "95vw",
                }}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </Grid>
  );
};

export default FeaturesCarousel;
