import React, { useMemo, useState } from "react";
import moment from "moment-timezone";
import { IAvailableSlots } from "@trysmarty/shared";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridGap: theme.spacing(1),
    maxHeight: 375,
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: theme.spacing(1),
    paddingTop: "10px",
  },
  selectedDateHeaders: {
    fontSize: "16px",
    fontWeight: 500,
    marginTop: 5,
    marginBottom: 8,
  },
  timePickerTimeSlot: {
    color: "#1E678F !important",
    border: "solid 1px #DCF2FE !important",
  },
  noTimesAvailable: {
    textAlign: "center",
    marginTop: "150px",
    color: "#1E678F",
    fontSize: "16px",
  },
}));

interface IComponentProps {
  disabled: boolean;
  availableSlots: IAvailableSlots | undefined;
  selectedDate: Date;
  timeZone: string;
  selectedTime: number;
  onSelectedTimeChange: (number) => void;
  onConfirm: () => void;
}

export const TimesView = ({
  disabled,
  selectedDate,
  availableSlots,
  timeZone,
  selectedTime,
  onSelectedTimeChange,
  onConfirm,
}: IComponentProps) => {
  const styles = useStyles();
  const selectedDateSlots = useMemo(() => {
    if (!availableSlots || !availableSlots.slots) return [];
    const tzDate = moment(selectedDate).tz(timeZone);
    const now = moment();
    return availableSlots.slots
      .map((slot) => moment(slot).tz(timeZone))
      .filter((slot) => slot.isSame(tzDate, "days"))
      .filter((slot) => slot.isAfter(now));
  }, [selectedDate, availableSlots, timeZone]);

  return (
    <Grid>
      <div className={styles.selectedDateHeaders}>
        {moment(selectedDate).format("dddd MMMM D")}
      </div>
      <Grid className={styles.root}>
        {selectedDateSlots.length ? (
          selectedDateSlots.map((slot) => (
            <Grid container key={slot.valueOf()} wrap="nowrap">
              <Button
                disabled={disabled}
                className={styles.timePickerTimeSlot}
                variant="outlined"
                onClick={() => onSelectedTimeChange(slot.valueOf())}
                fullWidth
              >
                {slot.format("h:mm a")}
              </Button>
              <Slide
                direction="left"
                in={selectedTime === slot.valueOf()}
                mountOnEnter
                unmountOnExit
              >
                <Button
                  disabled={disabled}
                  color="primary"
                  variant="contained"
                  onClick={() => onConfirm()}
                  fullWidth
                  style={{ marginLeft: 8 }}
                  disableElevation
                >
                  Confirm
                </Button>
              </Slide>
            </Grid>
          ))
        ) : (
          <Grid container direction="column" justifyContent="center">
            <div className="noTimeAvailable">
              No available times for this date.
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
