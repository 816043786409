import React from "react";
import LinkIcon from "../../../components/profile/LinkIcon";
import { makeStyles } from "@material-ui/core/styles";

interface IComponentProps {
  links: string[];
}

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "left",
    marginTop: "10px",
  },
}));

const LinksDrawer: React.FC<IComponentProps> | null = ({
  links,
}: IComponentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {links.map((link) => (
        <LinkIcon key={link} link={link} />
      ))}
    </div>
  );
};

export default LinksDrawer as React.ComponentType<IComponentProps>;
