/* eslint-disable dot-notation */
import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Paper, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: (props: { mobile: boolean }): string =>
      props.mobile ? "100%" : "600px",
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: "#ff0081",
    borderRadius: "5px",
    width: 120,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff3a9f",
    },
  },
  margin: {
    marginLeft: 25,
  },
  fuchsiaButton: {
    backgroundColor: "#FF339A",
    fontSize: 18,
    width: 150,
    color: "white",
    "&:hover": {
      backgroundColor: "#b2236b",
    },
    "&:active": {
      backgroundColor: "#ff5bae",
    },
  },
  buttonFont: {
    width: 150,
    fontSize: 18,
  },
  // fullWidth: {
  //   width: "100%",
  // },
  // floatingWidth: {
  //   width: "600px",
  //   top: "25%",
  //   left: "calc(50vw - 300px)",
  // },
  paperHeader: {
    display: "inline-flex",
    alignItems: "center",
    borderBottom: "1px solid #e7e7e7",
    width: "100%",
    padding: "15px 10px",
  },
  gridItem: {
    margin: 10,
    width: (props: { mobile: boolean }): string =>
      props.mobile ? "calc(100% - 20px)" : "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

const MyDashboardPage: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const styles = useStyles({ mobile });

  return (
    <div className={styles.root}>
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item className={styles.gridItem}>
          <TodayCalendarWidget />
        </Grid>
      </Grid>
    </div>
  );
};

const TodayCalendarWidget: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const styles = useStyles({ mobile });

  // useEffect(() => {
  //   if (settings) {
  //     const startOfToday = moment()
  //       .tz(settings.timezone)
  //       .startOf("day")
  //       .toDate();
  //     const endOfToday = moment().tz(settings.timezone).endOf("day").toDate();
  //     getCalendarEventList({
  //       timeMin: startOfToday,
  //       timeMax: endOfToday,
  //       calendars: ["primary"],
  //       contacts: "", // TODO
  //       timezone: settings.timezone,
  //     }).then((eventList) => {
  //       setEvents(
  //         eventList.map((event) => ({
  //           ...event,
  //           borderColor: colorLuminance(event.background, -0.2),
  //           backgroundColor: event.background,
  //           color: event.foreground,
  //           allDay: !!event.start.date,
  //           start: event.start.date
  //             ? moment.tz(event.start.date, settings.timezone).toDate()
  //             : new Date(event.startTime),
  //           end: event.start.date ? null : new Date(event.endTime),
  //         }))
  //       );
  //     });
  //   }
  // }, [email, settings]);

  return (
    <Paper className={styles.wrapper}>
      {/* <div>
        <div className={styles.paperHeader}>
          <Today style={{ marginRight: 10 }} />
          <Typography variant="h5" component="h2">
            Today
          </Typography>
        </div>
        <div>
          events missing conferencing details, guests
          {events.map((e, index) => (
            <>
              {settings &&
                moment(e["startTime"])
                  .tz(settings.timezone)
                  .diff(moment(), "minutes") > -30 && (
                  <Accordion
                    defaultExpanded={
                      moment(e["startTime"])
                        .tz(settings.timezone)
                        .diff(moment(), "minutes") < 30
                    }
                    style={{ margin: 10 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={styles.heading}>
                        <AccessTime
                          style={{
                            verticalAlign: "middle",
                            display: "inline-block",
                            marginRight: 10,
                          }}
                        />
                        {settings &&
                          (moment(e["startTime"])
                            .tz(settings.timezone)
                            .diff(moment(), "minutes") > 60 ? (
                            <>
                              {moment(e["startTime"])
                                .tz(settings.timezone)
                                .diff(moment(), "hours")}{" "}
                              hours until
                            </>
                          ) : (
                            <>
                              {moment(e["startTime"])
                                .tz(settings.timezone)
                                .diff(moment(), "minutes") < 0 ? (
                                <>
                                  Started <br />
                                  {moment().diff(
                                    moment(e["startTime"]).tz(
                                      settings.timezone
                                    ),
                                    "minutes"
                                  )}{" "}
                                  minutes ago
                                </>
                              ) : (
                                <>
                                  {moment(e["startTime"])
                                    .tz(settings.timezone)
                                    .diff(moment(), "minutes")}{" "}
                                  minutes until
                                </>
                              )}
                            </>
                          ))}
                      </Typography>
                      <Typography className={styles.secondaryHeading}>
                        {e["summary"]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {moment(e["startTime"]).format("MMM D")},{" "}
                        {moment(e["startTime"]).format("h:mm a")} -{" "}
                        {moment(e["endTime"]).format("h:mm a")}
                        <br />
                        <br />
                        {e["description"]}
                      </Typography>
                    </AccordionDetails>

                    {e["location"] && (
                      <>
                        <Divider />
                        <AccordionActions
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {e["location"]}
                        </AccordionActions>
                      </>
                    )}
                  </Accordion>
                )}
            </>
          ))}
        </div>
      </div> */}
    </Paper>
  );
};

export default MyDashboardPage;
