import React, { useCallback, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TextField from "@material-ui/core/TextField";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Chip from "@material-ui/core/Chip";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { isEmail, Contact$DTO } from "@trysmarty/shared";
import moment from "moment-timezone";
import { Button } from "@material-ui/core";
import { SingleLocationSelector } from "../SingleLocationSelector/SingleLocationSelector";

const nextCodes = [" ", ",", "Enter", ";", "/"];

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  done: {
    marginTop: theme.spacing(2),
    backgroundColor: "#ff0081",
    width: 120,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff3a9f",
    },
  },
  darkButton: {
    background: "#7497AD",
    color: "white",
    display: "inline-block",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    fontWeight: 500,
    fontSize: "14px",
    width: "150px",
    textAlign: "center",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    margin: "0 10px",
  },
  primaryButton: {
    background: "#FF339A",
    color: "white",
    display: "inline-block",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    fontWeight: 500,
    fontSize: "14px",
    width: "150px",
    textAlign: "center",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    margin: "0 10px",
  },
  eventDescriptionField: {
    "& .MuiInput-formControl": {
      height: "auto !important",
    },
    "& textarea": {
      padding: "10px !important",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface IComponentProps {
  selectedDate: Date;
  meetingDurationText: string;
  onBack: () => void;
  onBookMeeting: (payload: {
    name: string;
    emails: string[];
    eventName?: string;
    location?: string;
    notes?: string;
  }) => void;
  onUpdateBookMeeting: (payload: {
    name: string;
    emails: string[];
    eventName?: string;
    location?: string;
    notes?: string;
  }) => void;
  contacts: Contact$DTO[];
  eventChangeToken?: string;
  eventName: string;
  attendees: string[];
  eventTitle: string;
  location?: string;
  notes?: string;
  onChangeEventName: (value: string) => void;
  onChangeAttendees: (value: string[]) => void;
  onChangeEventTitle: (value: string) => void;
  onChangeLocation: (value?: string) => void;
  onChangeNotes: (value: string) => void;
}

export const EventConfirmView = ({
  meetingDurationText,
  selectedDate,
  onBack,
  onBookMeeting,
  onUpdateBookMeeting,
  contacts,
  eventChangeToken,
  eventName,
  attendees,
  eventTitle,
  location,
  notes,
  onChangeEventName,
  onChangeAttendees,
  onChangeEventTitle,
  onChangeLocation,
  onChangeNotes,
}: IComponentProps) => {
  const styles = useStyles();

  const [nameError, setNameError] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState<string>("");
  const [emailsError, setEmailsError] = React.useState<boolean>(false);

  const contactsEmails = useMemo(() => {
    try {
      return contacts?.map((ou) => ou.email) ?? [];
    } catch {
      return [];
    }
  }, [contacts]);

  const handleBackClick = (): void => {
    onBack();
  };

  const handleEventNameChange = (e): void => {
    onChangeEventName(e.target.value);
  };

  const handleNameFocus = (): void => {
    setNameError(false);
  };

  const handleEmailChange = (e): void => {
    setEmail(e.target.value);
  };

  const handleEmailKeyUp = (e): void => {
    if (nextCodes.includes(e.key)) {
      if (isEmail(e.target.value)) {
        const email_: string = e.target.value;
        if (!(attendees.indexOf(email_) > -1))
          onChangeAttendees([...attendees, email_]);
        setEmail("");
      }
    }
  };

  const handleLocationChange = (value?: string) => {
    if (onChangeLocation) onChangeLocation(value);
  };
  const handleEmailFocus = (): void => {
    setEmailsError(false);
  };

  const handleEmailBlur = (e): void => {
    if (isEmail(e.target.value)) {
      const email_ = e.target.value;
      if (!(attendees.indexOf(email_) > -1))
        onChangeAttendees([...attendees, email_]);

      setEmail("");
    }
  };

  const handleEventTitleChange = (e): void => {
    onChangeEventTitle(e.target.value);
  };

  const handleNotesChange = (e): void => {
    onChangeNotes(e.target.value);
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!eventName) {
        setNameError(true);
        return;
      }
      const attendeeEmails = attendees.filter(
        (email_) => contactsEmails.indexOf(email_) < 1
      );
      if (attendeeEmails.length === 0) {
        setEmailsError(true);
        return;
      }
      if (!eventChangeToken)
        onBookMeeting({
          name: eventName,
          emails: attendeeEmails,
          eventName: eventTitle,
          location,
          notes,
        });
      else
        onUpdateBookMeeting({
          name: eventName,
          emails: attendeeEmails,
          eventName: eventTitle,
          location,
          notes,
        });
    },
    [
      eventName,
      attendees,
      onBookMeeting,
      onUpdateBookMeeting,
      eventTitle,
      location,
      notes,
      contactsEmails,
      eventChangeToken,
    ]
  );

  return (
    <>
      <Grid container alignItems="center">
        <IconButton aria-label="change date or time" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>

        <CalendarTodayIcon style={{ marginRight: 8 }} />
        <div style={{ fontSize: 18 }}>
          {`
                ${meetingDurationText}, 
                ${moment(selectedDate).format("dddd MMMM D, YYYY")}
              `}
        </div>
      </Grid>
      <div className={styles.container}>
        <form noValidate autoComplete="off">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                id="name"
                label="Your Name"
                type="input"
                helperText="Please enter your name."
                fullWidth
                required
                onFocus={handleNameFocus}
                error={nameError}
                value={eventName}
                onChange={handleEventNameChange}
              />
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item container spacing={1}>
                {attendees.map((email_, index) => (
                  <Grid key={email_} item>
                    <Fade in>
                      <Chip
                        icon={<AccountCircleOutlinedIcon />}
                        label={email_}
                        onDelete={
                          contactsEmails.indexOf(email_) > -1
                            ? undefined
                            : () => {
                                onChangeAttendees([
                                  ...attendees.filter(
                                    (_, deletedIndex) => deletedIndex !== index
                                  ),
                                ]);
                              }
                        }
                        color="primary"
                        variant="outlined"
                      />
                    </Fade>
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <TextField
                  id="emails"
                  type="email"
                  label="Attendee Email(s)"
                  value={email}
                  onChange={handleEmailChange}
                  onKeyUp={handleEmailKeyUp}
                  onFocus={handleEmailFocus}
                  onBlur={handleEmailBlur}
                  fullWidth
                  helperText="At least one email is required."
                  error={emailsError}
                />
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                id="eventTitle"
                label="Event Title"
                type="input"
                helperText="Enter a name for your event."
                fullWidth
                value={eventTitle}
                onChange={handleEventTitleChange}
              />
            </Grid>
            <Grid item>
              <SingleLocationSelector
                location={location}
                onLocationChange={handleLocationChange}
              />
            </Grid>
            <Grid item>
              <TextField
                className={styles.eventDescriptionField}
                id="notes"
                label="Notes"
                type="input"
                multiline
                rows={4}
                helperText="Please share anything that will help prepare for our meeting."
                fullWidth
                value={notes}
                onChange={handleNotesChange}
              />
            </Grid>
            <Grid item className={styles.footer}>
              <Button onClick={handleBackClick} className={styles.darkButton}>
                Back
              </Button>
              <Button onClick={handleSubmit} className={styles.primaryButton}>
                {eventChangeToken ? "Reschedule Event" : "Schedule Event"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};
