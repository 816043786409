import React, { FC } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/ui/PrivateRoute";
import NotFound from "./pages/NotFound";
import SignInPage from "./pages/SignInPage";
import SignInSuccessPage from "./pages/SignInSuccessPage";
import FeedbackReport from "./pages/FeedbackReport";
import ErrorReport from "./pages/ErrorReport";
import CurrentUserPage from "./pages/CurrentUserPage";
import AboutPage from "./pages/AboutPage";
import GroupPollPage from "./components/group-poll/GroupPollPage";
import CalendarPage from "./components/calendar/CalendarPage/CalendarPage";
import MyCalendarPage from "./components/user-pages/MyCalendarPage";
import MyDashboardPage from "./components/user-pages/MyDashboardPage";
import MyContactsPage from "./components/user-pages/MyContactsPage";
import MyTasksPage from "./components/user-pages/MyTasksPage";
import MyTemplatesPage from "./components/user-pages/MyTemplatesPage";
import MyAutomationsPage from "./components/user-pages/MyAutomationsPage";
import ProductTourPage from "./components/user-pages/ProductTourPage";
import { Grid, useMediaQuery, makeStyles, Theme } from "@material-ui/core";
import { Sidebar } from "./components/Sidebar";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import TestErrorBoundary from "./pages/TestErrorBoundary";
import ExitSurvey from "./components/pages/ExitSurvey/ExitSurvey";
import ExitSurveyReport from "./components/pages/ExitSurveyReport/ExitSurveyReport";

const useStyles = makeStyles<Theme, { bottomRoundedBorders: boolean }>(
  (theme) => ({
    userAvatar: {
      "& .MuiIconButton-root": {
        padding: 0,
      },
      "& .MuiIconButton-root:hover": {
        backgroundColor: "#FF339A !important",
        width: "40px",
        height: "40px",
      },
    },
    appBar: {
      borderBottom: ".0625rem solid #e7e7e7",
      backgroundColor: "white",
      boxShadow: "none",
      color: "black",
      height: "56px",
      position: "fixed",
      "& .MuiToolbar-root": {
        zIndex: 1000,
      },
    },
    "user-container-avatar-banner": {
      backgroundSize: "cover",
      display: "flex",
      borderRadius: ({ bottomRoundedBorders }) =>
        bottomRoundedBorders ? "4px 4px 0 0" : "4px",
      justifyContent: "space-around",
    },
    "margin-auto-important": {
      margin: "auto !important",
      padding: 25,
    },
    avatar: {
      gridColumnStart: 2,
      width: "fit-content",
      height: "auto",
      margin: "auto",
      marginTop: -80,
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    photo: {
      margin: "auto",
      borderRadius: 50,
      width: 36,
      height: 36,
    },
    "user-profile-name": {
      fontSize: 22,
      fontWeight: 500,
    },
    "user-profile-email": {
      fontSize: 14,
    },
    paperBox: {
      margin: 10,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 1px rgb(0 0 0 / 12%)",
    },
    contentWide: {
      paddingLeft: "68px",
      width: "100%",
    },
    contentMobile: {
      width: "100%",
    },
    searchBar: {
      margin: "0 20px 0 0!important",
      fontSize: "32px",
      "& .MuiInput-formControl": {
        margin: "0 20px 0 0!important",
        color: "black",
      },
    },
    buttonNew: {
      backgroundColor: "white",
      border: "solid 1px",
      marginRight: 25,
    },
  })
);

export const App: FC = () => {
  const styles = useStyles({ bottomRoundedBorders: true });
  const mobile = useMediaQuery("(max-width: 650px)");

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Grid container>
          <Grid item>
            <Sidebar />
          </Grid>
          <Grid
            item
            className={mobile ? styles.contentMobile : styles.contentWide}
          >
            <Switch>
              <PrivateRoute exact path="/" component={MyCalendarPage} />
              <PrivateRoute
                exact
                path="/myAccount"
                component={CurrentUserPage}
              />
              <PrivateRoute
                exact
                path="/gettingStarted"
                component={ProductTourPage}
              />
              <PrivateRoute exact path="/calendar" component={MyCalendarPage} />
              <Route exact path="/signin" component={SignInPage} />
              <Route
                exact
                path="/signin/success"
                component={SignInSuccessPage}
              />
              <Route path="/feedback/report" component={FeedbackReport} />
              <Route path="/errors/report" component={ErrorReport} />
              <Route path="/exit-survey/report" component={ExitSurveyReport} />
              <Route path="/exit-survey" component={ExitSurvey} />
              <Route exact path="/u/:username" component={AboutPage} />

              <Route exact path="/dashboard" component={MyDashboardPage} />
              <Route exact path="/contacts" component={MyContactsPage} />
              <Route exact path="/tasks" component={MyTasksPage} />
              {/* <Route exact path="/notes" component={MyNotesPage} /> */}
              <Route exact path="/templates" component={MyTemplatesPage} />
              <Route exact path="/automations" component={MyAutomationsPage} />
              <Route
                path="/u/:username/polls/:groupPollIdOrSlug"
                component={GroupPollPage}
              />
              <Route
                path="/u/:username/meet/:meetingLinkIdOrSlug"
                component={CalendarPage}
              />
              <Route path="/events/:changeToken" component={CalendarPage} />
              <Route
                exact
                path="/testErrorBoundary"
                component={TestErrorBoundary}
              />
              <Route component={NotFound} />
            </Switch>
          </Grid>
        </Grid>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
