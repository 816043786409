import React, { useState, useCallback, FC } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import sadnessGif from "../../../assets/blue-cry.gif";
import { createExitReason } from "../../../services/exitSurvey";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    margin: "auto",
  },
  media: {
    height: 200,
    backgroundPosition: "top",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
  },
  titleListItem: {
    overflowY: "auto",
    height: "230px",
    "& .MuiListItem-container": {
      listStyleType: "none",
    },
  },
  gridList: {
    height: "350px",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    "& .MuiGridListTileBar-titleWrap": {
      color: "#1f678f",
    },
  },
  select: {
    width: "100%",
  },
  wrapper: {
    height: "100vh",
    width: "100vw",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background:
      "linear-gradient(90deg, rgba(108,178,238,1) 0%, rgba(231,58,131,1) 100%)",
    "& .MuiInputLabel-formControl": {
      top: "15px",
      left: "5px",
    },
  },
  pageContainer: {
    minHeight: (props: { mobile: boolean }): number =>
      props.mobile ? 700 : 380,
    position: "relative",
  },
  page: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  button: {
    width: 200,
  },
  bookedIcon: {
    backgroundColor: "#ff0081",
    width: 56,
    height: 56,
    transform: "rotate(-15deg)",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      backgroundColor: "#fff",
      width: 56,
      height: 56,
      borderRadius: theme.spacing(1),
      transform: "rotate(15deg)",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

type FormState =
  | { state: "initial" }
  | { state: "submitted" }
  | { state: "error"; error: Error };

const ExitSurvey: FC = () => {
  const mobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles({ mobile });

  const query = new URLSearchParams(useLocation().search);

  const email = query.get("e") || "";
  const mixpanelDistinctId = query.has("mid") ? query.get("mid") : null; // just for tracking, do not save to db

  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState(true);
  const [formState, setFormState] = useState<FormState>({ state: "initial" });
  const [value, setValue] = useState("Other");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await createExitReason(mixpanelDistinctId, {
          email,
          reason,
          date: new Date(),
        });
        setFormState({ state: "submitted" });
      } catch (error) {
        console.error(error);
        setFormState({ state: "error", error });
      }
    },
    [email, mixpanelDistinctId, reason]
  );

  const isFormValid = React.useMemo(() => {
    if (!otherReason) setReason(value);
    if (reason) {
      return reason.trim().length > 0;
    }
    return false;
  }, [reason, otherReason, value]);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason("");
    setValue((event.target as HTMLInputElement).value);
    setOtherReason((event.target as HTMLInputElement).value === "Other");
  };

  return (
    <div className={classes.wrapper}>
      <Card className={classes.root}>
        <Grid container>
          <Grid item style={{ padding: 20 }}>
            <Typography gutterBottom variant="h5" component="h2">
              We&apos;ll miss you!
            </Typography>
            <img alt="" src={sadnessGif} />
          </Grid>
          <Grid item style={{ padding: 20, textAlign: "center" }}>
            <Typography variant="body2" color="textSecondary" component="p">
              Help us get better! Let us know why you&apos;re leaving:
            </Typography>
            <br />
            {formState.state === "initial" ? (
              <>
                <FormControl component="fieldset" style={{ alignSelf: "left" }}>
                  <RadioGroup
                    aria-label="feedbackReason"
                    name="feedbackReason"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="I don't understand how to use the product"
                      control={<Radio />}
                      label="I don't understand how to use the product"
                    />
                    <FormControlLabel
                      value="I only needed it for a little while"
                      control={<Radio />}
                      label="I only needed it for a little while"
                    />
                    <FormControlLabel
                      value="Some features I need are missing"
                      control={<Radio />}
                      label="Some features I need are missing"
                    />
                    <FormControlLabel
                      value="I found a better solution"
                      control={<Radio />}
                      label="I found a better solution"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                  <br />
                </FormControl>
                {otherReason && (
                  <TextField
                    id="reason"
                    name="reason"
                    value={reason}
                    onChange={handleReasonChange}
                    multiline
                    rows={4}
                    style={{ width: "75%" }}
                    variant="outlined"
                  />
                )}
                <br />
                <Button
                  id="submit"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isFormValid}
                  disableElevation
                >
                  Share
                </Button>
              </>
            ) : formState.state === "submitted" ? (
              <>
                <Typography variant="h6" component="div">
                  Thank you for helping make Smarty better!
                </Typography>
              </>
            ) : (
              <>{formState.error.toString()}</>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default ExitSurvey;
