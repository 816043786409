import React, { Component, ErrorInfo } from "react";
import { createError } from "../../services/errors";
import { UserContext } from "../../UserContext";

interface Props {}

interface State {
  hasError: Boolean;
  error?: Error | null;
  errorInfo?: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1 className="mt-4" style={{ fontSize: 26 }}>
            Something went wrong.
          </h1>
          <div className="mt-4 text-center">
            <button
              type="button"
              className="btn btn-sm primary"
              aria-label="reload"
              onClick={this.handleReloadClick}
            >
              Reload
            </button>
          </div>
          <details
            className="mt-4"
            style={{ whiteSpace: "pre-wrap", fontSize: 10 }}
          >
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }

  componentDidCatch(error: Error | null, errorInfo: ErrorInfo | null) {
    // Catch errors in any components below and re-render with error message
    this.setState({ hasError: true, error, errorInfo });

    const { email } = this.context;

    createError({
      email,
      message: error ? error.toString() : "",
      details: errorInfo?.componentStack || "",
      source: "error-boundary",
      date: new Date(),
    }).catch(console.error);
  }

  handleReloadClick() {
    // reload iframe
    window.location.reload();
  }
}
ErrorBoundary.contextType = UserContext;

export default ErrorBoundary;
