import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkIcon from "@material-ui/icons/Link";
import {
  COLOR_DEFAULT_LINK,
  COLOR_EMAIL,
  COLOR_FACEBOOK,
  COLOR_INSTAGRAM,
  COLOR_LINKEDIN,
  COLOR_TWITTER,
  isEmail,
} from "@trysmarty/shared";

interface IComponentProps {
  link: string;
  className?: string;
}

const SocialIcon: React.FC<IComponentProps> | null = ({ link, className }) => {
  if (isEmail(link)) {
    return <EmailIcon className={className} htmlColor={COLOR_EMAIL} />;
  }
  if (link.includes("facebook")) {
    return <FacebookIcon className={className} htmlColor={COLOR_FACEBOOK} />;
  }
  if (link.includes("twitter")) {
    return <TwitterIcon className={className} htmlColor={COLOR_TWITTER} />;
  }
  if (link.includes("linkedin")) {
    return <LinkedInIcon className={className} htmlColor={COLOR_LINKEDIN} />;
  }
  if (link.includes("instagram")) {
    return <InstagramIcon className={className} htmlColor={COLOR_INSTAGRAM} />;
  }
  return <LinkIcon className={className} htmlColor={COLOR_DEFAULT_LINK} />;
};

export default SocialIcon as React.ComponentType<IComponentProps>;
