import draggingTutorial from "../../../assets/draggingAvailabilityTutorial.gif";
import HelpIcon from "@material-ui/icons/Help";
import Grid from "@material-ui/core/Grid";

import React from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  description: {
    color: "#848482",
    textAlign: "left",
  },
}));

export default function MouseOverPopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ marginTop: 10 }}>
      <div
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ color: "#848482", textAlign: "left" }}
      >
        <Grid container>
          <Grid
            item
            xs={9}
            style={{
              lineHeight: "38px",
              padding: "0 10px",
              fontSize: 16,
            }}
          >
            <span className={classes.description}>Drag & select times</span>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              textAlign: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <HelpIcon style={{ margin: "auto" }} />
          </Grid>
        </Grid>
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <img
          alt=""
          src={draggingTutorial}
          style={{ maxWidth: "250px", margin: "auto" }}
        />
      </Popover>
    </div>
  );
}
