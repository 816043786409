import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment-timezone";
import { makeStyles, Typography } from "@material-ui/core";
import {
  CalendarEvent$DTO,
  checkCollision,
  IInterval,
  isSameDay,
  isToday,
  IWeeklyInterval,
  nearestNumber,
  Nullable,
} from "@trysmarty/shared";
import DeleteIcon from "@material-ui/icons/Delete";

const GENERIC_DATE = "2020-01-01T00:00:00Z";
export type CalendarType = "SPECIFIC" | "GENERIC";
export type CalendarViewType =
  | "WEEK"
  | "WORK_WEEK"
  | "SINGLE_DAY"
  | "THREE_DAYS";
export type EditionMode = "NONE" | "EVENTS" | "SLOTS";
type DragAction = "NONE" | "CREATE" | "CHANGE_START" | "CHANGE_END" | "MOVE";

interface DayHeaderTemplateProps {
  date: moment.Moment;
  timeZone: string;
  events: CalendarEvent$DTO[];
  slots: IInterval<Date>[];
  calendarType: CalendarType;
}

interface AllDayEventTemplateProps {
  date: moment.Moment;
  timeZone: string;
  event: CalendarEvent$DTO;
}

interface EventTemplateProps {
  date: moment.Moment;
  timeZone: string;
  event: CalendarEvent$DTO;
}

interface CalendarProps {
  calendarType: CalendarType;
  calendarView: CalendarViewType;
  editionMode: EditionMode;
  currentDate: Date;
  timeZone: string;

  events: CalendarEvent$DTO[];
  onCreateEvent: (event: CalendarEvent$DTO) => void;
  onChangeEvent: (
    original: CalendarEvent$DTO,
    event: CalendarEvent$DTO
  ) => void;
  onDeleteEvent: (event: CalendarEvent$DTO) => void;

  slots: IInterval<Date>[];
  onCreateSlot: (slot: IInterval<Date>) => void;
  onChangeSlot: (original: IInterval<Date>, slot: IInterval<Date>) => void;
  onDeleteSlot: (slot: IInterval<Date>) => void;
  recommendedSlots: IInterval<Date>[];
  onRecommendedSlotClick: (slot: IInterval<Date>) => void;

  weeklyRecurringSlots: IWeeklyInterval[];
  onCreateWeeklyRecurringSlot: (slot: IWeeklyInterval) => void;
  onChangeWeeklyRecurringSlot: (
    original: IWeeklyInterval,
    slot: IWeeklyInterval
  ) => void;
  onDeleteWeeklyRecurringSlot: (slot: IWeeklyInterval) => void;

  minHour: number;
  maxHour: number;
  pixelsPerHour: number;
  step: number;
  defaultDurationMinutes: number;
  minEventDurationMinutes: number;
  minEventHeight: number;
  scrollbarWidth: number;
  dayMinWidth: number;
  topHandleHeight: number;
  bottomHandleHeight: number;
  hoursContainerWidth: number;
  className?: string;
  style?: React.CSSProperties;
  DayHeaderTemplate?: React.FC<DayHeaderTemplateProps>;
  AllDayEventTemplate?: React.FC<AllDayEventTemplateProps>;
  EventTemplate?: React.FC<EventTemplateProps>;
  defaultEventColor: string;
  defaultEventBackgroundColor: string;

  slotColor: string;
  slotBackgroundColor: string;

  recommendedSlotColor: string;
  recommendedSlotBackgroundColor: string;
  recommendedSlotIcon?: string;

  minSummaryDuration: number;
}

const $textPrimary = "#05283A";
const $textSecondary = "#B4B4B4";
const $colorPrimary = "#FF0081";
const $bgLightBlue2 = "#F0F7FB";
const $colorBlack20 = "#CCCCCC";
const $defaultEventColor = "#05283A";
const $defaultEventBackgroundColor = "#DBF1FE";
const $slotColor = "#05283A";
const $slotBackgroundColor = "#DCF2FE";
const $recommendedSlotColor = "#05283A";
const $recommendedSlotBackgroundColor = "#99FF99AF";
const $colorHeaderBackground = "#F0F0F0";

const useStyles = makeStyles(() => ({
  calendar: {
    color: $textPrimary,
    fontSize: 12,

    userSelect: "none" /* prevent text selection */,

    boxSizing: "border-box",
    backgroundClip: "border-box",

    boxShadow: "0px 14px 20px 0 #88888820",

    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowX: "hidden",
    overflowY: "hidden",

    "& *, & *:before, & *:after": {
      boxSizing: "inherit",
    },

    "& .header": {
      display: "flex",
      backgroundColor: $colorHeaderBackground,
      borderBottom: "solid 1px #dadce0",

      "& .day": {
        padding: 4,
        borderRadius: 4,
        textAlign: "center",
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: 500,

        "&.today": {
          backgroundColor: "#F0F7FB",
        },

        "& .hasEvent": {
          position: "relative",
          height: 16,

          "&:after": {
            content: "",
            position: "absolute",
            bottom: 4,
            left: "50%",
            transform: "translateX(-50%)",
            width: 4,
            height: 4,
            borderRadius: "50%",
            backgroundColor: $colorPrimary,
          },
        },
      },

      "& .event": {
        marginTop: 2,
        borderRadius: 4,
      },
    },

    "& .content": {
      flex: 1,
      display: "flex",
      overflowX: "auto",
      overflowY: "scroll",
      cursor: "pointer",
      background: "#fff",

      "& .hours": {
        color: $textSecondary,
        backgroundColor: "#fff",

        "& .hour": {
          transform: "translateY(-8px)",
          fontSize: 10,
          textAlign: "right",
          marginRight: 4,

          "&:first-child": {
            visibility: "hidden",
          },
        },
      },

      "& .day": {
        pointerEvents: "none",
        position: "relative",

        "& .grid": {
          pointerEvents: "none",
          position: "absolute",
          width: "100%",
          borderBottom: `1px solid ${$colorBlack20}`,
          borderRight: `1px solid ${$colorBlack20}`,
          boxSizing: "borderBox",
          zIndex: 0,

          "&:first-child": {
            borderTop: `1px solid ${$colorBlack20}`,
          },

          "&.today": {
            backgroundColor: $bgLightBlue2,
          },
        },
      },

      "& .event, & .slot, & .indicator, & .recommended-slot": {
        position: "absolute",
        left: 0,
        right: 0,
        fontSize: 12,
        borderRadius: 4,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 2,

        "&.dragging": {
          zIndex: 500,
          opacity: "75%",
        },
      },

      "& .current-time": {
        pointerEvents: "none",
        width: "100%",
        height: 2,
        backgroundColor: $colorPrimary,
        margin: "5px 0",
        zIndex: 100,
        position: "relative",

        // todo(hmassad): draw triangle on the left, this doesn't work any more
        "&:before": {
          pointerEvents: "none",
          content: "",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          width: 0,
          height: 0,
          borderTop: "6px solid transparent",
          borderBottom: "6px solid transparent",
          borderLeft: `6px solid ${$colorPrimary}`,
          borderRadius: 5,
        },
      },
    },
  },
}));

export const Calendar = ({
  calendarType = "SPECIFIC",
  calendarView = "WEEK",
  editionMode = "NONE",
  currentDate,
  timeZone,

  events = [],
  onCreateEvent,
  onChangeEvent,
  onDeleteEvent,

  slots = [],
  onCreateSlot,
  onChangeSlot,
  onDeleteSlot,

  recommendedSlots = [],
  onRecommendedSlotClick,

  weeklyRecurringSlots = [],
  onCreateWeeklyRecurringSlot,
  onChangeWeeklyRecurringSlot,
  onDeleteWeeklyRecurringSlot,

  minHour = 0,
  maxHour = 24,
  pixelsPerHour = 48,
  step = 15,
  defaultDurationMinutes = 60,
  minEventDurationMinutes = 15,
  minEventHeight = 10,
  scrollbarWidth = 22,
  dayMinWidth = 60,
  topHandleHeight = 10,
  bottomHandleHeight = 10,
  hoursContainerWidth = 40,
  className,
  style,
  DayHeaderTemplate,
  AllDayEventTemplate,
  EventTemplate,
  defaultEventColor = $defaultEventColor,
  defaultEventBackgroundColor = $defaultEventBackgroundColor,

  slotColor = $slotColor,
  slotBackgroundColor = $slotBackgroundColor,

  recommendedSlotColor = $recommendedSlotColor,
  recommendedSlotBackgroundColor = $recommendedSlotBackgroundColor,
  recommendedSlotIcon,

  minSummaryDuration = 45,
}: CalendarProps) => {
  const styles = useStyles();

  const columnDates = useMemo(() => {
    if (calendarType === "GENERIC") {
      switch (calendarView) {
        case "SINGLE_DAY":
          return [moment(GENERIC_DATE).tz(timeZone).startOf("days")];
        case "WORK_WEEK":
          return Array.from({ length: 5 }).map((_, i) =>
            moment(GENERIC_DATE)
              .tz(timeZone)
              .startOf("weeks")
              .add(i + 1, "days")
          );
        case "WEEK":
        default:
          return Array.from({ length: 7 }).map((_, i) =>
            moment(GENERIC_DATE).tz(timeZone).startOf("weeks").add(i, "days")
          );
      }
    } else {
      switch (calendarView) {
        case "SINGLE_DAY":
          return [moment(currentDate).tz(timeZone).startOf("days")];
        case "THREE_DAYS":
          return Array.from({ length: 3 }).map((_, i) =>
            moment(currentDate).tz(timeZone).startOf("days").add(i, "days")
          );
        case "WORK_WEEK":
          return Array.from({ length: 5 }).map((_, i) =>
            moment(currentDate)
              .tz(timeZone)
              .startOf("weeks")
              .add(i + 1, "days")
          );
        case "WEEK":
        default:
          return Array.from({ length: 7 }).map((_, i) =>
            moment(currentDate).tz(timeZone).startOf("weeks").add(i, "days")
          );
      }
    }
  }, [calendarType, calendarView, timeZone, currentDate]);

  const containerRef = useRef<HTMLElement>();
  const calendarContentRef = useRef<HTMLElement>();
  const [dayWidth, setDayWidth] = useState<number>(0);

  const [nowTop, setNowTop] = useState<Nullable<number>>();

  const dragContextRef = useRef<{
    action: DragAction | string;
    date?: Date;
    offset?: number;
  }>({ action: "NONE" });

  const [dragIndicator, setDragIndicator] = useState<Nullable<IInterval<Date>>>(
    null
  );
  const [dragEvent, setDragEvent] = useState<Nullable<any>>(null);
  const [dragOriginalEvent, setOriginalDragEvent] = useState<Nullable<any>>(
    null
  );

  const minutesToPixels = useCallback(
    (minutes) => (pixelsPerHour * minutes) / 60,
    [pixelsPerHour]
  );

  const pixelsToMinutes = useCallback(
    (pixels) => (pixels / pixelsPerHour) * 60,
    [pixelsPerHour]
  );

  const hoursToPixels = useCallback((hours) => minutesToPixels(hours * 60), [
    minutesToPixels,
  ]);

  const calcTop = useCallback(
    (date) => {
      const m = moment(date).tz(timeZone);
      if (m.hours() < minHour) m.hours(minHour);
      const startOfDay = m.clone().startOf("days").add(minHour, "hours");
      const minutes = m.diff(startOfDay, "minutes");
      return minutesToPixels(minutes);
    },
    [minHour, minutesToPixels, timeZone]
  );

  const calcHeight = useCallback(
    (start, end) => {
      const startM = moment(start).tz(timeZone);
      let endM = moment(end).tz(timeZone);
      if (!endM.isSame(startM, "days"))
        endM = startM.clone().startOf("days").add(1, "days");
      if (endM.hours() > maxHour) endM.hours(maxHour - 1);
      const minutes = endM.diff(startM, "minutes");
      return minutesToPixels(minutes);
    },
    [maxHour, minutesToPixels, timeZone]
  );

  const handleResize = useCallback(() => {
    // calculate width of each day column
    // Math.floor(xxx - 1) to prevent round error that make the horizontal scrollbar visible
    setDayWidth(
      Math.max(
        Math.floor(
          (containerRef.current!.clientWidth -
            hoursContainerWidth -
            scrollbarWidth -
            1) /
            columnDates.length
        ),
        dayMinWidth
      )
    );
  }, [columnDates, dayMinWidth, hoursContainerWidth, scrollbarWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const calcNowTop = () => {
      const now = moment().tz(timeZone);
      if (now.hours() < minHour || now.hours() > maxHour) {
        return null;
      }
      return calcTop(now.toDate());
    };

    const nowTop = calcNowTop();
    setNowTop(nowTop);
    if (nowTop) {
      calendarContentRef.current!.scrollTop = nowTop;
    }

    const intervalHandle = setInterval(() => {
      setNowTop(calcNowTop());
    }, 1000);
    return () => {
      clearInterval(intervalHandle);
    };
  }, [timeZone, minHour, maxHour, calcTop]);

  const findOverlapping = useCallback(
    (
      start: Date,
      end: Date
    ): (CalendarType | IInterval<Date> | IWeeklyInterval)[] => {
      switch (editionMode) {
        case "EVENTS":
          if (!events) return [];
          return events
            .filter((event) => event !== dragOriginalEvent)
            .filter((event) =>
              checkCollision(event.start, event.end, start, end)
            );
        case "SLOTS":
          switch (calendarType) {
            case "SPECIFIC":
              if (!slots) return [];
              return slots
                .filter((slot) => slot !== dragOriginalEvent)
                .filter((slot) =>
                  checkCollision(slot.start, slot.end, start, end)
                );
            case "GENERIC":
              if (!weeklyRecurringSlots) return [];
              return weeklyRecurringSlots
                .filter((slot) => slot !== dragOriginalEvent)
                .filter((slot) => {
                  const startOfDay = moment(GENERIC_DATE)
                    .tz(timeZone)
                    .startOf("weeks")
                    .add(slot.dayOfWeek, "days");
                  return checkCollision(
                    startOfDay
                      .clone()
                      .add(slot.startMinutes, "minutes")
                      .toDate(),
                    startOfDay.clone().add(slot.endMinutes, "minutes").toDate(),
                    start,
                    end
                  );
                });
            default:
              return [];
          }
        default:
          return [];
      }
    },
    [
      calendarType,
      dragOriginalEvent,
      editionMode,
      events,
      slots,
      timeZone,
      weeklyRecurringSlots,
    ]
  );

  /**
   * returns true if there's a collision
   */
  const doesOverlap = useCallback(
    (start, end) => findOverlapping(start, end).length > 0,
    [findOverlapping]
  );

  const findEventAtDate = useCallback(
    (date: Date) => {
      if (!events) return null;
      const found = events.filter(
        (event) =>
          !event.allDay &&
          event.start.getTime() <= date.getTime() &&
          event.end.getTime() >= date.getTime()
      );
      return found.length > 0 ? found[found.length - 1] : null;
    },
    [events]
  );

  const findSlotAtDate = useCallback(
    (date) => {
      if (!slots) return null;
      const found = slots.filter(
        (slot) =>
          slot.start.getTime() <= date.getTime() &&
          slot.end.getTime() >= date.getTime()
      );
      return found.length > 0 ? found[found.length - 1] : null;
    },
    [slots]
  );

  const findRecommendedSlotAtDate = useCallback(
    (date: Date) =>
      recommendedSlots.find(
        (slot) =>
          slot.start.getTime() <= date.getTime() &&
          slot.end.getTime() >= date.getTime()
      ),
    [recommendedSlots]
  );

  const findRecurringSlotAtDate = useCallback(
    (date) => {
      const startOfDay = moment(date).tz(timeZone).startOf("days");
      return weeklyRecurringSlots.find((slot) => {
        if (startOfDay.weekday() !== slot.dayOfWeek) return false;
        const slotStart = startOfDay
          .clone()
          .add(slot.startMinutes, "minutes")
          .toDate();
        const slotEnd = startOfDay
          .clone()
          .add(slot.endMinutes, "minutes")
          .toDate();
        return (
          slotStart.getTime() <= date.getTime() &&
          slotEnd.getTime() >= date.getTime()
        );
      });
    },
    [timeZone, weeklyRecurringSlots]
  );

  const someEventOrSlotOrRecommendedSlotAtDate = useCallback(
    (date) =>
      (editionMode === "SLOTS" &&
        ((calendarType === "SPECIFIC" &&
          (findSlotAtDate(date) || findRecommendedSlotAtDate(date))) ||
          (calendarType === "GENERIC" && findRecurringSlotAtDate(date)))) ||
      (editionMode === "EVENTS" && findEventAtDate(date)),
    [
      calendarType,
      editionMode,
      findEventAtDate,
      findRecurringSlotAtDate,
      findSlotAtDate,
      findRecommendedSlotAtDate,
    ]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (e.button !== 0) return;
      if (["EVENTS", "SLOTS"].indexOf(editionMode) < 0) return;

      const calendarContentRect = calendarContentRef.current!.getBoundingClientRect();
      let isOutsideCalendarContent = false;
      let top =
        e.clientY -
        calendarContentRect.top +
        calendarContentRef.current!.scrollTop;
      if (top < 0) {
        top = 0;
        isOutsideCalendarContent = true;
      }
      const maxTop = hoursToPixels(maxHour - minHour);
      if (top > maxTop) {
        top = maxTop;
        isOutsideCalendarContent = true;
      }
      const left = e.clientX - calendarContentRect.left - hoursContainerWidth;
      if (left < 0 || left > dayWidth * columnDates.length) {
        isOutsideCalendarContent = true;
      }

      const columnDate = columnDates[0]
        .clone()
        .add(Math.floor(left / dayWidth), "days")
        .startOf("days");
      const dateUnderCursor = columnDate
        .clone()
        .add(minHour * 60 + pixelsToMinutes(top), "minutes");
      const adjustedMinutesUnderCursor = nearestNumber(
        minHour * 60 + pixelsToMinutes(top),
        step
      );
      const adjustedDateUnderCursor = columnDate
        .clone()
        .add(adjustedMinutesUnderCursor, "minutes");
      const refDate = moment(dragContextRef.current.date).tz(timeZone);

      switch (dragContextRef.current.action) {
        case "CREATE": {
          setDragEvent((prev) => {
            // if point is higher than refDate (moving up), then start = point and end = refDate + offset
            // if point is lower than refDate (moving down), then start = refDate and end = point
            const point = moment(prev!.start)
              .tz(timeZone)
              .startOf("days")
              .add(adjustedMinutesUnderCursor, "minutes");
            const [newStart, newEnd] = point.isBefore(refDate)
              ? [
                  point.clone(),
                  refDate.clone().add(dragContextRef.current.offset, "minutes"),
                ]
              : [refDate.clone(), point.clone()];
            if (newEnd.diff(newStart, "minutes") < minEventDurationMinutes) {
              return prev;
            }
            if (doesOverlap(newStart.toDate(), newEnd.toDate())) {
              return prev;
            }
            return {
              ...prev,
              start: newStart.toDate(),
              end: newEnd.toDate(),
            };
          });
          break;
        }
        case "MOVE":
          if (isOutsideCalendarContent) return;
          setDragEvent((prev) => {
            const durationMinutes = moment(prev!.end).diff(
              moment(prev!.start),
              "minutes"
            );
            const minStart = columnDate.clone().add(minHour, "hours");
            const maxStart = columnDate
              .clone()
              .add(maxHour, "hours")
              .subtract(durationMinutes, "minutes");
            let newStart = moment(adjustedDateUnderCursor).subtract(
              dragContextRef.current.offset,
              "minutes"
            );
            if (moment(newStart).isBefore(minStart)) {
              newStart = minStart;
            } else if (moment(newStart).isAfter(maxStart)) {
              newStart = maxStart;
            }
            const newEnd = moment(newStart).add(durationMinutes, "minutes");
            if (doesOverlap(newStart.toDate(), newEnd.toDate())) {
              return prev;
            }
            return {
              ...prev,
              start: newStart.toDate(),
              end: newEnd.toDate(),
            };
          });
          break;
        case "CHANGE_START":
          setDragEvent((prev) => {
            const eventDay = moment(prev!.start).tz(timeZone).startOf("days");
            const minStart = eventDay.clone().add(minHour, "hours");
            const maxStart = moment(prev!.end).subtract(step, "minutes");
            let newStart = eventDay
              .clone()
              .add(adjustedMinutesUnderCursor, "minutes")
              .subtract(dragContextRef.current.offset, "minutes");
            if (moment(newStart).isBefore(minStart)) {
              newStart = minStart;
            } else if (moment(newStart).isAfter(maxStart)) {
              newStart = maxStart;
            }
            if (
              moment(prev!.end).diff(newStart, "minutes") <
              minEventDurationMinutes
            ) {
              return prev;
            }
            if (doesOverlap(newStart.toDate(), prev!.end)) {
              return prev;
            }
            return {
              ...prev,
              start: newStart.toDate(),
            };
          });
          break;
        case "CHANGE_END":
          setDragEvent((prev) => {
            const eventDay = moment(prev!.start).tz(timeZone).startOf("days");
            const minEnd = moment(prev!.start).add(step, "minutes");
            const maxEnd = eventDay.clone().add(maxHour, "hours");
            let newEnd = eventDay
              .clone()
              .add(adjustedMinutesUnderCursor, "minutes")
              .subtract(dragContextRef.current.offset, "minutes");
            if (newEnd.isBefore(minEnd)) {
              newEnd = minEnd;
            } else if (newEnd.isAfter(maxEnd)) {
              newEnd = maxEnd;
            }
            if (
              moment(newEnd).diff(prev!.start, "minutes") <
              minEventDurationMinutes
            ) {
              return prev;
            }
            if (doesOverlap(prev!.start, newEnd.toDate())) {
              return prev;
            }
            return {
              ...prev,
              end: newEnd.toDate(),
            };
          });
          break;
        case "NONE": // show drag indicator
        default: {
          if (isOutsideCalendarContent) {
            setDragIndicator(null);
            return;
          }
          // if there's an event or slot under the mouse, do not show drag indicator
          if (
            someEventOrSlotOrRecommendedSlotAtDate(dateUnderCursor.toDate())
          ) {
            setDragIndicator(null);
            return;
          }
          if (calendarType === "GENERIC" && editionMode === "EVENTS") {
            return;
          }
          const start = adjustedDateUnderCursor.clone();
          const end = adjustedDateUnderCursor
            .clone()
            .add(defaultDurationMinutes, "minutes");
          const overlapped = findOverlapping(start.toDate(), end.toDate());
          if (overlapped.length > 0) {
            // if we are colliding with a slot/event, try to move up check if it fits
            let min;
            if (calendarType === "GENERIC") {
              min = columnDate
                .clone()
                .add(
                  Math.min(
                    ...overlapped.map(
                      (slot) => (slot as IWeeklyInterval).startMinutes
                    )
                  ),
                  "minutes"
                );
            } else {
              min = moment(
                Math.min(
                  ...overlapped.map((event) =>
                    (event as
                      | CalendarEvent$DTO
                      | IInterval<Date>).start.getTime()
                  )
                )
              ).tz(timeZone);
            }
            min.subtract(defaultDurationMinutes, "minutes");
            if (
              Math.abs(min.diff(adjustedDateUnderCursor, "minutes")) <=
              defaultDurationMinutes
            ) {
              // check again if it fits with the new start
              const newStart = min.clone();
              const newEnd = min.clone().add(defaultDurationMinutes, "minutes");
              if (doesOverlap(newStart.toDate(), newEnd.toDate())) return;
              setDragIndicator({
                start: newStart.toDate(),
                end: newEnd.toDate(),
              });
            }
            return;
          }
          // do not let indicator past midnight
          if (
            adjustedMinutesUnderCursor >=
            maxHour * 60 - defaultDurationMinutes
          ) {
            const newStart = columnDate
              .clone()
              .add(maxHour * 60 - defaultDurationMinutes, "minutes");
            const newEnd = columnDate.clone().add(maxHour * 60, "minutes");
            if (doesOverlap(newStart.toDate(), newEnd.toDate())) return;
            setDragIndicator({
              start: newStart.toDate(),
              end: newEnd.toDate(),
            });
            return;
          }

          setDragIndicator({
            start: start.toDate(),
            end: end.toDate(),
          });
          break;
        }
      }
    },
    [
      hoursToPixels,
      maxHour,
      minHour,
      hoursContainerWidth,
      dayWidth,
      columnDates,
      pixelsToMinutes,
      step,
      editionMode,
      defaultDurationMinutes,
      doesOverlap,
      timeZone,
      minEventDurationMinutes,
      calendarType,
      findOverlapping,
      someEventOrSlotOrRecommendedSlotAtDate,
    ]
  );

  const handleMouseDown = useCallback(
    (e) => {
      if (["EVENTS", "SLOTS"].indexOf(editionMode) < 0) return;
      if (e.button !== 0) return;
      if (e.target.onclick) return; // allow clicking on top most elements
      if (!calendarContentRef.current!.contains(e.target)) return; // only fire if clicking on calendar or descendant

      const calendarContentRect = calendarContentRef.current!.getBoundingClientRect();
      const left = e.clientX - calendarContentRect.left - hoursContainerWidth;
      // discard clicks outside calendarContentRef
      if (
        e.clientX < calendarContentRect.left ||
        e.clientX > calendarContentRect.right ||
        e.clientY < calendarContentRect.top ||
        e.clientY > calendarContentRect.bottom
      ) {
        return;
      }
      const top =
        e.clientY -
        calendarContentRect.top +
        calendarContentRef.current!.scrollTop;
      // discard if outside of calendarContentRect
      if (
        left < 0 ||
        left > dayWidth * columnDates.length ||
        top < 0 ||
        top > hoursToPixels(maxHour - minHour)
      ) {
        return;
      }

      const columnDate = columnDates[0]
        .clone()
        .add(Math.floor(left / dayWidth), "days")
        .startOf("days");
      const minutesUnderCursor = Math.min(
        minHour * 60 + pixelsToMinutes(top),
        maxHour * 60
      );
      const adjustedMinutesUnderCursor = nearestNumber(
        minutesUnderCursor,
        step
      );
      const dateUnderCursor = columnDate
        .clone()
        .add(minutesUnderCursor, "minutes");
      const adjustedDateUnderCursor = columnDate
        .clone()
        .add(adjustedMinutesUnderCursor, "minutes");

      /**
       * if the click is in the bound of an element:
       *   click on top, action: CHANGE_START
       *   click on bottom, action: CHANGE_END
       *   click on middle, action: MOVE
       * if the click is in the border of an element: CREATE
       * @param eventOrSlotTop
       * @param eventOrSlotBottom
       * @return {string|DragAction}
       */
      const calculateAction = (
        eventOrSlotTop: number,
        eventOrSlotBottom: number
      ) => {
        if (eventOrSlotBottom - eventOrSlotTop < minEventHeight) {
          if (
            hoursToPixels(maxHour - minHour) - eventOrSlotBottom <
            minEventHeight
          ) {
            return "CHANGE_START";
          }
          return "CHANGE_END";
        }
        if (top - eventOrSlotTop < topHandleHeight) {
          return "CHANGE_START";
        }
        if (eventOrSlotBottom - top <= bottomHandleHeight) {
          return "CHANGE_END";
        }
        return "MOVE";
      };

      const startDraggingSpecificEventOrSlot = (
        eventOrSlotUnderCursor: CalendarEvent$DTO | IInterval<Date>
      ) => {
        const eventOrSlotTop = calcTop(eventOrSlotUnderCursor.start);
        const eventOrSlotBottom = moment(eventOrSlotUnderCursor.end)
          .tz(timeZone)
          .isSame(moment(eventOrSlotUnderCursor.start).tz(timeZone), "days")
          ? calcTop(eventOrSlotUnderCursor.end)
          : hoursToPixels(maxHour - minHour);
        const action = calculateAction(eventOrSlotTop, eventOrSlotBottom);
        // offset is used to make the dragging more natural, if you start from the middle, use the middle as a reference to move the event
        dragContextRef.current = {
          action,
          date: adjustedDateUnderCursor.toDate(),
          offset: nearestNumber(
            pixelsToMinutes(
              action === "CHANGE_END"
                ? top - eventOrSlotBottom
                : top - eventOrSlotTop
            ),
            step
          ),
        };
        setDragEvent({
          ...eventOrSlotUnderCursor,
        });
        setOriginalDragEvent(eventOrSlotUnderCursor);
      };

      const startDraggingRecurringSlot = (
        recurringSlotUnderCursor: IWeeklyInterval
      ) => {
        const start = columnDate
          .clone()
          .add(recurringSlotUnderCursor.startMinutes, "minutes");
        const end = columnDate
          .clone()
          .add(recurringSlotUnderCursor.endMinutes, "minutes");
        const slotTop = calcTop(start.toDate());
        const slotBottom = start.isSame(end, "days")
          ? calcTop(end.toDate())
          : hoursToPixels(maxHour - minHour);
        const action = calculateAction(slotTop, slotBottom);

        // offset is used to make the dragging more natural
        dragContextRef.current = {
          action,
          date: adjustedDateUnderCursor.toDate(),
          offset: nearestNumber(
            pixelsToMinutes(
              action === "CHANGE_END" ? top - slotBottom : top - slotTop
            ),
            step
          ),
        };
        setDragEvent({
          ...recurringSlotUnderCursor,
          start: start.toDate(),
          end: end.toDate(),
        });
        setOriginalDragEvent(recurringSlotUnderCursor);
      };

      const startDragCreatingEventOrSlot = () => {
        const start = adjustedDateUnderCursor.toDate();
        const end = adjustedDateUnderCursor
          .clone()
          .add(defaultDurationMinutes, "minutes")
          .toDate();
        const overlapped = findOverlapping(start, end);
        if (overlapped.length > 0) {
          // if we are colliding with a slot/event, try to move up check if it fits
          let min;
          if (calendarType === "GENERIC") {
            min = columnDate
              .clone()
              .add(
                Math.min(
                  ...overlapped.map(
                    (slot) => (slot as IWeeklyInterval).startMinutes
                  )
                ),
                "minutes"
              )
              .subtract(defaultDurationMinutes, "minutes");
          } else {
            min = moment(
              Math.min(
                ...overlapped.map((event) =>
                  (event as CalendarEvent$DTO | IInterval<Date>).start.getTime()
                )
              )
            )
              .tz(timeZone)
              .subtract(defaultDurationMinutes, "minutes");
          }
          if (
            Math.abs(min.diff(adjustedDateUnderCursor, "minutes")) <=
            defaultDurationMinutes
          ) {
            // check again if it fits with the new start
            const newStart = min.clone();
            const newEnd = min.clone().add(defaultDurationMinutes, "minutes");
            if (doesOverlap(newStart.toDate(), newEnd.toDate())) return;
            dragContextRef.current = {
              action: "CREATE",
              date: newStart.toDate(),
              offset: newEnd.diff(newStart, "minutes"),
            };
            setDragEvent({
              start: newStart.toDate(),
              end: newEnd.toDate(),
            });
          }
          return;
        }
        // do not let indicator past midnight
        if (
          adjustedMinutesUnderCursor >=
          maxHour * 60 - defaultDurationMinutes
        ) {
          const newStart = columnDate
            .clone()
            .add(maxHour * 60 - defaultDurationMinutes, "minutes");
          const newEnd = columnDate.clone().add(maxHour * 60, "minutes");
          if (doesOverlap(newStart.toDate(), newEnd.toDate())) return;
          dragContextRef.current = {
            action: "CREATE",
            date: newStart.toDate(),
            offset: newEnd.diff(newStart, "minutes"),
          };
          setDragEvent({
            start: newStart.toDate(),
            end: newEnd.toDate(),
          });
          return;
        }

        dragContextRef.current = {
          action: "CREATE",
          date: adjustedDateUnderCursor.toDate(),
          offset: moment(end).diff(moment(start), "minutes"),
        };
        setDragEvent({
          start,
          end,
        });
      };

      switch (editionMode) {
        case "EVENTS": {
          if (calendarType !== "SPECIFIC") return;
          if (!events) return;
          const eventUnderCursor = findEventAtDate(dateUnderCursor.toDate());
          if (eventUnderCursor) {
            startDraggingSpecificEventOrSlot(eventUnderCursor);
            return;
          }
          startDragCreatingEventOrSlot();
          return;
        }
        case "SLOTS":
          switch (calendarType) {
            case "SPECIFIC": {
              if (!slots) return;
              const slotUnderCursor = findSlotAtDate(dateUnderCursor.toDate());
              if (slotUnderCursor) {
                startDraggingSpecificEventOrSlot(slotUnderCursor);
                return;
              }
              const recommendedSlotUnderCursor = findRecommendedSlotAtDate(
                dateUnderCursor.toDate()
              );
              if (recommendedSlotUnderCursor) {
                if (onRecommendedSlotClick)
                  onRecommendedSlotClick(recommendedSlotUnderCursor);
                return;
              }
              startDragCreatingEventOrSlot();
              return;
            }
            case "GENERIC": {
              if (!weeklyRecurringSlots) return;
              const recurringSlotUnderCursor = findRecurringSlotAtDate(
                dateUnderCursor.toDate()
              );
              if (recurringSlotUnderCursor) {
                startDraggingRecurringSlot(recurringSlotUnderCursor);
                return;
              }
              startDragCreatingEventOrSlot();
              break;
            }
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    [
      editionMode,
      hoursContainerWidth,
      dayWidth,
      columnDates,
      hoursToPixels,
      maxHour,
      minHour,
      pixelsToMinutes,
      step,
      events,
      calendarType,
      calcTop,
      timeZone,
      minEventHeight,
      topHandleHeight,
      bottomHandleHeight,
      defaultDurationMinutes,
      slots,
      weeklyRecurringSlots,
      doesOverlap,
      findEventAtDate,
      findSlotAtDate,
      findRecurringSlotAtDate,
      findOverlapping,
      findRecommendedSlotAtDate,
      onRecommendedSlotClick,
    ]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (e.button !== 0) return;
      if (["EVENTS", "SLOTS"].indexOf(editionMode) < 0) return;
      if (dragContextRef.current.action === "NONE") return;

      if (calendarType === "SPECIFIC") {
        if (editionMode === "EVENTS") {
          // eslint-disable-next-line default-case
          switch (dragContextRef.current.action) {
            case "CREATE":
              if (onCreateEvent) onCreateEvent(dragEvent as CalendarEvent$DTO);
              break;
            case "CHANGE_START":
            case "CHANGE_END":
            case "MOVE":
              if (onChangeEvent)
                onChangeEvent(
                  dragOriginalEvent as CalendarEvent$DTO,
                  dragEvent as CalendarEvent$DTO
                );
              break;
          }
        } else if (editionMode === "SLOTS") {
          // eslint-disable-next-line default-case
          switch (dragContextRef.current.action) {
            case "CREATE":
              if (onCreateSlot) onCreateSlot(dragEvent as IInterval<Date>);
              break;
            case "CHANGE_START":
            case "CHANGE_END":
            case "MOVE":
              if (onChangeSlot)
                onChangeSlot(
                  dragOriginalEvent as IInterval<Date>,
                  dragEvent as IInterval<Date>
                );
              break;
          }
        }
      } else if (calendarType === "GENERIC" && editionMode === "SLOTS") {
        // eslint-disable-next-line default-case
        switch (dragContextRef.current.action) {
          case "CREATE":
            if (onCreateWeeklyRecurringSlot)
              onCreateWeeklyRecurringSlot({
                dayOfWeek: moment(dragEvent!.start).tz(timeZone).weekday(),
                startMinutes: moment(dragEvent!.start)
                  .tz(timeZone)
                  .diff(
                    moment(dragEvent!.start).tz(timeZone).startOf("days"),
                    "minutes"
                  ),
                endMinutes: moment(dragEvent!.end)
                  .tz(timeZone)
                  .diff(
                    moment(dragEvent!.start).tz(timeZone).startOf("days"),
                    "minutes"
                  ),
              });
            break;
          case "CHANGE_START":
          case "CHANGE_END":
          case "MOVE":
            if (onChangeWeeklyRecurringSlot)
              onChangeWeeklyRecurringSlot(
                dragOriginalEvent as IWeeklyInterval,
                {
                  ...dragOriginalEvent,
                  dayOfWeek: moment(dragEvent!.start).tz(timeZone).weekday(),
                  startMinutes: moment(dragEvent!.start)
                    .tz(timeZone)
                    .diff(
                      moment(dragEvent!.start).tz(timeZone).startOf("days"),
                      "minutes"
                    ),
                  endMinutes: moment(dragEvent!.end)
                    .tz(timeZone)
                    .diff(
                      moment(dragEvent!.start).tz(timeZone).startOf("days"),
                      "minutes"
                    ),
                }
              );
            break;
        }
      }

      setDragEvent(null);
      setOriginalDragEvent(null);
      dragContextRef.current = { action: "NONE" };
    },
    [
      timeZone,
      calendarType,
      editionMode,
      dragEvent,
      dragOriginalEvent,
      onCreateEvent,
      onChangeEvent,
      onCreateSlot,
      onChangeSlot,
      onCreateWeeklyRecurringSlot,
      onChangeWeeklyRecurringSlot,
    ]
  );

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseDown, handleMouseUp, handleMouseMove]);

  const handleDeleteEventClick = useCallback(
    (event: CalendarEvent$DTO) => {
      if (onDeleteEvent) onDeleteEvent(event);
    },
    [onDeleteEvent]
  );

  const handleDeleteSlotClick = useCallback(
    (slot) => {
      if (onDeleteSlot) onDeleteSlot(slot);
    },
    [onDeleteSlot]
  );

  const handleDeleteWeeklyRecurringSlotClick = useCallback(
    (slot) => {
      if (onDeleteWeeklyRecurringSlot) onDeleteWeeklyRecurringSlot(slot);
    },
    [onDeleteWeeklyRecurringSlot]
  );

  const renderedDayEventContainer = useMemo(
    () =>
      columnDates.map((date) => (
        <>
          {Array.from({ length: maxHour - minHour })
            .map((_, i) => minHour + i)
            .map((hour) => (
              <div
                key={hour}
                className={`grid ${
                  calendarView !== "SINGLE_DAY" && isToday(date, timeZone)
                    ? "today"
                    : ""
                }`}
                style={{
                  top: hoursToPixels(hour - minHour),
                  height: hoursToPixels(1),
                }}
              />
            ))}
        </>
      )),
    [calendarView, columnDates, hoursToPixels, maxHour, minHour, timeZone]
  );

  const renderedDayHeaders = useMemo(
    () =>
      columnDates.map((date) => {
        const startOfDay = moment(date).tz(timeZone).startOf("days");
        const eventsOfTheDay = !events
          ? []
          : events
              .filter((event) => event) // HACK hot reloader throws an error
              .filter((event) =>
                startOfDay.isSame(moment(event.start).tz(timeZone), "days")
              );
        const slotsOfTheDay = !slots
          ? []
          : slots
              .filter((slot) => slot) // HACK hot reloader throws an error
              .filter((slot) =>
                startOfDay.isSame(moment(slot.start).tz(timeZone), "days")
              );

        return (
          <div
            key={date.valueOf()}
            style={{ width: dayWidth, minWidth: dayWidth, maxWidth: dayWidth }}
          >
            {calendarType === "SPECIFIC" && calendarView !== "SINGLE_DAY" && (
              <>
                {DayHeaderTemplate ? (
                  <DayHeaderTemplate
                    key={date.valueOf()}
                    date={date}
                    timeZone={timeZone}
                    events={eventsOfTheDay}
                    slots={slotsOfTheDay}
                    calendarType={calendarType}
                  />
                ) : (
                  <div
                    className={`day ${isToday(date, timeZone) ? "today" : ""}`}
                  >
                    <Typography variant="subtitle2">
                      {moment(date).tz(timeZone).format("dd D")}
                    </Typography>
                    <div
                      className="has-event"
                      style={{
                        visibility: eventsOfTheDay.some(
                          (event) => !event.allDay
                        )
                          ? "visible"
                          : "hidden",
                      }}
                    />
                  </div>
                )}

                {eventsOfTheDay
                  .filter((event) => event.allDay)
                  .filter((event) =>
                    moment(date)
                      .tz(timeZone)
                      .isSame(moment(event.start).tz(timeZone), "days")
                  )
                  .map((event, index) => (
                    <div
                      key={index}
                      className="event"
                      title={event.summary}
                      style={{
                        color: event.foreground || defaultEventColor,
                        background:
                          event.background || defaultEventBackgroundColor,
                      }}
                    >
                      {AllDayEventTemplate ? (
                        <AllDayEventTemplate
                          event={event}
                          date={date}
                          timeZone={timeZone}
                        />
                      ) : (
                        <Typography variant="caption">
                          {event.summary}
                        </Typography>
                      )}
                    </div>
                  ))}
              </>
            )}
            {calendarType !== "SPECIFIC" && calendarView !== "SINGLE_DAY" && (
              /* single day has no header */
              <>
                {DayHeaderTemplate ? (
                  <DayHeaderTemplate
                    key={date.valueOf()}
                    date={date}
                    timeZone={timeZone}
                    events={eventsOfTheDay}
                    slots={slotsOfTheDay}
                    calendarType={calendarType}
                  />
                ) : (
                  <div className="day">
                    <Typography variant="caption">
                      {moment(date).tz(timeZone).format("dd")}
                    </Typography>
                  </div>
                )}
              </>
            )}
          </div>
        );
      }),
    [
      DayHeaderTemplate,
      AllDayEventTemplate,
      calendarType,
      calendarView,
      columnDates,
      dayWidth,
      defaultEventBackgroundColor,
      defaultEventColor,
      events,
      slots,
      timeZone,
    ]
  );

  const renderedEvents = useMemo(
    () =>
      columnDates.map((date) => {
        if (!events) return null;
        if (calendarType !== "SPECIFIC") return null;
        const startOfDay = moment(date).tz(timeZone).startOf("days");
        const filteredEvents = events
          .filter((event) => event) // HACK hot-reloader throws an error
          .filter((event) => !event.allDay)
          .filter((event) => !dragOriginalEvent || event !== dragOriginalEvent) // do not render event being dragged
          .filter((event) =>
            startOfDay.isSame(moment(event.start).tz(timeZone), "days")
          )
          .filter(
            (event) => moment(event.start).tz(timeZone).hours() <= maxHour
          )
          .filter(
            (event) =>
              !moment(event.start)
                .tz(timeZone)
                .isSame(moment(event.end).tz(timeZone), "days") ||
              moment(event.start).tz(timeZone).hours() <= maxHour
          );
        if (!filteredEvents.length) return null;

        // group events that overlap
        filteredEvents.sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        );
        const groups: CalendarEvent$DTO[][] = [];
        groups.push([filteredEvents[0]]);
        for (let i = 1; i < filteredEvents.length; i++) {
          const e = filteredEvents[i];
          let added = false;
          for (let j = 0; j < groups.length; j++) {
            const g = groups[j];
            if (
              g.some((e1) => checkCollision(e.start, e.end, e1.start, e1.end))
            ) {
              g.push(e);
              added = true;
            }
          }
          if (!added) {
            groups.push([e]);
          }
        }

        // todo(hmassad): the current approach only works with 2 columns, it doesn't work with case 2
        return groups
          .map((g, groupIndex) => {
            const columns = g.length === 1 ? 1 : 2;
            let prevColumn = 0;
            return g.map((event, eventIndex) => {
              // column is the visual column to render the event
              let column = eventIndex % 2;
              if (eventIndex > 1) {
                // if it collides with the prev, set the column as the opposite from the previous one
                // if not, use the same as the previous
                const prev = g[eventIndex - 1];
                if (
                  checkCollision(prev.start, prev.end, event.start, event.end)
                ) {
                  column = (prevColumn + 1) % 2;
                } else {
                  column = prevColumn;
                }
              }
              prevColumn = column;

              return (
                <div
                  key={`${groupIndex}-${eventIndex}`}
                  className="event"
                  title={event.summary}
                  style={{
                    top: calcTop(event.start),
                    height: calcHeight(event.start, event.end),
                    border: event.border
                      ? `0.5px solid ${event.border}`
                      : undefined,
                    color: event.foreground || defaultEventColor,
                    backgroundColor:
                      event.background || defaultEventBackgroundColor,
                    opacity: editionMode === "EVENTS" ? 1 : 0.5,
                    left: `${(100 * column) / columns}%`,
                    right: `${(100 / columns) * (columns - column - 1)}%`,
                    zIndex: editionMode === "EVENTS" ? 400 : 1,
                    pointerEvents: editionMode !== "EVENTS" ? "none" : "auto",
                    whiteSpace: "normal",
                  }}
                >
                  {editionMode === "EVENTS" ? (
                    <>
                      <div
                        style={{ height: topHandleHeight, cursor: "n-resize" }}
                      />
                      <div style={{ flex: 1, cursor: "move" }}>
                        {EventTemplate ? (
                          <EventTemplate
                            event={event}
                            date={date}
                            timeZone={timeZone}
                          />
                        ) : (
                          <Typography variant="caption">
                            {event.summary}
                            {moment(event.end).diff(
                              moment(event.start),
                              "minutes"
                            ) >= minSummaryDuration && (
                              <>
                                <br />
                                {moment(event.start)
                                  .tz(timeZone)
                                  .format("h:mma")}
                                {" - "}
                                {moment(event.end).tz(timeZone).format("h:mma")}
                              </>
                            )}
                          </Typography>
                        )}
                      </div>
                      <div
                        style={{
                          height: bottomHandleHeight,
                          cursor: "s-resize",
                        }}
                      />
                      {!!onDeleteEvent && (
                        <div
                          onClick={() => handleDeleteEventClick(event)}
                          style={{
                            position: "absolute",
                            bottom: 5,
                            right: 5,
                            cursor: "pointer",
                            padding: 5,
                          }}
                        >
                          <DeleteIcon
                            htmlColor={$colorPrimary}
                            onClick={() => handleDeleteEventClick(event)}
                            style={{ zIndex: -1, position: "relative" }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={{ flex: 1 }}>
                      <Typography variant="caption">
                        {event.summary}
                        {moment(event.end).diff(
                          moment(event.start),
                          "minutes"
                        ) >= minSummaryDuration && (
                          <>
                            <br />
                            {moment(event.start).tz(timeZone).format("h:mma")}
                            {" - "}
                            {moment(event.end).tz(timeZone).format("h:mma")}
                            <br />
                          </>
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              );
            });
          })
          .flat();
      }),
    [
      columnDates,
      events,
      calendarType,
      timeZone,
      dragOriginalEvent,
      maxHour,
      calcTop,
      calcHeight,
      defaultEventColor,
      defaultEventBackgroundColor,
      editionMode,
      topHandleHeight,
      EventTemplate,
      bottomHandleHeight,
      onDeleteEvent,
      handleDeleteEventClick,
      minSummaryDuration,
    ]
  );

  const renderedSlots = useMemo(() => {
    switch (calendarType) {
      case "GENERIC":
        // use weekly recurring slots
        return columnDates.map((date) => {
          if (!weeklyRecurringSlots) return null;
          // date is GENERIC_DATE
          const startOfDay = moment(date).tz(timeZone).startOf("days");
          return weeklyRecurringSlots
            .filter((slot) => slot) // HACK hot-reloader throws an error
            .filter((slot) => !dragOriginalEvent || slot !== dragOriginalEvent) // do not render slot being dragged
            .filter((slot) => startOfDay.weekday() === slot.dayOfWeek)
            .filter(
              (slot) =>
                startOfDay.clone().add(slot.startMinutes, "minutes").hours() <=
                maxHour
            )
            .map((slot, index) => (
              <div
                key={index}
                className="slot"
                style={{
                  top: calcTop(
                    startOfDay.clone().add(slot.startMinutes, "minutes")
                  ),
                  height: calcHeight(
                    startOfDay.clone().add(slot.startMinutes, "minutes"),
                    startOfDay.clone().add(slot.endMinutes, "minutes")
                  ),
                  color: slotColor,
                  backgroundColor: slotBackgroundColor,
                  left: 0,
                  right: 0,
                  pointerEvents: editionMode !== "SLOTS" ? "none" : "auto",
                  whiteSpace: "normal",
                  zIndex: 0,
                }}
              >
                {editionMode === "SLOTS" ? (
                  <>
                    <div
                      style={{ height: topHandleHeight, cursor: "n-resize" }}
                    />
                    <div style={{ flex: 1, cursor: "move" }}>
                      <Typography variant="caption">
                        {startOfDay
                          .clone()
                          .add(slot.startMinutes, "minutes")
                          .format("h:mma")}
                        &nbsp;-&nbsp;
                        {startOfDay
                          .clone()
                          .add(slot.endMinutes, "minutes")
                          .format("h:mma")}
                      </Typography>
                    </div>
                    <div
                      style={{ height: bottomHandleHeight, cursor: "s-resize" }}
                    />
                    <div
                      onClick={() => handleDeleteWeeklyRecurringSlotClick(slot)}
                      style={{
                        position: "absolute",
                        bottom: 5,
                        right: 5,
                        cursor: "pointer",
                        padding: 5,
                      }}
                    >
                      <DeleteIcon
                        htmlColor={$colorPrimary}
                        onClick={() =>
                          handleDeleteWeeklyRecurringSlotClick(slot)
                        }
                        style={{ zIndex: -1, position: "relative" }}
                      />
                    </div>
                  </>
                ) : (
                  <div style={{ flex: 1 }}>
                    <Typography variant="caption">
                      {startOfDay
                        .clone()
                        .add(slot.startMinutes, "minutes")
                        .format("h:mma")}
                      &nbsp;- &nbsp;
                      {startOfDay
                        .clone()
                        .add(slot.endMinutes, "minutes")
                        .format("h:mma")}
                    </Typography>
                  </div>
                )}
              </div>
            ));
        });
      case "SPECIFIC":
      default:
        // use weekly recurring slots
        return columnDates.map((date) => {
          if (!slots) return null;
          const startOfDay = moment(date).tz(timeZone).startOf("days");
          return slots
            .filter((slot) => slot) // HACK hot-reloader throws an error
            .filter((slot) => !dragOriginalEvent || slot !== dragOriginalEvent) // do not render slot being dragged
            .filter((slot) =>
              startOfDay.isSame(moment(slot.start).tz(timeZone), "days")
            )
            .filter(
              (slot) => moment(slot.start).tz(timeZone).hours() <= maxHour
            )
            .filter(
              (slot) =>
                !moment(slot.start)
                  .tz(timeZone)
                  .isSame(moment(slot.end).tz(timeZone), "days") ||
                moment(slot.start).tz(timeZone).hours() <= maxHour
            )
            .map((slot, index) => {
              // eslint-disable-next-line no-underscore-dangle
              const doesOverlap_ =
                events &&
                events.some((event) =>
                  checkCollision(event.start, event.end, slot.start, slot.end)
                );
              return (
                <div
                  key={index}
                  className="slot"
                  style={{
                    top: calcTop(slot.start),
                    height: calcHeight(slot.start, slot.end),
                    color: slotColor,
                    backgroundColor: slotBackgroundColor,
                    opacity: editionMode === "SLOTS" ? 1 : 0.5,
                    left: doesOverlap_ ? 40 : 0,
                    zIndex: editionMode === "SLOTS" ? 400 : 1,
                    pointerEvents: editionMode !== "SLOTS" ? "none" : "auto",
                    whiteSpace: "normal",
                  }}
                >
                  {editionMode === "SLOTS" ? (
                    <>
                      <div
                        style={{ height: topHandleHeight, cursor: "n-resize" }}
                      />
                      <div style={{ flex: 1, cursor: "move" }}>
                        <Typography variant="caption">
                          {moment(slot.start).tz(timeZone).format("h:mma")} -{" "}
                          {moment(slot.end).tz(timeZone).format("h:mma")}
                        </Typography>
                      </div>
                      <div
                        style={{
                          height: bottomHandleHeight,
                          cursor: "s-resize",
                        }}
                      />
                      <div
                        onClick={() => handleDeleteSlotClick(slot)}
                        style={{
                          position: "absolute",
                          bottom: 5,
                          right: 5,
                          cursor: "pointer",
                          padding: 5,
                        }}
                      >
                        <DeleteIcon
                          htmlColor={$colorPrimary}
                          onClick={() => handleDeleteSlotClick(slot)}
                          style={{ zIndex: -1, position: "relative" }}
                        />
                      </div>
                    </>
                  ) : (
                    <div style={{ flex: 1 }}>
                      <Typography variant="caption">
                        {moment(slot.start).tz(timeZone).format("h:mma")}
                        &nbsp;-&nbsp;
                        {moment(slot.end).tz(timeZone).format("h:mma")}
                      </Typography>
                    </div>
                  )}
                </div>
              );
            });
        });
    }
  }, [
    bottomHandleHeight,
    calcHeight,
    calcTop,
    calendarType,
    columnDates,
    dragOriginalEvent,
    editionMode,
    handleDeleteSlotClick,
    handleDeleteWeeklyRecurringSlotClick,
    maxHour,
    slots,
    timeZone,
    topHandleHeight,
    weeklyRecurringSlots,
    slotColor,
    slotBackgroundColor,
    events,
  ]);

  const renderedRecommendedSlots = useMemo(
    () =>
      columnDates.map((date) => {
        if (!slots) return null;
        if (calendarType !== "SPECIFIC") return null;
        if (editionMode !== "SLOTS") return null;
        const startOfDay = moment(date).tz(timeZone).startOf("days");
        return (
          recommendedSlots &&
          recommendedSlots
            .filter((slot) => slot) // HACK hot-reloader throws an error
            .filter((slot) =>
              startOfDay.isSame(moment(slot.start).tz(timeZone), "days")
            )
            .filter(
              (slot) => moment(slot.start).tz(timeZone).hours() <= maxHour
            )
            .filter((slot) => !doesOverlap(slot.start, slot.end)) // do not render event if conflicted
            .filter(
              (slot) =>
                !moment(slot.start)
                  .tz(timeZone)
                  .isSame(moment(slot.end).tz(timeZone), "days") ||
                moment(slot.start).tz(timeZone).hours() <= maxHour
            )
            .filter(
              (slot) =>
                !dragEvent ||
                !checkCollision(
                  slot.start,
                  slot.end,
                  dragEvent.start,
                  dragEvent.end
                )
            )
            .map((slot, index) => {
              const overlapsWithEvent =
                events &&
                events.some((event) =>
                  checkCollision(event.start, event.end, slot.start, slot.end)
                );
              const height = calcHeight(slot.start, slot.end);
              return (
                <div
                  key={index}
                  className="recommended-slot"
                  style={{
                    top: calcTop(slot.start),
                    height,
                    color: recommendedSlotColor,
                    backgroundColor: recommendedSlotBackgroundColor,
                    left: overlapsWithEvent ? 40 : 0,
                    zIndex: 400,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="caption">Recommended</Typography>
                  {recommendedSlotIcon && (
                    <img
                      src={recommendedSlotIcon}
                      alt=""
                      style={{
                        height: height - 4,
                        width: "auto",
                        marginRight: 2,
                      }}
                    />
                  )}
                </div>
              );
            })
        );
      }),
    [
      columnDates,
      events,
      calendarType,
      editionMode,
      timeZone,
      recommendedSlots,
      doesOverlap,
      maxHour,
      slots,
      calcTop,
      calcHeight,
      recommendedSlotColor,
      recommendedSlotBackgroundColor,
      recommendedSlotIcon,
      dragEvent,
    ]
  );

  return (
    <div
      className={`${styles.calendar} ${className || ""}`}
      style={style}
      // @ts-ignore
      ref={containerRef}
    >
      <div className="header">
        <div
          style={{ width: hoursContainerWidth, minWidth: hoursContainerWidth }}
        />
        {columnDates.map((date, index) => renderedDayHeaders[index])}
        <div style={{ width: scrollbarWidth, minWidth: scrollbarWidth }} />
      </div>

      {/* @ts-ignore */}
      <div className="content" ref={calendarContentRef}>
        <div
          className="hours"
          style={{
            height: hoursToPixels(maxHour - minHour),
            width: hoursContainerWidth,
            minWidth: hoursContainerWidth,
          }}
        >
          {Array.from({ length: maxHour - minHour + 1 })
            .map((_, i) => minHour + i)
            .map((/* number */ hour) => (
              // 6 is the height of calendar__content__hour last item
              <div
                key={hour}
                className="hour"
                style={{ height: hour < 24 ? hoursToPixels(1) : 6 }}
              >
                <Typography variant="caption">
                  {`${moment(currentDate)
                    .tz(timeZone)
                    .startOf("weeks")
                    .add(hour, "hours")
                    .format("ha")}`}
                </Typography>
              </div>
            ))}
        </div>

        {columnDates.map((date, index) => (
          <div
            key={date.valueOf()}
            className="day"
            style={{
              width: dayWidth,
              minWidth: dayWidth,
              maxWidth: dayWidth,
              height: hoursToPixels(maxHour - minHour),
            }}
          >
            {renderedDayEventContainer[index]}
            {renderedSlots[index]}
            {renderedEvents[index]}
            {renderedRecommendedSlots[index]}

            {/* dragged event */}
            {dragContextRef.current.action !== "NONE" &&
              editionMode === "EVENTS" &&
              dragEvent &&
              isSameDay(dragEvent.start, date, timeZone) && (
                <div
                  className="event dragging"
                  style={{
                    top: calcTop(dragEvent.start),
                    height: calcHeight(dragEvent.start, dragEvent.end),
                    color:
                      (dragEvent as CalendarEvent$DTO).foreground ||
                      defaultEventColor,
                    backgroundColor:
                      (dragEvent as CalendarEvent$DTO).background ||
                      defaultEventBackgroundColor,
                  }}
                >
                  <Typography variant="caption">
                    {moment(dragEvent.end).diff(
                      moment(dragEvent.start),
                      "minutes"
                    ) >= minSummaryDuration && (
                      <>
                        {moment(dragEvent.start).tz(timeZone).format("h:mma")}
                        {" - "}
                        {moment(dragEvent.end).tz(timeZone).format("h:mma")}
                        <br />
                      </>
                    )}
                    {(dragEvent as CalendarEvent$DTO).summary}
                  </Typography>
                </div>
              )}

            {/* dragged slot */}
            {dragContextRef.current.action !== "NONE" &&
              editionMode === "SLOTS" &&
              dragEvent &&
              isSameDay(dragEvent.start, date, timeZone) && (
                <div
                  className="slot dragging"
                  style={{
                    top: calcTop(dragEvent.start),
                    height: calcHeight(dragEvent.start, dragEvent.end),
                    color: slotColor,
                    backgroundColor: slotBackgroundColor,
                    pointerEvents: editionMode !== "SLOTS" ? "none" : "auto",
                    whiteSpace: "normal",
                  }}
                >
                  <div style={{ height: topHandleHeight }} />
                  <div style={{ flex: 1 }}>
                    <Typography variant="caption">
                      {moment(dragEvent.start).tz(timeZone).format("h:mma")} -{" "}
                      {moment(dragEvent.end).tz(timeZone).format("h:mma")}
                      <br />
                    </Typography>
                  </div>
                  <div style={{ height: bottomHandleHeight }} />
                </div>
              )}

            {/* indicator */}
            {dragContextRef.current.action === "NONE" &&
              dragIndicator &&
              isSameDay(dragIndicator!.start, date, timeZone) && (
                <div
                  className="indicator dragging"
                  style={{
                    top: calcTop(dragIndicator.start),
                    height: calcHeight(dragIndicator.start, dragIndicator.end),
                    color:
                      editionMode === "EVENTS" ? defaultEventColor : slotColor,
                    backgroundColor:
                      editionMode === "EVENTS"
                        ? defaultEventBackgroundColor
                        : slotBackgroundColor,
                    opacity: 0.5,
                  }}
                >
                  <Typography variant="caption">
                    {moment(dragIndicator.start).tz(timeZone).format("h:mma")} -{" "}
                    {moment(dragIndicator.end).tz(timeZone).format("h:mma")}
                    <br />
                  </Typography>
                </div>
              )}

            {isToday(date, timeZone) && nowTop && (
              <div className="current-time" style={{ top: nowTop }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
