import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { UserContext } from "../UserContext";
import {
  ConfirmDialog,
  CalendarSelectorWeb,
  FavoriteLocationsSelector,
} from "@trysmarty/components";
import { CalendarItem } from "@trysmarty/shared";
import UserBanner from "../components/UserBanner";
import EditLinks from "../components/settings/EditLinks/EditLinks";
import WorkingHours from "../components/settings/WorkingHours/WorkingHours";
import MeetingOptions from "../components/ui/MeetingOptions";
import { getCalendars } from "../services/temporary";

// TODO(hmassad): verify the user can scroll in a small screen
const useStyles = makeStyles((theme) => ({
  root: {
    // position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    maxWidth: 800,
    width: "100%",
    gridRowGap: theme.spacing(2),
    maxHeight: "100%",
  },
  wrapper: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: "#ff0081",
    borderRadius: "5px",
    width: 120,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff3a9f",
    },
  },
  margin: {
    marginLeft: 25,
  },
  fuchsiaButton: {
    backgroundColor: "#FF339A",
    fontSize: 18,
    width: 150,
    color: "white",
    "&:hover": {
      backgroundColor: "#b2236b",
    },
    "&:active": {
      backgroundColor: "#ff5bae",
    },
  },
}));

const CurrentUserPage: FC = () => {
  const styles = useStyles();

  const {
    username,
    email,
    firstName,
    lastName,
    image,
    links,
    logout,
    settings,
    changeSettings,
    resetProfile,
  } = useContext(UserContext);

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [calendarList, setCalendarList] = useState<CalendarItem[]>([]);

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleLogout = (): void => {
    logout();
  };

  const updateDuration = useCallback(
    (value: number): void => {
      if (!settings?.duration) return;
      changeSettings({ ...settings!, duration: value });
    },
    [settings, changeSettings]
  );

  const updateTimezone = useCallback(
    (value: string): void => {
      if (!settings) return;
      changeSettings({ ...settings!, timezone: value });
    },
    [settings, changeSettings]
  );

  const handleReset = async () => {
    await resetProfile();
    // eslint-disable-next-line no-self-assign
    window.location = window.location;
  };

  useEffect(() => {
    getCalendars().then(setCalendarList);
  }, []);

  const handleCalendarsChange = useCallback(
    (calendarId: string, added: boolean): void => {
      const newSelectedCalendarIds = [...(settings?.selectedCalendarIds || [])];
      if (added) {
        if (newSelectedCalendarIds.indexOf(calendarId) < 0) {
          newSelectedCalendarIds.push(calendarId);
        }
      } else {
        newSelectedCalendarIds.splice(
          newSelectedCalendarIds.indexOf(calendarId),
          1
        );
      }
      changeSettings({
        ...settings!,
        selectedCalendarIds: newSelectedCalendarIds,
      });
    },
    [settings, changeSettings]
  );
  const handleLocationsChange = useCallback(
    (favoriteLocations: string[]): void => {
      changeSettings({
        ...settings!,
        locations: favoriteLocations,
      });
    },
    [settings, changeSettings]
  );

  if (!settings) return null;
  if (!calendarList) return null;
  return (
    <div className={styles.root}>
      <Paper className={styles.container}>
        <UserBanner
          email={email}
          firstName={firstName}
          lastName={lastName}
          image={image}
          links={links}
          customToolbar={
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleConfirmDialogOpen}
                  className={`${styles.fuchsiaButton}`}
                >
                  Log Out
                </Button>
                <ConfirmDialog
                  title="Log out"
                  content="Are you sure you want to log out?"
                  open={isConfirmDialogOpen}
                  onConfirm={handleLogout}
                  onCancel={handleConfirmDialogClose}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  component={RouterLink}
                  to={`/u/${username}`}
                  className={`${styles.fuchsiaButton}`}
                >
                  Profile
                </Button>
              </Grid>
            </Grid>
          }
        />
        <div className={styles.wrapper}>
          <Grid container direction="column" spacing={5}>
            <Grid item style={{ width: "100%" }}>
              <EditLinks />
            </Grid>
            <Grid item style={{ width: "500%" }}>
              <Grid container alignItems="center" direction="column">
                <Grid item style={{ width: "100%" }}>
                  <Typography variant="h6">Default Meeting Options</Typography>
                </Grid>

                <Grid item style={{ width: "100%" }}>
                  <MeetingOptions
                    disabled={false}
                    duration={settings.duration}
                    onDurationChange={updateDuration}
                    timeZone={settings.timezone}
                    onTimeZoneChange={updateTimezone}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <CalendarSelectorWeb
                calendarIds={settings.selectedCalendarIds}
                calendarList={calendarList}
                onCalendarIdsChange={handleCalendarsChange}
                fixedPrimary
                fullWidth
              />
            </Grid>

            <Grid item style={{ width: "100%" }}>
              <Typography variant="h6">
                Favorite conferencing links or locations
              </Typography>
              <FavoriteLocationsSelector
                locations={settings.locations}
                onLocationChange={handleLocationsChange}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <WorkingHours />
            </Grid>
          </Grid>
        </div>
        <Button
          className={styles.container}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleReset}
        >
          Reset settings
        </Button>
      </Paper>
    </div>
  );
};

export default CurrentUserPage;
