import React, { createContext, useCallback, useEffect, useState } from "react";
import { Settings$DTO } from "@trysmarty/shared";
import { saveSettings } from "./services/User";
import { anonymize, identifyUser, trackEvent } from "./services/Tracking";
import { resetSettings } from "./services/temporary";

interface ContextProps {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  image: string;
  timeZone: string;
  links: string[];
  settings?: Settings$DTO | null;
  login: () => void;
  logout: () => void;
  resetProfile: () => void;
  changeLinks: (links: string[]) => void;
  changeSettings: (settings: Settings$DTO) => void;
}

export const UserContext = createContext<ContextProps>({
  isAuthenticated: false,
  isAuthenticating: true,
  email: "",
  firstName: "",
  lastName: "",
  username: "",
  image: "",
  timeZone: "",
  links: [],
  settings: undefined,
  login: () => {},
  logout: () => {},
  resetProfile: () => {},
  changeLinks: () => {},
  changeSettings: () => {},
});

export const UserProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [links, setLinks] = useState<string[]>([]);
  const [settings, setSettings] = useState<Settings$DTO>();

  useEffect(() => {
    // find the cookie
    const match = document.cookie.match(new RegExp(`(^| )smarty=([^;]+)`));
    if (match && match[2]) {
      fetch("/backend/api/v1/user/me")
        .then((response1) => {
          if (!response1.ok) {
            setAuthenticated(false);
            setAuthenticating(false);
            if (response1.status >= 400 && response1.status < 500) {
              document.cookie = `smarty="";-1; path=/`;
            }
          }
          response1
            .json()
            .then((response2) => {
              localStorage.setItem("user", JSON.stringify(response2));
              identifyUser(
                response2.username,
                email,
                `${response2.firstName} ${response2.lastName}`
              );
              setEmail(response2.email);
              setUsername(response2.username);
              setFirstName(response2.firstName);
              setLastName(response2.lastName);
              setImage(response2.image);
              // let oldTimeZone = [];
              // oldTimeZone = response2.timeZone;
              setTimeZone("America/Adak");
              setLinks(response2.links);
              setSettings(response2.settings);
              setAuthenticated(true);
              setAuthenticating(false);
            })
            .catch((error) => {
              console.error(error); // todo(hmassad): handle error
              setAuthenticating(false);
            });
        })
        .catch((error) => {
          console.error(error); // todo(hmassad): handle error
          setAuthenticating(false);
        });
    } else {
      setAuthenticated(false);
      setAuthenticating(false);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = () => {
    trackEvent("login attempt");
    // navigate to google
    window.location.pathname = "/signin/google";
  };
  const saveProfile = async (links, settings) => {
    const filtered: string[] = links.filter((link: string) => link);
    if (!settings) return;

    try {
      await saveSettings(filtered, settings!);
      trackEvent("saveSettings");

      const userDataString = localStorage.getItem("user");
      const userData = userDataString ? JSON.parse(userDataString) : null;
      if (userData) {
        userData.links = filtered;
        userData.settings = settings;
      }
      localStorage.setItem("user", JSON.stringify(userData));
    } catch (err) {
      console.error(err);
    }
  };

  const resetProfile = async () => {
    try {
      const user = await resetSettings();

      trackEvent("resetSettings");

      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const changeLinks = useCallback(
    (links: string[]) => {
      setLinks([...links]);
      saveProfile(links, settings).catch(console.error);
    },
    [settings]
  );

  const changeSettings = useCallback(
    (settings: Settings$DTO) => {
      setSettings(settings);
      saveProfile(links, settings).catch(console.error);
    },
    [links]
  );

  const logout = () => {
    trackEvent("logout");
    anonymize();
    localStorage.removeItem("user");
    document.cookie = `smarty="";-1; path=/`;
    setEmail("");
    setUsername("");
    setFirstName("");
    setLastName("");
    setImage("");
    setTimeZone("");
    setLinks([]);
    setSettings(undefined);
    setAuthenticated(true);
  };

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        email,
        firstName,
        lastName,
        username,
        image,
        timeZone,
        links,
        settings,
        login,
        logout,
        resetProfile,
        changeLinks,
        changeSettings,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
