import React from "react";
import { Link } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SocialIcon from "../../ui/SocialIcon/SocialIcon";
import { formatLink } from "@trysmarty/shared";

interface IComponentProps {
  link: string;
}

const useStyle = makeStyles(() => ({
  icon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
}));

const LinkIcon: React.FC<IComponentProps> | null = ({
  link,
}: IComponentProps) => {
  const classes = useStyle();
  return (
    <Link id={link} href={formatLink(link)} target="_blank" rel="noopener">
      <SocialIcon className={classes.icon} link={link} />
    </Link>
  );
};

export default LinkIcon as React.ComponentType<IComponentProps>;
