import moment, { unitOfTime } from "moment-timezone";
import { RawTimeZone, rawTimeZones } from "@vvo/tzdb";
import { IInterval } from ".";

const findTzDbZone = (value: string): RawTimeZone | undefined => {
  let tzDbZone = rawTimeZones.find(
    (rtz) => rtz.name === value || rtz.group.includes(value)
  );
  if (!tzDbZone) {
    // fix for America/Buenos_Aires -> America/Argentina/Buenos_Aires
    const splitSlashesThenGetFirstAndLast = (s: string) => {
      const parts = s.split("/");
      return `${parts[0]}/${parts[parts.length - 1]}`;
    };
    tzDbZone = rawTimeZones.find(
      (rtz) =>
        splitSlashesThenGetFirstAndLast(rtz.name) === value ||
        rtz.group.some((tzg) => splitSlashesThenGetFirstAndLast(tzg) === value)
    );
  }
  return tzDbZone;
};

export const getBrowserTimeZone = (): string => {
  const rawTimeZone = findTzDbZone(moment.tz.guess(true));
  if (!rawTimeZone) return "";
  return rawTimeZone.name;
};

export const formatDateRange = ({
  start,
  end,
  tz,
}: {
  start: moment.MomentInput;
  end: moment.MomentInput;
  tz?: string;
}): string => {
  const timeZone = tz || getBrowserTimeZone();

  const start1 = moment(start).tz(timeZone);
  const end1 = moment(end).tz(timeZone);

  if (start1.get("year") !== end1.get("year")) {
    // 29 Dec 2019-4 Jan 2020
    return `${start1.format("D MMM YYYY")}-${end1.format("D MMM YYYY")}`;
  }

  if (start1.get("month") !== end1.get("month")) {
    // 26 Jan-1 Feb 2020
    return `${start1.format("D MMM")}-${end1.format("D MMM YYYY")}`;
  }

  // 5-11 January 2020
  return `${start1.format("D")}-${end1.format("D MMMM YYYY")}`;
};

/**
 * Gets first and last dates of a period containing date
 */
export const getFirstAndLastOf = (
  period: string | null,
  date: Date | undefined | null = new Date(),
  timeZone = getBrowserTimeZone()
): { start: Date; end: Date } => ({
  start: moment(date)
    .tz(timeZone)
    .startOf(<unitOfTime.StartOf>period)
    .toDate(),
  end: moment(date)
    .tz(timeZone)
    .endOf(<unitOfTime.StartOf>period)
    .toDate(),
});

/**
 * @description Gets days of the week for the selected date
 * @export
 * @param {Date} date
 * @param timeZone
 * @return {[Date]}
 */
export const getDaysOfWeek = (
  date: Date | undefined | null = new Date(),
  timeZone = getBrowserTimeZone()
) => {
  const start = moment(date).tz(timeZone).startOf("weeks");
  return Array.from({ length: 7 }).map((_, i) =>
    start.clone().add(i, "days").toDate()
  );
};

/**
 * @description Returns the duration options
 * @return {[{label: string, value: string}]}
 */
export const getDurationOptions = () => {
  const options = [];

  for (let i = 60; i > 0; i -= 10) {
    // const hours = Math.floor(i / 60);
    const minutes = i;
    const optionText = [
      minutes ? `${minutes} minute${minutes > 1 ? "s" : ""}` : undefined,
    ].join(" ");

    options.push({
      value: i,
      label: optionText,
    });
  }
  return options;
};

/**
 * @description
 * @export
 * @param {*} intervals
 * @returns
 */
// @ts-ignore
export function mergeSortedIntervals(intervals) {
  // test if there are at least 2 intervals
  if (intervals.length <= 1) {
    return intervals;
  }

  const stack = [];
  let top = null;

  // push the 1st interval into the stack
  stack.push(intervals[0]);

  // start from the next interval and merge if needed
  for (let i = 1; i < intervals.length; i++) {
    // get the top element
    top = stack[stack.length - 1];

    // if the current interval doesn't overlap with the
    // stack top element, push it to the stack
    if (top[1] < intervals[i][0]) {
      stack.push(intervals[i]);
    } else if (top[1] < intervals[i][1]) {
      // otherwise update the end value of the top element
      // if end of current interval is higher
      // eslint-disable-next-line prefer-destructuring
      top[1] = intervals[i][1];
      stack.pop();
      stack.push(top);
    }
  }

  return stack;
}

/**
 * timezones that shouldn't be considered for inference
 */
const inferringExcludedTimeZones = [
  "Europe/Isle_of_Man",
  "Europe/Jersey",
  "Europe/Guernsey",
];

/**
 * Infer time zone based on offset and abbreviation, returns IANA time zone value
 * @param abbr
 * @param offsetMinutes
 * @param date
 * @returns {String} time zone IANA value, e.g. America/New_York or America/Argentina/Buenos_Aires
 */
export const inferTimeZone = (
  abbr: string,
  offsetMinutes: Number | undefined = undefined,
  date: Date | undefined | null = new Date()
) => {
  // add PT and ET
  if (["PT", "PDT", "PST"].indexOf(abbr) > -1) return "America/Los_Angeles";
  if (["MT", "MDT", "MST"].indexOf(abbr) > -1) return "America/Phoenix";
  if (["CT", "CDT", "CST"].indexOf(abbr) > -1) return "America/Chicago";
  if (["ET", "EDT", "EST"].indexOf(abbr) > -1) return "America/New_York";
  if (["BT", "BDT", "BST", "GMT"].indexOf(abbr) > -1) return "Europe/London";

  // first US timezones, then rest of the world sorted by population
  const usTimeZoneNames = moment.tz.zonesForCountry("US");
  return (
    usTimeZoneNames
      .map((timeZoneName) => moment.tz.zone(timeZoneName))
      .sort((a, b) => {
        // make new york top of the list
        if (a && a.name === "America/New_York") return -1;
        if (b && b.name === "America/New_York") return 1;
        return 0;
      })
      .concat(
        moment.tz
          .names()
          .filter(
            (timeZoneName) => !usTimeZoneNames.some((n) => n === timeZoneName)
          )
          .map((timeZoneName) => moment.tz.zone(timeZoneName))
          .sort((a, b) => b!.population - a!.population)
      )
      .filter((tz) => !inferringExcludedTimeZones.includes(tz!.name))
      .filter((tz) => findTzDbZone(tz!.name))
      .filter(
        (tz) =>
          tz!.abbr(date!.getTime()) === abbr &&
          (offsetMinutes === undefined ||
            tz!.utcOffset(date!.getTime()) === offsetMinutes)
      )
      .map((tz) => tz!.name)
      .find(() => true) || null
  );
};

const formatUtcOffset = (offset: number) => {
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.abs(offset % 60);
  return `GMT${offset > 0 ? "-" : "+"}${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;
};

interface TimeZoneLabelValue {
  label: string;
  value: string;
  abbr: string;
  offset: number;
}

const mapZoneToLabelValue = (
  momentZone: moment.MomentZone | null,
  tzDbZone: RawTimeZone | undefined,
  date: string | number | Date
): TimeZoneLabelValue | null => {
  // eslint-disable-next-line no-param-reassign
  date = new Date(date);

  if (!momentZone) {
    return null;
  }
  const value = momentZone.name;
  // @ts-ignore
  const abbr = momentZone.abbr(date);
  let formattedAbbr;
  if (abbr.startsWith("-") || abbr.startsWith("+")) {
    if (abbr.length === 5) {
      formattedAbbr = `GMT${abbr.substring(0, 3)}:${abbr.substring(3)}`;
    } else if (abbr.length === 3) {
      formattedAbbr = `GMT${abbr}`;
    } else {
      formattedAbbr = abbr;
    }
  } else {
    formattedAbbr = abbr;
  }
  // @ts-ignore
  const offset = momentZone.utcOffset(date);

  let label;
  if (tzDbZone) {
    label = `(${formatUtcOffset(offset)}) ${tzDbZone.alternativeName} - ${
      tzDbZone.mainCities[0]
    }`;
  } else {
    label = value;
  }

  return {
    label,
    value,
    abbr: formattedAbbr,
    offset,
  };
};

/**
 * get all time zones label, value and abbreviation
 * @param date
 * @returns {[{label: string, value: string, abbr: string}]}
 */
export const getTimeZones = (date = new Date()): TimeZoneLabelValue[] => {
  // eslint-disable-next-line no-param-reassign
  date = new Date(date);

  return rawTimeZones
    .map((rtz) => ({
      momentZone: moment.tz.zone(rtz.name),
      tzDbZone: rtz,
    }))
    .sort(
      (a, b) =>
        // @ts-ignore
        b.momentZone.utcOffset(date.getTime()) -
        // @ts-ignore
        a.momentZone.utcOffset(date.getTime())
    )
    .map((z) => mapZoneToLabelValue(z.momentZone, z.tzDbZone, date)!);
};

/**
 * find time zone by value and date, returns time zone label, value and abbreviation
 * @param {string} value
 * @param {Date | String} date
 * @returns {{label: string, abbr: (string), value: string}}
 */
export const findTimeZone = (value: string, date = new Date()) => {
  // eslint-disable-next-line no-param-reassign
  date = new Date(date);
  const tzDbZone = findTzDbZone(value);
  return mapZoneToLabelValue(
    moment.tz.zone((tzDbZone && tzDbZone.name) || value),
    tzDbZone,
    date
  );
};

/**
 * round up date to next 'minutes' block, e.g. roundToNextBlock(12:17 PM, 30) => 12:30 PM
 * @param date {string | number | Date}
 * @param minutes {number}
 * @returns {number|*}
 */
export const roundToNextBlock = (
  date: string | number | Date,
  minutes: number
) => {
  // eslint-disable-next-line no-param-reassign
  date = new Date(date);
  const milliseconds = date.getTime();
  const gap = minutes * 60 * 1000;
  const remainder = milliseconds % gap;
  if (!remainder) {
    return date;
  }
  return new Date(milliseconds + gap - remainder);
};

export const daysInRange = (
  start: moment.MomentInput,
  end: moment.MomentInput,
  timeZone: string
) => {
  const arr = [];
  const lastDay = moment(end).tz(timeZone);
  if (lastDay.seconds() > 0) {
    lastDay.add(1, "days");
  }
  const currentDay = moment(start).tz(timeZone).startOf("day");
  while (currentDay.isBefore(lastDay)) {
    arr.push(currentDay.toDate());
    currentDay.add(1, "days");
  }
  return arr;
};

/**
 * given a collection of available ranges and a duration of interval, choose the first, the last and middle interval
 * @param availableRanges
 * @param duration
 * @returns {[*, *]|[*]|*[]}
 */
export const calculateRecommendedTimes = (
  availableRanges: { [x: string]: any[] },
  duration = 0
) => {
  const days = Object.keys(availableRanges);

  if (days.length === 0) {
    return [];
  }

  // split ranges into intervals of duration length
  const intervals = {};
  days.forEach((day) => {
    // @ts-ignore
    intervals[day] = [];
    availableRanges[day].forEach((range) => {
      let intervalStart = moment(range.start);
      while (
        intervalStart.isSameOrBefore(
          moment(range.end).subtract(duration, "minutes")
        )
      ) {
        const intervalEnd = intervalStart.clone().add(duration, "minutes");
        // @ts-ignore
        intervals[day].push({
          start: intervalStart.toDate(),
          end: intervalEnd.toDate(),
        });
        intervalStart = intervalStart.clone().add(30, "minutes");
      }
    });
  });

  if (days.length === 1) {
    // @ts-ignore
    const recommendedTimes = [intervals[days[0]][0]];
    // @ts-ignore
    if (intervals[days[0]].length === 2) {
      // @ts-ignore
      recommendedTimes.push(intervals[days[0]][1]);
      // @ts-ignore
    } else if (intervals[days[0]].length > 2) {
      // one from de middle
      recommendedTimes.push(
        // @ts-ignore
        intervals[days[0]][Math.floor(intervals[days[0]].length / 2)]
      );
      // the last one
      // @ts-ignore
      recommendedTimes.push(intervals[days[0]][intervals[days[0]].length - 1]);
    }
    return recommendedTimes;
  }
  if (days.length === 2) {
    // @ts-ignore
    const recommendedTimes = [intervals[days[0]][0], intervals[days[1]][0]];
    // @ts-ignore
    if (intervals[days[1]].length === 1 && intervals[days[0]].length > 1) {
      recommendedTimes.splice(
        1,
        0,
        // @ts-ignore
        intervals[days[0]][intervals[days[0]].length - 1]
      );
      return recommendedTimes;
    }
    // @ts-ignore
    recommendedTimes.push(intervals[days[1]][intervals[days[1]].length - 1]);

    return recommendedTimes;
  }
  // 3 or more
  // @ts-ignore
  const recommendedTimes = [intervals[days[0]][0]];
  let midTimeSlot;
  if (days.length > 1) {
    const midDate = days[Math.floor(days.length / 2)];
    // @ts-ignore
    midTimeSlot = intervals[midDate][intervals[midDate].length - 1];
    recommendedTimes.push(midTimeSlot);
  }
  const lastDate = days[days.length - 1];
  // @ts-ignore
  const lastTimeSlot = intervals[lastDate][intervals[lastDate].length - 1];
  if (
    midTimeSlot &&
    midTimeSlot.start.getTime() !== lastTimeSlot.start.getTime()
  ) {
    recommendedTimes.push(lastTimeSlot);
  }
  return recommendedTimes;
};

export const calculateAllTimeSlots = (
  availableRanges: { [x: string]: any[] },
  duration = 0
) => {
  const days = Object.keys(availableRanges);

  if (days.length === 0) {
    return [];
  }

  // split ranges into intervals of duration length
  const intervals = {};

  const recommendedTimes: { start: Date; end: Date }[] = [];

  days.forEach((day) => {
    // @ts-ignore
    intervals[day] = [];
    availableRanges[day].forEach((range) => {
      let intervalStart = moment(range.start);
      while (
        intervalStart.isSameOrBefore(
          moment(range.end).subtract(duration, "minutes")
        )
      ) {
        const intervalEnd = intervalStart.clone().add(duration, "minutes");
        // @ts-ignore
        intervals[day].push({
          start: intervalStart.toDate(),
          end: intervalEnd.toDate(),
        });
        recommendedTimes.push({
          start: intervalStart.toDate(),
          end: intervalEnd.toDate(),
        });
        intervalStart = intervalStart.clone().add(30, "minutes");
      }
    });
  });

  return recommendedTimes;
};

export const formatDateWithTwoTimeZones = (
  date: moment.MomentInput,
  primaryTimeZone: string,
  secondaryTimeZone: string | null
) => {
  const _date = moment(date);

  const momentPrimaryTZ = _date.clone().tz(primaryTimeZone);
  const abbrPrimaryTZ = findTimeZone(primaryTimeZone, _date.toDate())!.abbr;
  const abbrSecondaryTZ = secondaryTimeZone
    ? findTimeZone(secondaryTimeZone, _date.toDate())!.abbr
    : null;

  // only one timezone or primary is the same as secondary
  if (!secondaryTimeZone || abbrPrimaryTZ === abbrSecondaryTZ) {
    // eslint-disable-next-line prettier/prettier
    return `${moment(date)
      .tz(primaryTimeZone)
      .format("ddd, MMM D, h:mm a")} ${abbrPrimaryTZ}`;
  }

  const momentSecondaryTZ = _date.clone().tz(secondaryTimeZone);

  // days are different because of timezones
  if (momentPrimaryTZ.day() !== momentSecondaryTZ.day()) {
    // eslint-disable-next-line prettier/prettier
    return `${momentPrimaryTZ.format(
      "ddd, MMM D, h:mm a"
    )} ${abbrPrimaryTZ} (${momentSecondaryTZ.format(
      "ddd, MMM D, h:mm a"
    )} ${abbrSecondaryTZ})`;
  }

  // same day, two timezones
  // eslint-disable-next-line prettier/prettier
  return `${momentPrimaryTZ.format(
    "ddd, MMM D, h:mm a"
  )} ${abbrPrimaryTZ} (${momentSecondaryTZ.format(
    "h:mm a"
  )} ${abbrSecondaryTZ})`;
};

export const isSameTimeZone = (
  timeZone1: string,
  timeZone2: string,
  date = new Date()
) =>
  findTimeZone(timeZone1, date)!.offset ===
  findTimeZone(timeZone2, date)!.offset;

/**
 *
 * @param date {Date}
 * @param minutes {number}
 * @returns {Date}
 */
export const nearestMinutes = (date: moment.MomentInput, minutes: number) => {
  const m = moment(date);
  const roundedMinutes = Math.round(m.minute() / minutes) * minutes;
  return m.startOf("hours").minute(roundedMinutes).toDate();
};

/**
 *
 * @param date {Date}
 * @param minutes {number}
 * @returns {Date}
 */
export const nearestPastMinutes = (
  date: moment.MomentInput,
  minutes: number
) => {
  const m = moment(date);
  const roundedMinutes = Math.floor(m.minute() / minutes) * minutes;
  return m.startOf("hours").minute(roundedMinutes).toDate();
};

/**
 *
 * @param date {Date}
 * @param minutes {number}
 * @returns {Date}
 */
export const nearestFutureMinutes = (
  date: moment.MomentInput,
  minutes: number
) => {
  const m = moment(date);
  const roundedMinutes = Math.ceil(m.minute() / minutes) * minutes;
  return m.startOf("hours").minute(roundedMinutes).toDate();
};

/**
 *
 * @param start1 {Date}
 * @param end1 {Date}
 * @param start2 {Date}
 * @param end2 {Date}
 * @returns {boolean}
 */
export const checkCollision = (
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date
) =>
  !!start1 &&
  !!end1 &&
  !!start2 &&
  !!end2 &&
  ((start1.getTime() >= start2.getTime() &&
    start1.getTime() < end2.getTime()) ||
    (end1.getTime() > start2.getTime() &&
      start1.getTime() <= start2.getTime()));

export const isToday = (date: moment.MomentInput, timeZone: string) =>
  moment().tz(timeZone).isSame(moment(date).tz(timeZone), "days");

export const isSameDay = (
  date1: moment.MomentInput,
  date2: moment.MomentInput,
  timeZone: string
) => moment(date1).tz(timeZone).isSame(moment(date2).tz(timeZone), "days");

export const minutesOfDay = (date: moment.MomentInput, timeZone: string) =>
  moment(date)
    .tz(timeZone)
    .diff(moment(date).tz(timeZone).startOf("days"), "minutes");

export const formatShortDuration = (minutes: number): string => {
  if (minutes < 60) return `${minutes} minutes`;
  if (minutes === 60) return `1 hour`;
  if (minutes % 60 === 0) return `${minutes / 60} hours`;
  return `${Math.floor(minutes / 60)}:${minutes % 60} hours`;
};

export const buildIntervalsFromSpecificDateToCustomDays = (
  start: Date,
  end: Date,
  timeZone: string
): IInterval<number>[] => {
  const res = [];
  const current = moment(start).tz(timeZone).startOf("days");
  while (current.isBefore(moment(end))) {
    res.push({
      start: current.valueOf(),
      end: current.clone().add(1, "days").valueOf(),
    });
    current.add(1, "days");
  }
  return res;
};
