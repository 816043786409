import { DEFAULT_FETCH_CONFIG, DEFAULT_HEADERS } from "../configs/network";
import mixpanel from "mixpanel-browser";

/* eslint-disable no-param-reassign */
const request = async (url: string, config: any): Promise<any> => {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    config.headers.mixpanel_distinct_id = mixpanel.get_distinct_id();
  }
  const response = await fetch(url, config);
  if (!response.ok && !response.redirected) {
    const msg = await response.text();
    throw new Error(msg);
  }
  return response;
};
/* eslint-enable no-param-reassign */

// region HTTP method wrappers

export const get = async (url: string): Promise<any> => {
  const response = await request(url, {
    method: "GET",
    ...DEFAULT_FETCH_CONFIG,
    headers: {
      ...DEFAULT_HEADERS,
    },
  });
  return response.json();
};

export const post = async (url: string, data: {}): Promise<any> => {
  const response = await request(url, {
    method: "POST",
    body: JSON.stringify(data),
    ...DEFAULT_FETCH_CONFIG,
    headers: {
      ...DEFAULT_HEADERS,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const patch = async (url: string, data: {}): Promise<any> => {
  const response = await request(url, {
    method: "PATCH",
    body: JSON.stringify(data),
    ...DEFAULT_FETCH_CONFIG,
    headers: {
      ...DEFAULT_HEADERS,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

// noinspection JSUnusedGlobalSymbols
export const del = async (url: string): Promise<any> => {
  const response = await request(url, {
    method: "DELETE",
    ...DEFAULT_FETCH_CONFIG,
    headers: {
      ...DEFAULT_HEADERS,
    },
  });
  return response.json();
};

// endregion HTTP method wrappers
