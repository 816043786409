import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  invalidPageContainer: {
    width: "100vw",
    height: "100vh",
    textAlign: "center",
    overflow: "hidden",
    backgroundColor: "black",
    padding: "200px 25%",
    color: "white",
  },
  invalidSmartyLogo: {
    width: "125px",
    marginBottom: "40px",
  },
  errorContainer: {
    textAlign: "center",
    fontSize: "250px",
    fontFamily: "'Catamaran', sans-serif",
    fontWeight: 800,
    margin: "20px 15px",
  },
  screenReaderText: {
    position: "absolute",
    top: "-9999em",
    left: "-9999em",
  },
}));

interface IComponentProps {
  image: ReactElement;
  message: string | null;
}

export const NotFoundContent = ({ image, message }: IComponentProps) => {
  const styles = useStyles();
  return (
    <div className={styles.invalidPageContainer}>
      <a href="https://smarty.ai/">{image}</a>
      {message ? (
        <h1>{message}</h1>
      ) : (
        <>
          <h1>Whoops! We couldn&apos;t find what you&apos;re looking for.</h1>
          <section className="error-container">
            <span>4</span>
            <span>
              <span className="screen-reader-text">0</span>
            </span>
            <span>4</span>
          </section>
        </>
      )}
    </div>
  );
};
