import React, { FC, useEffect, useState } from "react";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Fade,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Paper,
} from "@material-ui/core";
import { Error$DTO, getBrowserTimeZone } from "@trysmarty/shared";
import { getErrors } from "../services/errors";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
  },
  titleListItem: {
    overflowY: "auto",
    height: "230px",
    "& .MuiListItem-container": {
      listStyleType: "none",
    },
  },
  gridList: {
    height: "350px",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    "& .MuiGridListTileBar-titleWrap": {
      color: "#1f678f",
    },
  },
  select: {
    width: "100%",
  },
  wrapper: {
    margin: 10,
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& .MuiInputLabel-formControl": {
      top: "15px",
      left: "5px",
    },
  },
  pageContainer: {
    minHeight: (props: { mobile: boolean }): number =>
      props.mobile ? 1000 : 500,
    position: "relative",
  },
  button: {
    width: 200,
  },
  bookedIcon: {
    backgroundColor: "#ff0081",
    width: 56,
    height: 56,
    transform: "rotate(-15deg)",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      backgroundColor: "#fff",
      width: 56,
      height: 56,
      borderRadius: theme.spacing(1),
      transform: "rotate(15deg)",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    width: "100%",
  },
}));

const ErrorReport: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const classes = useStyles({ mobile });
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<Error$DTO[]>([]);
  const tz = getBrowserTimeZone();

  useEffect(() => {
    setLoading(true);
    getErrors()
      .then((errorBoundaryReports) => {
        setErrors(errorBoundaryReports);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className={classes.wrapper}>
        <Fade in={loading}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Grid>
        </Fade>
      </div>
    );
  }

  if (error) {
    return error.toString();
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Message</TableCell>
            <TableCell align="center">Details</TableCell>
            <TableCell align="center">Source</TableCell>
            <TableCell align="center">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errors?.map((error, index) => (
            <TableRow key={`${error.email}_${index}`}>
              <TableCell align="center" component="th" scope="row">
                {error.email}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {error.message}
              </TableCell>
              <TableCell align="center">{error.details}</TableCell>
              <TableCell align="center">{error.source}</TableCell>
              <TableCell align="center">
                {moment(error.date).tz(tz).format("MM/DD/yyyy LT")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ErrorReport;
