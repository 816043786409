import React from "react";
import { DAYS } from "../../../configs/ui";
import { IInterval } from "@trysmarty/shared";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 40,
  },
}));

interface IComponentProps {
  workingHours: (null | IInterval<number>[])[];
  onToggleDay: (day: number) => void;
}

const AvailableDays: React.FC<IComponentProps> | null = ({
  workingHours,
  onToggleDay,
}: IComponentProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} style={{ margin: "25px" }}>
      {DAYS.map((day: string, index: number) => {
        const handleClick = (): void => {
          onToggleDay(index);
        };

        return (
          <Grid key={day} item>
            <Button
              onClick={handleClick}
              className={classes.button}
              color="primary"
              disableElevation
              variant={!workingHours[index] ? "outlined" : "contained"}
            >
              {day[0]}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AvailableDays as React.ComponentType<IComponentProps>;
