import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { IconButton, Menu, TextField } from "@material-ui/core";
import { CalendarItem } from "@trysmarty/shared";

// todo(hmassad): text overflow ellipsis on calendar names

interface IconCalendarSelectorProps {
  calendarIds: string[];
  calendarList: CalendarItem[];
  onCalendarIdsChange: (calendarId: string, added: boolean) => void;
  fixedPrimary: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: "100%",
    maxWidth: 300,
  },
  labelColor: {
    height: 8,
    width: 8,
    minWidth: 8,
    marginRight: 8,
    borderRadius: 50,
    display: "inline-block",
  },
}));

export const IconCalendarSelector = ({
  calendarIds = [],
  calendarList = [],
  onCalendarIdsChange = () => {},
  fixedPrimary,
}: IconCalendarSelectorProps) => {
  const [calendars, setCalendars] = useState<CalendarItem[]>(calendarList);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const iconButtonRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");

  const selectedIds = useMemo(() => {
    const primaryCalendarId = calendars.find((x) => x.primary)?.id;
    if (primaryCalendarId) {
      const newCalendarIds = [...calendarIds];
      const index = calendarIds.findIndex((x) => x === "primary");
      newCalendarIds[index] = primaryCalendarId;
      return newCalendarIds;
    }
    return calendarIds;
  }, [calendarIds, calendars]);

  const styles = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: null, // fix anchor
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSearchValueChange = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    setCalendars(
      calendarList.filter(
        (calendar) =>
          calendar.id.toUpperCase().includes(searchValue.toUpperCase()) ||
          calendar.name.toUpperCase().includes(searchValue.toUpperCase())
      )
    );
  }, [calendarList, searchValue]);

  const handleIconButtonClick = () => {
    setCalendarOpen((prev) => !prev);
  };

  const handleClose = () => {
    setCalendarOpen(false);
  };

  const calendarSelectorItems = useMemo(
    () =>
      calendars?.map((calendar: CalendarItem) => {
        const checked =
          (fixedPrimary && calendar.primary) ||
          selectedIds.indexOf(calendar.id) > -1;
        return (
          <MenuItem
            key={calendar.id}
            value={calendar.id}
            disabled={fixedPrimary && calendar.primary}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onCalendarIdsChange) {
                onCalendarIdsChange(calendar.id, !checked);
              }
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={fixedPrimary && calendar.primary}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled color="primary" />}
                  checked={checked}
                />
              }
              label={
                <Box display="flex" flexDirection="row" alignItems="center">
                  <span
                    className={styles.labelColor}
                    style={{ backgroundColor: calendar.color }}
                  />
                  <ListItemText primary={calendar.name} title={calendar.name} />
                </Box>
              }
            />
          </MenuItem>
        );
      }),
    [
      calendars,
      fixedPrimary,
      selectedIds,
      styles.labelColor,
      onCalendarIdsChange,
    ]
  );

  const autocomplete = useMemo(
    () => (
      <MenuItem
        key="find_menu-item"
        id="find_menu-item"
        value=""
        onKeyDown={(e) => e.stopPropagation()}
      >
        <TextField
          id="Find_calendar"
          label="Find Calendar"
          type="input"
          fullWidth
          autoComplete="off"
          value={searchValue}
          onChange={handleSearchValueChange}
        />
      </MenuItem>
    ),
    [searchValue]
  );

  if (!calendars) return null;

  return (
    <>
      <IconButton
        color="primary"
        onClick={handleIconButtonClick}
        ref={iconButtonRef}
        size="small"
      >
        <DateRangeIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={iconButtonRef.current}
        keepMounted
        open={isCalendarOpen}
        onClose={handleClose}
        {...MenuProps}
      >
        {autocomplete}
        {calendarSelectorItems}
      </Menu>
    </>
  );
};
