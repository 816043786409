import React, { useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from "../UserContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  makeStyles,
  Theme,
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  Button,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@material-ui/core";
import {
  Home,
  Today,
  AccountCircle,
  Help,
  Chat,
  Subject,
  Close,
} from "@material-ui/icons";

const useStyles = makeStyles<Theme, { bottomRoundedBorders: boolean }>(
  (theme) => ({
    bar: {
      backgroundColor: "white",
      color: "black",
    },
    root: {
      "& .MuiIconButton-root:hover": {
        backgroundColor: "#dbeefd !important",
        borderRadius: "4px",
      },
    },
    drawerContainer: {
      "& .MuiDrawer-paper": {
        zIndex: 500,
      },
      "& .MuiListItem-button:hover": {
        backgroundColor: "rgb(255 51 154 / 10%)",
      },
    },
    selectedItem: {
      background: "rgb(255 51 154 / 20%) !important",
    },
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      "& [contentEditable=true]:empty:not(:focus):before": {
        content: "attr(data-text)",
      },
    },
    fullWidth: {
      width: "100%",
    },
    floatingWidth: {
      width: "600px",
      top: "25%",
      left: "calc(50vw - 300px)",
    },
    paperHeader: {
      display: "inline-flex",
      alignItems: "center",
      borderBottom: "1px solid #e7e7e7",
      width: "100%",
      padding: "15px 10px",
    },
  })
);

export const Sidebar = () => {
  const styles = useStyles({ bottomRoundedBorders: true });
  const mobile = useMediaQuery("(max-width: 650px)");
  const [selectedItemToShow, setSelectedItemToShow] = React.useState(0);
  const [expandedMode, setExpandedMode] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [moduleWidth, setModuleWidth] = React.useState("100%");
  const [topPos, setTopPos] = React.useState("0px");
  const [leftPos, setLeftPos] = React.useState("0px");
  const [category, setCategory] = React.useState("");
  const [value, setValue] = React.useState("");
  const { isAuthenticated } = useContext(UserContext);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleSidebar = () => {
    setExpandedMode((prev) => !prev);
  };

  const sidebarItems = [
    { label: "Calendar", link: "/calendar", icon: <Today /> },
    { label: "Templates", link: "/templates", icon: <Subject /> },
    // { label: "Notes", link: "/notes", icon: <Create /> },
    // { label: "Home", link: "/dashboard", icon: <Home /> },
    // {
    //   label: "Contacts",
    //   link: `/u/${username}/contacts`,
    //   icon: <PermContactCalendar />,
    // },
    // { label: "Tasks", link: `/u/${username}/tasks`, icon: <CheckBox /> },
    // {
    //   label: "Automations",
    //   link: `/u/${username}/automations`,
    //   icon: <WbIncandescent />,
    // },
  ];

  const dividerSidebarItems = [
    { label: "My Account", link: "/myAccount", icon: <AccountCircle /> },
    { label: "Product Tour", link: "/gettingStarted", icon: <Help /> },
    // { label: "Get Help", link: "", icon: <Help /> },
  ];

  useEffect(() => {
    const path = window.location.href;
    const pageType = path.split("/")[path.split("/").length - 1];
    switch (pageType) {
      case "dashboard":
        return setSelectedItemToShow(0);
      case "calendar":
        return setSelectedItemToShow(1);
      case "contacts":
        return setSelectedItemToShow(2);
      case "tasks":
        return setSelectedItemToShow(3);
      case "notes":
        return setSelectedItemToShow(4);
      case "templates":
        return setSelectedItemToShow(5);
      case "automations":
        return setSelectedItemToShow(6);
      case "myAccount":
        return setSelectedItemToShow(7);
      case "help":
        return setSelectedItemToShow(8);
      case "feedback":
        return setSelectedItemToShow(9);
      default:
        return setSelectedItemToShow(-1);
    }
  }, []);

  const handleSelectedItem = (index) => {
    setSelectedItemToShow(index);
  };

  useEffect(() => {
    if (mobile) {
      setModuleWidth("100%");
      setTopPos("0px");
      setLeftPos("0px");
      setExpandedMode(false);
    } else {
      setModuleWidth("600px");
      setTopPos("50px");
      setLeftPos("calc(50vw - 300px");
      setExpandedMode(false);
    }
  }, [mobile]);

  return (
    <>
      {mobile && (
        <Box sx={{ overflow: "auto", width: "100vw" }}>
          <List style={{ background: "white" }}>
            <ListItem>
              <IconButton
                edge="start"
                className={styles.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleSidebar}
              >
                <Home />
              </IconButton>

              <ListItemText
                primary={
                  <Typography
                    variant="h3"
                    noWrap
                    component={RouterLink}
                    to="/"
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      color: "#FF0081",
                      textDecoration: "none",
                    }}
                  >
                    Smarty
                  </Typography>
                }
                style={{ marginLeft: 10 }}
              />
            </ListItem>
          </List>
        </Box>
      )}
      {(!mobile || (mobile && expandedMode)) && (
        <>
          <Drawer variant="permanent" className={styles.drawerContainer}>
            <Box sx={{ overflow: "auto" }}>
              <List>
                <ListItem>
                  <IconButton
                    edge="start"
                    className={styles.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleSidebar}
                  >
                    <Home />
                  </IconButton>

                  {expandedMode && (
                    <ListItemText
                      primary={
                        <Typography
                          variant="h3"
                          noWrap
                          component={RouterLink}
                          to="/"
                          style={{
                            fontSize: "28px",
                            fontWeight: "bold",
                            color: "#FF0081",
                            textDecoration: "none",
                          }}
                        >
                          Smarty
                        </Typography>
                      }
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </ListItem>
                {sidebarItems.map((item, index) => (
                  <ListItem
                    button
                    key={item.label}
                    component={RouterLink}
                    to={item.link}
                    className={
                      index === selectedItemToShow ? styles.selectedItem : ""
                    }
                    onClick={() => handleSelectedItem(index)}
                  >
                    {item.icon}
                    {expandedMode && (
                      <ListItemText
                        primary={item.label}
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                {dividerSidebarItems.map((item, index) => (
                  <ListItem
                    button
                    key={item.label}
                    component={RouterLink}
                    to={item.link}
                    className={
                      index + sidebarItems.length === selectedItemToShow
                        ? styles.selectedItem
                        : ""
                    }
                    onClick={() =>
                      handleSelectedItem(index + sidebarItems.length)
                    }
                  >
                    {item.icon}
                    {expandedMode && (
                      <ListItemText
                        primary={item.label}
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </ListItem>
                ))}
                <ListItem
                  button
                  className={
                    sidebarItems.length + dividerSidebarItems.length ===
                    selectedItemToShow
                      ? styles.selectedItem
                      : ""
                  }
                  onClick={handleOpen}
                >
                  <Chat />
                  {expandedMode && (
                    <ListItemText
                      primary="Share Feedback"
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={styles.paper}
              style={{ width: moduleWidth, top: topPos, left: leftPos }}
            >
              <div className={mobile ? styles.fullWidth : styles.floatingWidth}>
                <div className={styles.paperHeader}>
                  <Close style={{ marginRight: 10 }} onClick={handleClose} />
                  <Typography variant="h5" component="h2">
                    Share feedback
                  </Typography>
                </div>
                <div
                  style={{ width: "100%", textAlign: "center", padding: 10 }}
                >
                  <Typography variant="h4" component="h2">
                    Smarty thrives on feedback! <br />
                    Please tell us about your experience.
                  </Typography>
                </div>
                <div style={{ padding: 25 }}>
                  <Typography variant="h6" component="h2">
                    Select a feature to provide feedback about:
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    onChange={handleCategoryChange}
                    style={{
                      width: "100%",
                      border: "solid 1px #e7e7e7",
                      margin: "20px 0",
                    }}
                  >
                    <MenuItem value="meetinglinks">Meeting Links</MenuItem>
                    <MenuItem value="events">Events</MenuItem>
                    <MenuItem value="notes">Notes</MenuItem>
                    <MenuItem value="templates">Templates</MenuItem>
                    <MenuItem value="contacts">Contacts</MenuItem>
                    <MenuItem value="tasks">Tasks</MenuItem>
                    <MenuItem value="automations">Automations</MenuItem>
                  </Select>
                  <Typography variant="h6" component="h2">
                    What would you like us to know?
                  </Typography>
                  <div
                    contentEditable
                    data-text="Add your comments"
                    style={{
                      height: 100,
                      border: "solid 1px #e7e7e7",
                      padding: 10,
                      margin: "20px 0",
                    }}
                  />
                  {isAuthenticated && (
                    <>
                      <Typography variant="h6" component="h2">
                        Can we contact you if we have follow-up questions?
                      </Typography>
                      <RadioGroup
                        value={value}
                        onChange={handleChange}
                        style={{ margin: "20px 0" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, please contact me"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, I don't want to help further"
                        />
                      </RadioGroup>
                    </>
                  )}
                  <Button size="large" color="primary" variant="contained">
                    Submit feedback
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
