import React, { FC, useContext } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { UserContext } from "../../../UserContext";

const PrivateRoute: FC<RouteProps> = (props) => {
  const location = useLocation();
  const { isAuthenticating, isAuthenticated } = useContext(UserContext);
  if (isAuthenticating) return null;
  return isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/signin",
        state: { from: location.pathname },
      }}
    />
  );
};

export default PrivateRoute;
