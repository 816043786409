import React, { CSSProperties, useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { ListItemText, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface LocationSelectorProps {
  className?: string | undefined;
  style?: CSSProperties | undefined;
  location?: string;
  onLocationChange: (location?: string) => void;
  variant?: "outlined" | "standard";
}

export const SingleLocationSelector = ({
  className,
  style,
  location,
  onLocationChange = () => {},
  variant,
}: LocationSelectorProps) => {
  const {
    ready,
    // value,
    suggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    if (!ready) return;
    setValue(location || "");
  }, [location, ready, setValue]);

  return (
    <Autocomplete
      value={location}
      className={className}
      style={style}
      freeSolo
      options={suggestions.data.map((p) => p.description)}
      getOptionLabel={(description) => description}
      onChange={(e, option) => {
        // is triggered when a location is selected from the drop down list
        e.preventDefault();
        e.stopPropagation();
        setValue(option || "", false);
        onLocationChange(option || "");
        clearSuggestions();
      }}
      onInputChange={(e, option) => {
        // is triggered when the user types in the box
        setValue(option);
        onLocationChange(option || "");
      }}
      renderOption={(option: string) => (
        <ListItemText
          key={option}
          title={option}
          primary={<Typography noWrap>{option}</Typography>}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Location"
          disabled={!ready}
          variant={variant}
        />
      )}
    />
  );
};
