import btoa from "btoa";

export type Nullable<T> = T | null | undefined;

export interface IInterval<T> {
  start: T;
  end: T;
}

export interface IAvailableSlots {
  slots: number[];
  duration: number;
}

export enum TimePeriod {
  INDEFINITELY = "indefinitely",
  ROLLING = "rolling",
  RANGE = "range",
  CUSTOM = "custom",
}

export interface IWeeklyInterval {
  dayOfWeek: number;
  startMinutes: number;
  endMinutes: number;
}

export interface Contact$DTO {
  name?: string;
  email: string;
  img?: string;
}

export const workingHoursDayDefault: IInterval<number>[] = [
  { start: 9, end: 17 },
];

export const workingHoursDefault: (IInterval<number>[] | null)[] = [
  null,
  workingHoursDayDefault,
  workingHoursDayDefault,
  workingHoursDayDefault,
  workingHoursDayDefault,
  workingHoursDayDefault,
  null,
];

export const defaultSettings = {
  workingHoursEnabled: true,
  workingHours: workingHoursDefault,
  duration: 60,
  timezone: "America/New_York",
  selectedCalendarIds: [],
  locations: [],
};

export const COLOR_FACEBOOK = "#3b5998";
export const COLOR_TWITTER = "#00acee";
export const COLOR_LINKEDIN = "#0e76a8";
export const COLOR_INSTAGRAM = "#E1306C";
export const COLOR_EMAIL = "#f46f30";
export const COLOR_DEFAULT_LINK = "#000000";

export const isEmail = (link: string): boolean => {
  try {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(link);
  } catch (e) {
    return false;
  }
};

export const formatLink = (link: string): string => {
  if (isEmail(link)) {
    return `mailto:${link}`;
  }
  // noinspection HttpUrlsUsage
  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link;
  }
  return `//${link}`;
};

export const escapeHTML = (unsafe: string): string =>
  unsafe.replace(
    // eslint-disable-next-line no-control-regex
    /[\u0000-\u002F]|[\u003A-\u0040]|[\u005B-\u00FF]/g,
    // eslint-disable-next-line prefer-template
    (c: string) => `&#${("000" + c.charCodeAt(0)).substr(-4, 4)};`
  );

// RFC 1342
export const escapeEmailHeader = (value: string) =>
  `=?utf-8?B?${btoa(value)}?=`;

export const colorLuminance = (hex: string, lum = 0) => {
  // validate hex string
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    // eslint-disable-next-line no-param-reassign
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // convert to decimal and change luminosity
  let rgb = "#";
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export * from "./dateUtils";
export * from "./calendarUtils";
export * from "./numberUtils";
export * from "./GroupPoll";
export * from "./RequestError";
export * from "./Feedback";
export * from "./Snippet";
export * from "./Note";
export * from "./MeetingLink";
export * from "./Error";
export * from "./ExitSurvey";
export * from "./Settings";
export * from "./GoogleColorsUtils";
export * from "./generatorId";
export * from "./ScheduledEvent";
export * from "./User";
export * from "./CalendarEvent";
export * from "./Calendar";
