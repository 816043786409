import React, { useCallback, useState } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SocialIcon from "../../ui/SocialIcon/SocialIcon";

interface IComponentProps {
  idx: number;
  link: string;
  onUpdate: (link: string, idx: number) => void;
  onRemove: (idx: number) => void;
}

const useStyles = makeStyles(() => ({
  link: {
    "& > .MuiInput-formControl": {
      marginTop: "0 !important",
    },
  },
}));

const LinkInput: React.FC<IComponentProps> | null = ({
  idx,
  link,
  onUpdate,
  onRemove,
}: IComponentProps) => {
  const classes = useStyles();

  const [value, setValue] = useState(link);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleBlur = useCallback(() => {
    onUpdate(value, idx);
  }, [onUpdate, idx, value]);

  const handleRemove = useCallback((): void => {
    onRemove(idx);
  }, [onRemove, idx]);

  return (
    <>
      <TextField
        className={classes.link}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SocialIcon link={value} />
            </InputAdornment>
          ),
        }}
        size="small"
        value={value}
        placeholder="URL / email"
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
      />
      <IconButton aria-label="remove-link" size="small" onClick={handleRemove}>
        <ClearIcon />
      </IconButton>
    </>
  );
};

export default LinkInput as React.ComponentType<IComponentProps>;
