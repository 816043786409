// todo(hmassad): replace with unified API
import {
  Calendar$DTO,
  CalendarItem,
  MeetingLink$DTO,
  UserSettings$DTO,
} from "@trysmarty/shared";
import { get, patch, post, del } from "./network";

export const getGroupPolls = async () =>
  get(`/backend/api/v1/scheduling/polls`);

export const deleteGroupPoll = async (id) =>
  del(`/backend/api/v1/scheduling/polls/${id}`);

export const getMeetingLinks = async (): Promise<MeetingLink$DTO[]> =>
  get(`/backend/api/v1/scheduling/links`);

export const isMeetingLinkSlugAvailable = async (
  id: string,
  slug: string
): Promise<{ available: boolean }> =>
  get(
    `/backend/api/v1/scheduling/links/available?slug=${slug}${
      id ? `&id=${id}` : ""
    }`
  );

export const getMeetingLink = async (id: string): Promise<MeetingLink$DTO> =>
  get(`/backend/api/v1/scheduling/links/${id}`);

export const createMeetingLink = async (meetingLink: MeetingLink$DTO) =>
  post(`/backend/api/v1/scheduling/links`, meetingLink);

export const updateMeetingLink = async (
  id: string,
  meetingLink: MeetingLink$DTO
) => patch(`/backend/api/v1/scheduling/links/${id}`, meetingLink);

export const deleteMeetingLink = async (id: string) =>
  del(`/backend/api/v1/scheduling/links/${id}`);

export const getCalendarEventList = ({
  timeMin,
  timeMax,
  contacts = [],
  calendars = [],
}: {
  timeMin: Date;
  timeMax: Date;
  contacts: string[];
  calendars: string[];
}) => {
  let url = `/backend/api/v1/calendars/events?timeMin=${timeMin.toISOString()}&timeMax=${timeMax.toISOString()}`;
  if (contacts) {
    url += `&contacts=${encodeURIComponent(JSON.stringify(contacts))}`;
  }
  if (calendars && calendars.length) {
    url += `&calendars=${encodeURIComponent(JSON.stringify(calendars))}`;
  }
  return get(url);
};

export const getCalendarList = (): Promise<Calendar$DTO[]> =>
  get("/backend/api/v1/calendars");

export const getCalendarInfo = ({ calendarId }) =>
  get(`/backend/api/v1/calendars/${encodeURIComponent(calendarId)}`);

export const buildMeetingLinkUrl = (meetingLink) => {
  let url = `/u/${meetingLink.username}/meet`;
  if (meetingLink.slug) {
    url += `/${meetingLink.slug}`;
  } else if (meetingLink.id) {
    url += `/${meetingLink.id}`;
  }
  return url;
};

export const createGroupPoll = async (groupPoll) =>
  post(`/backend/api/v1/scheduling/polls`, groupPoll);

export const buildGroupPollUrl = (groupPoll) => {
  let url = `/u/${groupPoll.username}/polls`;
  if (groupPoll.slug) {
    url += `/${groupPoll.slug}`;
  } else if (groupPoll.id) {
    url += `/${groupPoll.id}`;
  }
  return url;
};

export const getFreeTimeSlots = (duration, tz, start, end, meetingLink) =>
  post(`/backend/api/v1/calendars/getfreetimeslots`, {
    meetingLink,
    duration,
    start,
    end,
    tz,
  });

export const getContacts = (inputValue) =>
  get(`/backend/api/v1/people${inputValue ? `?input=${inputValue}` : ""}`);

/** *
 * Text Templates
 */

export const getTextSnippets = async () => get("/backend/api/v1/snippets/");

export const createTextSnippet = async (snippet) =>
  post(`/backend/api/v1/snippets`, snippet);

export const getTextSnippet = async (id) =>
  get(`/backend/api/v1/snippets/${id}`);

export const updateTextSnippet = async (id, snippet) =>
  patch(`/backend/api/v1/snippets/${id}`, snippet);

export const deleteTextSnippet = async (id) =>
  del(`/backend/api/v1/snippets/${id}`);

/** *
 * Notes
 */

export const getNotes = async () => get("/backend/api/v1/notes/");

/**
 * @description function for color mutating
 * @export
 * @param {*} hex
 * @param {*} lum
 * @returns
 */
export const colorLuminance = (hex, lum) => {
  // validate hex string
  // eslint-disable-next-line no-param-reassign
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    // eslint-disable-next-line no-param-reassign
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  // eslint-disable-next-line no-param-reassign
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#";
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export const buildLink = (href, text) =>
  `<a style="color: #64b5f6; font-size: inherit; text-decoration: underline" href="${href}">${text}</a>`;

export const resetSettings = async (): Promise<UserSettings$DTO> =>
  post(`/backend/api/v1/settings/reset`, {});

export const getCalendars = async (): Promise<CalendarItem[]> =>
  get(`/backend/api/v1/calendars`);
