import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {
  Badge,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";
import { CalendarItem } from "@trysmarty/shared";
// todo(hmassad): text overflow ellipsis on calendar names

interface CalendarSelectorProps {
  calendarIds: string[];
  calendarList: CalendarItem[];
  onCalendarIdsChange: (calendarId: string, added: boolean) => void;
  fixedPrimary: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: "100%",
  },
  labelColor: {
    height: 8,
    width: 8,
    minWidth: 8,
    marginRight: 8,
    borderRadius: 50,
    display: "inline-block",
  },
  searchInput: {
    paddingLeft: 14,
    width: "100%",

    marginBottom: 6,
  },
  calendarOptions: {
    width: "100%",

    maxHeight: 150,
    overflow: "hidden",
    overflowY: "scroll",
  },
}));

export const CalendarSelector = ({
  calendarIds = [],
  calendarList = [],
  onCalendarIdsChange = () => {},
  fixedPrimary,
}: CalendarSelectorProps) => {
  const [calendars, setCalendars] = useState<CalendarItem[]>(calendarList);

  const [searchValue, setSearchValue] = useState("");

  const selectedIds = useMemo(() => {
    const primaryCalendarId = calendars.find((x) => x.primary)?.id;
    if (primaryCalendarId) {
      const newCalendarIds = [...calendarIds];
      const index = calendarIds.findIndex((x) => x === "primary");
      newCalendarIds[index] = primaryCalendarId;
      return newCalendarIds;
    }
    return calendarIds;
  }, [calendarIds, calendars]);

  const styles = useStyles();

  const handleSearchValueChange = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    setCalendars(
      calendarList.filter(
        (calendar) =>
          calendar.id.toUpperCase().includes(searchValue.toUpperCase()) ||
          calendar.name.toUpperCase().includes(searchValue.toUpperCase())
      )
    );
  }, [calendarList, searchValue]);

  const calendarSelectorItems = useMemo(
    () =>
      calendars?.map((calendar: CalendarItem) => {
        const checked =
          (fixedPrimary && calendar.primary) ||
          selectedIds.indexOf(calendar.id) > -1;
        return (
          <MenuItem
            id="calendar-selector-menuitem"
            key={calendar.id}
            value={calendar.id}
            disabled={fixedPrimary && calendar.primary}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onCalendarIdsChange) {
                onCalendarIdsChange(calendar.id, !checked);
              }
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={fixedPrimary && calendar.primary}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled color="primary" />}
                  checked={checked}
                />
              }
              label={
                <Box display="flex" flexDirection="row" alignItems="center">
                  <span
                    className={styles.labelColor}
                    style={{ backgroundColor: calendar.color }}
                  />
                  <ListItemText primary={calendar.name} title={calendar.name} />
                </Box>
              }
            />
          </MenuItem>
        );
      }),
    [
      calendars,
      fixedPrimary,
      selectedIds,
      styles.labelColor,
      onCalendarIdsChange,
    ]
  );

  if (!calendars) return null;

  return (
    <Grid container direction="column" className={styles.container}>
      <Grid item style={{ paddingLeft: 14, marginBottom: 10 }}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Badge color="secondary" badgeContent={`${selectedIds.length}`}>
              <DateRangeIcon color="primary" fontSize="small" />
            </Badge>
          </Grid>
          <Grid item>
            <Typography>Calendars</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <FormControl
          variant="outlined"
          className={styles.formControl}
          style={{ maxWidth: 300 }}
        >
          <Grid container direction="column" alignItems="flex-start">
            <Grid item className={styles.searchInput} style={{ maxWidth: 300 }}>
              <TextField
                type="input"
                // variant="outlined"
                fullWidth
                value={searchValue}
                autoComplete="off"
                onChange={handleSearchValueChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setSearchValue("")}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              className={styles.calendarOptions}
              style={{ maxWidth: 300 }}
            >
              {calendarSelectorItems}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};
