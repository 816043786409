import React, { FC, useState, useEffect, useMemo, useCallback } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  FilterNone,
  Subject,
} from "@material-ui/icons";

import {
  getTextSnippets,
  createTextSnippet,
  updateTextSnippet,
  deleteTextSnippet,
} from "../../services/temporary";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Snippet$DTO } from "@trysmarty/shared";

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
      color: "#4ABAF9",
    },
  },
  header: {
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 10px",
    border: "solid #e7e7e7",
    borderWidth: "1px 0",
    background: "white",
  },
  templatesList: {
    border: "solid #e7e7e7",
    borderWidth: "0 1px 1px 1px",
    height: "100vh",
    background: "white",
  },
  selectedItem: {
    background: "rgb(74 186 249 / 20%) !important",
  },
  templateItem: {
    cursor: "pointer",
    height: "calc(100vh - 80px)",
    overflow: "auto",
    "& :hover": {
      background: "rgb(74 186 249 / 10%)",
    },
    "& .MuiTypography-root": {
      background: "transparent",
    },
    "& .MuiListItemText-root:hover": {
      background: "none",
    },
    "& .MuiListItemText-secondary": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .MuiListItemAvatar-root": {
      color: "black",
      background: "transparent !important",
    },
    "& .MuiListItemAvatar-root:hover .MuiAvatar-root": {
      color: "#4ABAF9",
    },
  },
  copyAvatar: {
    "& .MuiAvatar-root": {
      color: "black",
      background: "transparent !important",
    },
  },
  paperContainer: {
    margin: 20,
    padding: 20,
  },
  textSnippetTitle: {
    "& .MuiInputBase-root": {
      border: "1px solid #ccc",
      borderRadius: 0,
      color: "black !important",
    },
  },
});

const MyTemplatesPage: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const [selectedTemplateToShow, setSelectedTemplateToShow] = React.useState(
    -1
  );
  // TEMPLATES Library
  // made it start in new snippet mode instead of templates mode and removed
  //
  // <Close
  //   style={{ marginRight: 10 }}
  //   onClick={() => setNewSnippetMode(false)}
  // />
  const [newSnippetMode, setNewSnippetMode] = React.useState(true);
  const classes = useStyles({ mobile });
  const [textSnippets, setTextSnippets] = useState<Snippet$DTO[]>([]);

  useEffect(() => {
    getTextSnippets()
      .then((snippetsList) => {
        setTextSnippets(snippetsList.reverse());
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }, []);

  const handleSelectedTextSnippet = (index) => {
    setSelectedTemplateToShow(index);
  };

  const handleCopyTextSnippetClick = (index) => {
    const temp = document.createElement("div");
    const textSnippet = textSnippets[index];
    const content = textSnippet.content.replace(/<\/p><p>/g, "</p><p> ");
    temp.innerHTML = content.replace(/<br>/g, " ");
  };

  const handleSaveTextSnippetClick = useCallback(
    async (updatedSnippet) => {
      if (newSnippetMode) {
        const snippet = {
          snippetName: updatedSnippet.snippetName,
          content: updatedSnippet.content,
        };
        try {
          await createTextSnippet(snippet);
          setNewSnippetMode(true);
          setSelectedTemplateToShow(-1);
          // refresh page or at least reload templates
        } catch (error) {
          console.log(error);
        }
      } else {
        const snippet = {
          snippetName: updatedSnippet.snippetName,
          content: updatedSnippet.content,
        };
        const textSnippetId = updatedSnippet.id;
        try {
          await updateTextSnippet(textSnippetId, snippet);
          // refresh page or at least reload templates
        } catch (error) {
          console.log(error);
        }
      }
      getTextSnippets()
        .then((snippetsList) => {
          setTextSnippets(snippetsList.reverse());
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    },
    [newSnippetMode]
  );

  const handleDeleteTextSnippetClick = useCallback(async (textSnippetId) => {
    try {
      deleteTextSnippet(textSnippetId.toString());
      getTextSnippets()
        .then((snippetsList) => {
          setTextSnippets(snippetsList.reverse());
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
      setSelectedTemplateToShow(-1);
      setNewSnippetMode(true);
      // refresh page or at least reload templates
    } catch (error) {
      console.log(error);
    }
  }, []);

  const createNewSnippet = () => {
    console.log("trying to create a new text snippet");
    setSelectedTemplateToShow(-1);
    setNewSnippetMode(true);
  };

  return (
    <>
      {mobile ? (
        <>Mobile version just sidebar</>
      ) : (
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={3} className={classes.templatesList}>
              <div className={classes.header}>
                <Typography variant="h5" component="h2">
                  My Templates
                </Typography>
                <AddCircleOutline onClick={createNewSnippet} />
              </div>

              <List className={classes.templateItem}>
                {textSnippets &&
                  textSnippets.map((snippet, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <ListItem
                      key={index}
                      className={
                        index === selectedTemplateToShow
                          ? classes.selectedItem
                          : ""
                      }
                      onClick={() => handleSelectedTextSnippet(index)}
                    >
                      <ListItemAvatar className={classes.copyAvatar}>
                        <Avatar
                          onClick={() => handleCopyTextSnippetClick(index)}
                        >
                          <FilterNone />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={snippet.snippetName}
                        secondary={snippet.content.replace(/<[^>]+>/g, " ")}
                      />
                    </ListItem>
                  ))}
              </List>

              {textSnippets.length === 0 && (
                <Card
                  className={classes.root}
                  variant="outlined"
                  style={{ margin: "25px 10px", textAlign: "center" }}
                >
                  <CardContent>
                    <div style={{ width: "100%" }}>
                      <Subject
                        color="disabled"
                        fontSize="large"
                        style={{
                          verticalAlign: "bottom",
                          marginRight: 5,
                          color: "black",
                          fontSize: 60,
                        }}
                      />
                    </div>
                    <Typography variant="h4">
                      Communicate faster with templates!
                    </Typography>
                    <Typography color="textSecondary" style={{ marginTop: 10 }}>
                      Instantly insert phrases, paragraphs, or whole emails.
                      Type less, do more!
                    </Typography>
                  </CardContent>
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() => setNewSnippetMode(true)}
                    >
                      Create your first snippet now
                    </Button>
                  </CardActions>
                </Card>
              )}
            </Grid>
            <Grid item xs={9}>
              <div className={classes.header}>
                {selectedTemplateToShow === -1 ? (
                  <>
                    {newSnippetMode ? (
                      <div
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <Typography variant="h5" component="h2">
                          Create a New Template
                        </Typography>
                      </div>
                    ) : (
                      <Typography variant="h5" component="h2">
                        Templates Library
                      </Typography>
                    )}
                  </>
                ) : (
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <Close
                      style={{ marginRight: 10 }}
                      onClick={() => handleSelectedTextSnippet(-1)}
                    />
                    <Typography variant="h5" component="h2">
                      {textSnippets[selectedTemplateToShow].snippetName}
                    </Typography>
                  </div>
                )}
              </div>
              {textSnippets.length > 0 && selectedTemplateToShow !== -1 ? (
                <TextSnippetEditCard
                  key={selectedTemplateToShow}
                  textSnippet={textSnippets[selectedTemplateToShow]}
                  onCopyClick={handleCopyTextSnippetClick}
                  onSaveClick={handleSaveTextSnippetClick}
                  onDeleteClick={handleDeleteTextSnippetClick}
                  index={selectedTemplateToShow}
                />
              ) : (
                <>
                  {newSnippetMode ? (
                    <TextSnippetEditCard
                      key={selectedTemplateToShow}
                      textSnippet={{ snippetName: "", content: "" }}
                      onCopyClick={handleCopyTextSnippetClick}
                      onSaveClick={handleSaveTextSnippetClick}
                      onDeleteClick={handleDeleteTextSnippetClick}
                      index={selectedTemplateToShow}
                    />
                  ) : (
                    "Show Example Snippets"
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

function quillConfigFactory() {
  return {
    modules: {
      toolbar: [
        [
          { color: [] },
          "bold",
          "italic",
          "underline",
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          "link",
        ],
      ],
    },
  };
}

const TextSnippetEditCard = ({
  textSnippet,
  onCopyClick,
  onDeleteClick,
  onSaveClick,
  index,
}) => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const classes = useStyles({ mobile });

  const [snippetName, setSnippetName] = useState(textSnippet.snippetName);
  const [content, setContent] = useState(textSnippet.content);
  const { modules } = useMemo(quillConfigFactory, []);

  const handleSaveClick = () => {
    const snippet = {
      snippetName,
      content,
      id: textSnippet && textSnippet.id ? textSnippet.id : "",
    };
    if (onSaveClick) onSaveClick(snippet);
  };

  const handleDeleteClick = () => {
    if (onDeleteClick) onDeleteClick(textSnippet.id);
  };

  const handleCopyClick = () => {
    const snippet = {
      snippetName,
      content,
      id: textSnippet && textSnippet.id ? textSnippet.id : "",
    };
    if (onCopyClick) onCopyClick(snippet);
  };

  const handleSnippetNameChange = (value) => {
    setSnippetName(value);
  };

  const handleSnippetChange = (value) => {
    setContent(value);
  };

  return (
    <Paper className={classes.paperContainer}>
      <TextField
        value={snippetName}
        className={classes.textSnippetTitle}
        onChange={({ target: { value } }) => handleSnippetNameChange(value)}
        id="standard-basic"
        autoComplete="off"
        style={{ margin: "0 0 20px 0", width: "100%" }}
        placeholder="Title"
      />
      <ReactQuill
        readOnly={false}
        modules={modules}
        value={content}
        onChange={handleSnippetChange}
      />
      <div
        style={{
          textAlign: "right",
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {textSnippet.id && (
          <Button variant="outlined" onClick={handleDeleteClick}>
            Delete
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </div>
    </Paper>
  );
};

export default MyTemplatesPage;
