import React, { FC, useMemo } from "react";
import { formatShortDuration, getTimeZones } from "@trysmarty/shared";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import RoomIcon from "@material-ui/icons/Room";
import Multiselect from 'multiselect-react-dropdown';
interface IComponentProps {
  disabled: boolean;
  duration: number;
  onDurationChange: (duration: number) => void;
  timeZone: string;
  onTimeZoneChange: (timezone: string) => any;
  canChangeDuration?: boolean;
}

const myTimeZone: any = [];
const mySelectedTimeZone: any = [];

const MeetingOptions: FC<IComponentProps> = ({
  disabled,
  duration,
  onDurationChange,
  timeZone,
  onTimeZoneChange,
  canChangeDuration = true,
}: IComponentProps) => {
  const timeZones = useMemo(() => getTimeZones(), []);

  // const handleTimezoneChange = (e, value): void => {
  //   if (!onTimeZoneChange) return;
  //   onTimeZoneChange(value.value);
  // };

  const handleTimezoneOnSelect = (e, value): void => {
    const data: any = value.value;
    myTimeZone.push(data);
    onTimeZoneChange(myTimeZone);
  };

  const handleTimezoneOnRemove = (_e, value) : void => {
    const index = myTimeZone.indexOf(value.value);
    if (index > -1) {
      myTimeZone.splice(index, 1);
    }
    onTimeZoneChange(myTimeZone);
  };

  const handleDurationChange = (e): void => {
    e.preventDefault();
    if (!onDurationChange) return;
    onDurationChange(e.target.value);
  };

  const durationOptions = useMemo(() => {
    const allValues = Array.from(
      new Set([10, 15, 30, 45, 60, 90, 120, duration])
    );
    allValues.sort((a, b) => a - b);
    return allValues;
  }, [duration]);
  
  // timeZone.map(function (i) {
  //   myTimeZone.push(i);
  // });

  timeZones.map(function (i) {
    if (timeZone.includes(i.value)) {
      mySelectedTimeZone.push(i);
    }
  });

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={6} xs={12}>
       <Multiselect
          id="userTimezone"
          onSelect={handleTimezoneOnSelect}
          onRemove={handleTimezoneOnRemove}
          options={timeZones}
          displayValue="label"
          selectedValues={mySelectedTimeZone}
       />
        {/* <Autocomplete
          disabled={disabled}
          fullWidth
          id="userTimezone"
          value={
            timeZones.find((option) => option && option.value === timeZone) ||
            undefined
          }
          onChange={handleTimezoneChange}
          disableClearable
          openOnFocus
          options={timeZones}
          getOptionLabel={(option) => (option ? option.label : "")}
          renderInput={(params): any => (
            <TextField
              {...params}
              label={
                <Grid container alignItems="center">
                  <RoomIcon width={6} height={6} />
                  <Typography> Timezone </Typography>
                </Grid>
              }
              margin="normal"
            />
          )}
          renderOption={(option) => (
            <span
              key={option!.label}
              data-tz={option!.value.replaceAll("/", "_")}
            >
              {option!.label}
            </span>
          )}
        /> */}
      </Grid>
      <Grid item sm={6} xs={12}>
        {canChangeDuration ? (
          <FormControl fullWidth margin="normal">
            <InputLabel id="user-duration-label">
              <Grid container alignItems="center">
                <AccessTimeIcon
                  style={{ marginRight: 4 }}
                  width={6}
                  height={6}
                />
                <Typography>{" Duration"}</Typography>
              </Grid>
            </InputLabel>
            <Select
              id="meetingDuration"
              // disabled={canChangeDuration}
              value={duration}
              onChange={handleDurationChange}
              labelId="user-duration-label"
            >
              {durationOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {formatShortDuration(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            id="meetingDuration"
            fullWidth
            margin="normal"
            disabled
            label={
              <Grid container alignItems="center">
                <AccessTimeIcon
                  style={{ marginRight: 4 }}
                  width={6}
                  height={6}
                />
                <Typography>{" Duration"}</Typography>
              </Grid>
            }
            value={formatShortDuration(duration)}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MeetingOptions;
