import React, { FC, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../../UserContext";
import FeaturesCarousel from "../signin/FeaturesCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    gridRowGap: theme.spacing(2),
  },
  wrapper: {
    margin: 10,
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: "#ff0081",
    borderRadius: "5px",
    width: 120,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff3a9f",
    },
  },
  margin: {
    marginLeft: 25,
  },
  fuchsiaButton: {
    backgroundColor: "#FF339A",
    fontSize: 18,
    width: 150,
    color: "white",
    "&:hover": {
      backgroundColor: "#b2236b",
    },
    "&:active": {
      backgroundColor: "#ff5bae",
    },
  },
  buttonFont: {
    width: 150,
    fontSize: 18,
  },
}));

const MyAutomationsPage: FC = () => {
  const styles = useStyles();
  const { isAuthenticated } = useContext(UserContext);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Paper className={styles.wrapper}>
          {isAuthenticated ? (
            <div>
              Show upcoming tasks upcoming appointments easy way to get
              scheduling links
            </div>
          ) : (
            <FeaturesCarousel />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default MyAutomationsPage;
