import "typeface-roboto";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const SmartyThemeInternal = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
    button: {
      textTransform: "none",
    },
    h3: {
      margin: "16px auto",
    },
  },
  palette: {
    primary: {
      main: "#4ABAF9",
    },
    secondary: {
      main: "#FF0081",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.1,
  },
  spacing: 8,
  overrides: {
    MuiSwitch: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#4ABAF9",
        },
        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#4ABAF9",
        },
      },
      colorSecondary: {
        "&.Mui-checked": {
          color: "#FF0081",
        },
        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#FF0081",
        },
      },
    },
  },
});

export const SmartyTheme = responsiveFontSizes(SmartyThemeInternal);

export default SmartyTheme;
