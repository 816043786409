export function roundTo(
  n: number,
  round: number,
  type: "ceil" | "floor"
): number {
  return Math[type](n / round) * round;
}

export const nearestNumber = (number: number, step: number): number =>
  Math.round(number / step) * step;
