import React, { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { CalendarSelector, GuestsSelector } from "@trysmarty/components";
import draggingTutorial from "../../../assets/draggingAvailabilityTutorial.gif";
import DraggingHelpPopover from "./DragPopover";

import {
  buildGroupPollUrl,
  getCalendarInfo,
  buildLink,
  getContacts,
  getMeetingLinks,
  getGroupPolls,
  deleteMeetingLink,
  deleteGroupPoll,
} from "../../../services/temporary";

import LocationSelect from "./Location";

import {
  useMediaQuery,
  Tab,
  Tabs,
  Divider,
  Grid,
  Switch,
  Box,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import Select from "react-select";
import {
  Group,
  Link,
  CalendarViewDay,
  EventNote,
  Schedule,
  DateRange,
  LocationOn,
  GroupAdd,
  MoreVert,
  Edit,
  OpenInNew,
  Delete,
  FilterNone,
} from "@material-ui/icons";
import {
  Calendar$DTO,
  Contact$DTO,
  IInterval,
  TimePeriod,
  MeetingLink$DTO,
} from "@trysmarty/shared";

const timePeriodOptions = [
  { value: TimePeriod.INDEFINITELY, label: "Whenever" },
  { value: TimePeriod.ROLLING, label: "For the next" },
  { value: TimePeriod.RANGE, label: "Between two dates" },
];

const recurringOptions = [
  { value: "dates", label: "Specific dates" },
  { value: "weekdays", label: "Days of the week" },
];

const existingLinkOptions = [
  { value: "events", label: "My Event Links" },
  { value: "polls", label: "My Group Polls" },
  { value: "onetime", label: "My One Time Links" },
];

const rollingTimeUnitOptions = [
  { value: "days", label: "days" },
  { value: "months", label: "months" },
];

const cardStyles = makeStyles({
  root: {
    width: 275,
    margin: "10px auto",

    "& .MuiCardHeader-root": {
      padding: "8px 16px 0",
    },

    "& .MuiCardContent-root": {
      padding: "0 16px",
    },

    "& .MuiCardHeader-title": {
      fontSize: 16,
      cursor: "pointer",
    },

    "& .MuiButton-label": {
      color: "#3f51b5",
    },
    "& .links-email-btn": {
      width: "30px",
      height: "20px",
      verticalAlign: "middle",
      cursor: "pointer",
      borderRadius: "2px",
      marginTop: "2px",
      color: "#3f51b5",
    },
    "& .links-email-btn:hover": {
      backgroundColor: "#f2f2f2",
    },
    "& .MuiCardActions-root .MuiButton-label": {
      justifyContent: "left",
      marginLeft: "10px",
    },
  },
  pos: {
    marginBottom: 5,
    fontSize: 12,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "block",
  },
  listItemCard: {
    "& .MuiAvatar-root:hover": {
      backgroundColor: "#e2e2e2 !important",
    },
    "& .MuiListItemText-root": {
      margin: "0 !important",
    },
    "& .MuiListItem-root": {
      margin: "0 !important",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  shareAvailabilityContainer: {
    height: "100%",
    position: "relative",
    "& .Mui-selected": {
      color: "#ff0d87",
    },
  },
  meetingDetailsContainer: {
    height: "calc(100% - 150px)",
    overflow: "auto",
    overflowX: "hidden",
  },
  meetingDetailsTitle: {
    width: "100%",
    "& .MuiInputBase-root": {
      backgroundColor: "white",
    },
  },
  freeTimesBox: {
    textAlign: "left",
    overflow: "auto",
    padding: "10px",
    backgroundColor: "white",
    border: "1px solid #e4dff2",
    margin: "20px 5px",
    borderRadius: "5px",
    fontSize: "12px",
    width: "90%",
    marginLeft: 10,
    boxShadow: "0 1px 7px 0 rgb(0 0 0 / 16%)",
    cursor: "pointer",
    pointerEvents: "none",
  },
  eventTypesTabs: {
    "& .MuiTab-root": {
      minWidth: "60px !important",
    },
  },
  header: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 16,
    padding: "0 10px 10px",
  },
  subheader: {
    lineHeight: "38px",
    fontSize: "16px",
    color: "#848482",
    textAlign: "left",
  },
  description: {
    color: "#848482",
    textAlign: "left",
  },
  meetingHeader: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "10px 0 5px",
    textAlign: "left",
  },
  linksList: {
    height: "calc(100% - 250px)",
    overflowY: "auto",
  },
  selected: {
    paddingLeft: 14,
    paddingRight: 14,
    width: "auto",
    borderRadius: "4px",
    backgroundColor: "black",
    border: "none",
    color: "white",
  },
  notSelected: {
    paddingLeft: 14,
    paddingRight: 14,
    width: "auto",
    borderRadius: "4px",
    backgroundColor: "white",
    border: "solid 1px",
  },
  selectDaysMonths: {
    minWidth: 150,
    marginLeft: 10,
    minHeight: 44,
  },
  dropdownStyle: {
    borderRadius: 4,
    border: "solid 1px #cccccc",
  },
  btnIcon: {
    background: "none",
    border: "none",
    cursor: "pointer",
    "& :hover": {
      color: "rgb(245, 0, 87)",
    },
  },
  duration: {
    "& > label": {
      fontWeight: 400,
      color: "black",
      display: "block",
      marginBottom: "4px",
    },
    icon: {
      marginRight: "8px",
      color: "blue",
    },
  },
  buttonGroup: {
    display: "flex",
  },
  item: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    color: "black",
    width: "43px",
    padding: "1px",
  },
  itemActive: {
    border: "2px solid blue",
    padding: "1px",
    color: "blue",
    width: "43px",
    textAlign: "center",
    "& input": {
      color: "blue",
      border: "0px",
      backgroundColor: "transparent",
      width: "100%",
      textAlign: "center",
    },
  },
  calEventDateTime: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiFormControl-root, .MuiInput-formControl": {
      border: 0,
      fontSize: 14,
      padding: 0,
      margin: "0px !important",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    "& .MuiInputBase-root": {
      margin: "0 !important",
      border: "0",
    },
  },
}));

interface CalendarItem {
  name: string;
  id: string;
  color: string;
  primary?: boolean;
  accessRole: string;
}

interface IComponentProps {
  // slot: IInterval<Date> | undefined;
  slots: IInterval<Date>[];
  timeZone: string;
  calendarList: Calendar$DTO[];
  startDateString: string;
  endDateString: string;
  setUseWorkingHoursSlotsParent: (boolean) => void;
  setStartWHDate: (string) => void;
  setEndWHDate: (string) => void;
  useWorkingHours: boolean;
  handleTabChange: (string) => void;
  // workingHoursTimesToShow: string;
  // plainWorkingHoursTimesToShow: string;
  // timeSlotsToShow: string;
  // plainTimeSlotsToShow: string;
}

export const SlotsMode: FC<IComponentProps> = ({
  slots,
  timeZone,
  calendarList,
  startDateString,
  endDateString,
  setUseWorkingHoursSlotsParent,
  setStartWHDate,
  setEndWHDate,
  useWorkingHours,
  handleTabChange,
}: IComponentProps) => {
  const [isUsingWorkingHours, setUsingWorkingHours] = useState(useWorkingHours);
  const mobile = useMediaQuery("(max-width: 650px)");
  const [existingMeetingLinkId, setMeetingLinkId] = useState("");
  const [duration, setDuration] = useState(60);
  const classes = useStyles({ mobile });
  const [availabilityType, setAvailabilityType] = React.useState("ranges");
  const [location, setLocation] = useState("");
  const handleLocationChange = (value) => {
    setLocation(value);
  };

  const [meetingLinks, setMeetingLinks] = useState<MeetingLink$DTO[]>([]);
  const [oneTimeLinks, setOneTimeLinks] = useState<MeetingLink$DTO[]>([]);
  const [groupPolls, setGroupPolls] = useState<MeetingLink$DTO[]>([]);

  useEffect(() => {
    getMeetingLinks()
      .then((meetingLinks) => {
        let meetingLinksReversed: MeetingLink$DTO[] = [];
        meetingLinksReversed = meetingLinks.reverse();
        setMeetingLinks(meetingLinksReversed.filter((ml) => !ml.oneTimeLink));
        setOneTimeLinks(meetingLinksReversed.filter((ml) => ml.oneTimeLink));
      })
      .catch(() => {
        // todo handle getMeetingLinks error, message?
      })
      .finally(() => {});
    getGroupPolls()
      .then((groupPolls) => {
        const groupPollsReversed = groupPolls.reverse();
        const activeGroupPolls = groupPollsReversed.filter(
          (gp) => gp.state !== "ARCHIVED"
        );
        setGroupPolls(activeGroupPolls);
      })
      .catch(() => {
        // todo handle getMeetingLinks error, message?
      })
      .finally(() => {});
  }, []);

  const [isRecurring, setRecurring] = useState(false);
  const [oneTimeLink, setOneTimeLink] = useState(false);
  const [timePeriod, setTimePeriod] = useState(timePeriodOptions[0]);
  const [recurringOption, setRecurringOption] = useState(recurringOptions[0]);
  const [existingLinkOption, setExistingLinkOption] = useState(
    existingLinkOptions[0]
  );

  const [rollingTimeAmount, setRollingTimeAmount] = useState(60);
  const [rollingTimeUnit, setRollingTimeUnit] = useState(
    rollingTimeUnitOptions[0]
  );
  const [embedLink, setEmbedLink] = useState(true);
  const [weeklyRecurringSlots, setWeeklyRecurringSlots] = useState([]);
  const [linkTitleValue, setLinkTitleValue] = React.useState(
    "Select a time on my calendar"
  );

  const [workingHoursTimesToShow, setWorkingHoursTimesToShow] = useState("");
  const [
    plainWorkingHoursTimesToShow,
    setPlainWorkingHoursTimesToShow,
  ] = useState("");

  const handleDurationChange = (value) => {
    setDuration(value);
  };
  const [plainTimeSlotsToShow, setPlainTimeSlotsToShow] = useState("");
  const [timeSlotsToShow, setTimeSlotsToShow] = useState("");

  const [validContacts, setValidContacts] = useState<any[]>([]);
  const [invalidContacts, setInvalidContacts] = useState<any[]>([]);

  const [contacts, setContacts] = useState<Contact$DTO[]>([]);

  const handleContactsSearch = useCallback(async (value: string): Promise<
    Contact$DTO[]
  > => {
    const otherContacts = await getContacts(value);
    return otherContacts.map((contact) => ({
      name: contact.name,
      email: contact.email,
      img: contact.img,
    }));
  }, []);

  const handleContactsChange = useCallback(
    (value) => {
      if (value.length < contacts.length) {
        // then there have been guests that were deleted
        // figure out who they are.
        // find guests that are in guests.current but not in value
        const deletedGuests = contacts.filter(
          (obj) => !value.some((obj2) => obj.email === obj2.email)
        );
        for (let i = 0; i < deletedGuests.length; i++) {
          const deletedGuest = deletedGuests[i];
          const deletedGuestEmail = deletedGuest.email;
          if (validContacts.includes(deletedGuest)) {
            const newValidGuests = validContacts.filter(
              (el) => el.email !== deletedGuestEmail
            );
            setValidContacts(newValidGuests);
          } else {
            const newInvalidGuests = invalidContacts.filter(
              (el) => el.value !== deletedGuestEmail
            );
            setInvalidContacts(newInvalidGuests);
          }
        }
      } else {
        // Find guests that are in value but not in guests.current
        const uniqueNewGuests = value.filter((obj) =>
          contacts.some((obj2) => obj.email === obj2.email)
        );

        for (let i = 0; i < uniqueNewGuests.length; i++) {
          const newGuest = uniqueNewGuests[i];
          const newGuestEmail = newGuest.email;
          // eslint-disable-next-line no-await-in-loop
          getCalendarInfo({ calendarId: newGuestEmail }).then((res) => {
            if (res) {
              const newValidGuests = [...validContacts];
              if (!newValidGuests.includes(newGuest)) {
                newValidGuests.push(newGuest);
                setValidContacts(newValidGuests);
              }
            } else {
              const newInvalidGuests = [...invalidContacts];
              if (!newInvalidGuests.includes(newGuest)) {
                newInvalidGuests.push(newGuest);
                setInvalidContacts(newInvalidGuests);
              }
            }
          });
        }
      }
      setContacts(value);
    },
    [contacts, invalidContacts, validContacts]
  );

  // const slotsAreSelected =
  //   (isUsingWorkingHours && workingHourSlots.length > 0) ||
  //   (!isRecurring && slots && slots.length > 0) ||
  //   (isRecurring && weeklyRecurringSlots.length > 0);

  const showMeetingDetailsSection =
    availabilityType === "events" ||
    (slots.length > 0 && embedLink) ||
    (isUsingWorkingHours && embedLink);

  const showIncludeLinkOption =
    (slots.length > 0 && !isRecurring) || isUsingWorkingHours;

  const showCalendarChooserSection = isUsingWorkingHours
    ? !embedLink
    : availabilityType !== "events" && !isRecurring && slots.length === 0;

  const handleSetRecurringClick = (isRecurring) => {
    setRecurring(isRecurring);
    if (isRecurring) {
      setRecurringOption(recurringOptions[1]);
    } else {
      setRecurringOption(recurringOptions[0]);
    }
  };

  const handleMeetingLinkTitleChange = (event) => {
    setLinkTitleValue(event.target.value);
  };

  const handleTimePeriodChange = useCallback((selectedTimePeriod) => {
    setTimePeriod(selectedTimePeriod);
  }, []);

  const handleExistingLinkSelectChange = useCallback(
    (selectedExistingLinkOption) => {
      setExistingLinkOption(selectedExistingLinkOption);
    },
    []
  );

  const handleRecurringChange = useCallback(
    (selectedRecurringOption) => {
      if (selectedRecurringOption.value === "weekdays") {
        handleSetRecurringClick(true);
        handleTabChange("polls_days");
      } else {
        handleSetRecurringClick(false);
        handleTabChange("polls");
      }
    },
    [handleTabChange]
  );

  const handleRollingTimeAmountChange = (rollingTimeAmount) => {
    setRollingTimeAmount(rollingTimeAmount);
  };

  const handleRollingTimeUnitChange = (rollingTimeUnit) => {
    setRollingTimeUnit(rollingTimeUnit);
  };

  const handleAvailabilityTypeChange = (event, newValue) => {
    handleTabChange(newValue);
    setAvailabilityType(newValue);
    if (newValue === "events") {
      handleSetRecurringClick(true);
      setOneTimeLink(false);
      setEmbedLink(true);
      setUsingWorkingHours(false);
    } else if (newValue === "ranges") {
      handleSetRecurringClick(false);
      setOneTimeLink(true);
    } else {
      handleSetRecurringClick(false);
      setOneTimeLink(false);
      setEmbedLink(true);
      setUsingWorkingHours(false);
    }
  };

  const formatFreeTimeEntry = (day, times) => {
    if (times.length > 0) {
      return `<b>${day}</b>:<br/>- <a href="URLTOFILLIN">${times.join(
        '</a><br/>- <a href="URLTOFILLIN">'
      )}</a><br/>`;
    }
    return "";
  };

  const formatPlainFreeTimeEntry = (day, times) => {
    if (times.length > 0) {
      return `${day}:\n - ${times.join("\n - ")}\n`;
    }
    return "";
  };

  const formatFreeTimes = useCallback(
    (freeTimeIntervals) => {
      const groupedByDate = new Map();
      groupedByDate.set("Sunday", []);
      groupedByDate.set("Monday", []);
      groupedByDate.set("Tuesday", []);
      groupedByDate.set("Wednesday", []);
      groupedByDate.set("Thursday", []);
      groupedByDate.set("Friday", []);
      groupedByDate.set("Saturday", []);
      if (isRecurring) {
        freeTimeIntervals.sort((a, b) => a.startMinutes - b.startMinutes);
      } else {
        freeTimeIntervals.sort((a, b) => a.start - b.start);
      }

      freeTimeIntervals.forEach((freeTime) => {
        let day, start, end;
        if (isRecurring) {
          day = moment(freeTime.dayOfWeek, "e").format("dddd");
          start = moment()
            .startOf("day")
            .add(1, "day")
            .add(freeTime.startMinutes, "minutes")
            .format("LT");
          end = moment()
            .startOf("day")
            .add(1, "day")
            .add(freeTime.endMinutes, "minutes")
            .format("LT");
        } else {
          day = moment(freeTime.start).format("ddd, MMM D");
          start = moment(freeTime.start).format("LT");
          end = moment(freeTime.end).format("LT");
        }

        if (!groupedByDate.has(day)) {
          groupedByDate.set(day, []);
        }

        groupedByDate.get(day).push(`${start} - ${end}`);
      }, []);

      return groupedByDate;
    },
    [isRecurring]
  );

  const formatFreeTimeEntryRecurring = (day, times) => {
    if (times.length > 0) {
      return `${day}s:\n - ${times.join("\n - ")}\n`;
    }
    return "";
  };

  const parseIntervals = useCallback(
    (freeTimeIntervals) => {
      let allFreeTimes, allPlainFreeTimes;
      if (isRecurring) {
        allFreeTimes = Array.from(
          formatFreeTimes(freeTimeIntervals),
          ([day, events]) => formatFreeTimeEntryRecurring(day, events)
        ).join("\n");
        allPlainFreeTimes = Array.from(
          formatFreeTimes(freeTimeIntervals),
          ([day, events]) => formatFreeTimeEntryRecurring(day, events)
        ).join("\n");
        setPlainTimeSlotsToShow(allPlainFreeTimes);
        setTimeSlotsToShow(allFreeTimes);
      } else {
        allFreeTimes = Array.from(
          formatFreeTimes(freeTimeIntervals),
          ([day, events]) => formatFreeTimeEntry(day, events)
        ).join("\n");
        allPlainFreeTimes = Array.from(
          formatFreeTimes(freeTimeIntervals),
          ([day, events]) => formatPlainFreeTimeEntry(day, events)
        ).join("\n");
        setPlainTimeSlotsToShow(allPlainFreeTimes);
        setTimeSlotsToShow(allFreeTimes);
      }
      return {
        textLinkSlots: allFreeTimes,
        plainTextSlots: allPlainFreeTimes,
      };
    },
    [formatFreeTimes, isRecurring]
  );

  const parseIntervalsWorkingHours = useCallback(
    (freeTimeIntervals) => {
      const allFreeTimes = Array.from(
        formatFreeTimes(freeTimeIntervals),
        ([day, events]) => formatFreeTimeEntry(day, events)
      ).join("\n");
      const allPlainFreeTimes = Array.from(
        formatFreeTimes(freeTimeIntervals),
        ([day, events]) => formatPlainFreeTimeEntry(day, events)
      ).join("\n");
      setPlainWorkingHoursTimesToShow(allPlainFreeTimes);
      setWorkingHoursTimesToShow(allFreeTimes);
      return {
        textLinkSlots: allFreeTimes,
        plainTextSlots: allPlainFreeTimes,
      };
    },
    [formatFreeTimes]
  );

  useEffect(() => {
    if (isRecurring) {
      parseIntervals(slots);
    } else {
      parseIntervals(slots);
    }
  }, [isRecurring, parseIntervals, slots, weeklyRecurringSlots]);

  const handleCalendarsChange = () => {
    console.log("calendars selected changed");
  };

  const handleCreateCopyLink = useCallback(async () => {
    // if (embedLink) {
    //   try {
    //     if (timePeriod.value === TimePeriod.RANGE) {
    //       rangePeriod[0].getTime();
    //       rangePeriod[1].getTime();
    //     }
    //   } catch (error) {
    //     return;
    //   }
    //   let slotsToUse;
    //   if (!isRecurring) {
    //     if (isUsingWorkingHours) {
    //       slotsToUse = workingHourSlots.map((slot) => ({
    //         ...slot,
    //         start: slot.start.getTime(),
    //         end: slot.end.getTime(),
    //       }));
    //     } else {
    //       slotsToUse = slots.map((slot) => ({
    //         ...slot,
    //         start: slot.start.getTime(),
    //         end: slot.end.getTime(),
    //       }));
    //     }
    //   } else {
    //     slotsToUse = null;
    //   }
    //   const meetingLink = {
    //     customIntervals: slotsToUse,
    //     customRangeEnd:
    //       timePeriod.value === TimePeriod.RANGE
    //         ? rangePeriod[1].getTime()
    //         : null,
    //     customRangeStart:
    //       timePeriod.value === TimePeriod.RANGE
    //         ? rangePeriod[0].getTime()
    //         : null,
    //     customRecurring: isRecurring,
    //     customRollingTimeAmount: rollingTimeAmount,
    //     customRollingTimeUnit: rollingTimeUnit.value,
    //     customTimePeriod: timePeriod.value,
    //     customWeeklyIntervals: isRecurring ? weeklyRecurringSlots : null,
    //     duration,
    //     eventName: linkTitleValue,
    //     contacts,
    //     validContacts,
    //     invalidContacts,
    //     oneTimeLink,
    //     calendars,
    //     timePeriod: TimePeriod.CUSTOM,
    //     timeZone,
    //   };
    //   if (existingMeetingLinkId) {
    //     updateMeetingLink(existingMeetingLinkId, meetingLink).catch(
    //       console.error
    //     );
    //   } else {
    //     if (oneTimeLink) {
    //       if (isUsingWorkingHours) {
    //         const meetingLinkMade = await createMeetingLink(meetingLink);
    //         iframeController.notifyNewOneTimeLink(meetingLinkMade);
    //         const newURL = buildMeetingLinkUrl(meetingLinkMade);
    //         trackEvent(
    //           "use working hours - meeting link popup - copy times text"
    //         );
    //         let {
    //           // eslint-disable-next-line prefer-const
    //           textLinkSlots,
    //           plainTextSlots,
    //         } = parseIntervalsWorkingHours(workingHourSlots, newURL);
    //         const textLinkSlotsWithUrl = textLinkSlots.replaceAll(
    //           'href="URLTOFILLIN"',
    //           `href="${newURL}"`
    //         );
    //         plainTextSlots = `${plainTextSlots}\nIf it's easier, you can book here: \n${newURL}`;
    //         iframeController.copyToClipboard(
    //           textLinkSlotsWithUrl,
    //           plainTextSlots
    //         );
    //       } else {
    //         const meetingLinkMade = await createMeetingLink(meetingLink);
    //         iframeController.notifyNewOneTimeLink(meetingLinkMade);
    //         const newURL = buildMeetingLinkUrl(meetingLinkMade);
    //         trackEvent("meeting link popup - copy times text");
    //         // eslint-disable-next-line prefer-const
    //         let { textLinkSlots, plainTextSlots } = parseIntervals(
    //           slots,
    //           newURL
    //         );
    //         const textLinkSlotsWithUrl = textLinkSlots.replaceAll(
    //           'href="URLTOFILLIN"',
    //           `href="${newURL}"`
    //         );
    //         plainTextSlots = `${plainTextSlots}\nIf it's easier, you can book here: \n${newURL}`;
    //         iframeController.copyToClipboard(
    //           textLinkSlotsWithUrl,
    //           plainTextSlots
    //         );
    //       }
    //     } else {
    //       createMeetingLink(meetingLink)
    //         .then((res) => {
    //           iframeController.notifyNewEvent(res);
    //           const url = buildMeetingLinkUrl(res);
    //           const link = buildLink(url, linkTitleValue);
    //           iframeController.copyToClipboard(link, url);
    //         })
    //         .catch(console.error);
    //     }
    //     showToast(
    //       "Copied to clipboard",
    //       "",
    //       "You can now paste into an email."
    //     );
    //   }
    // } else {
    //   if (isUsingWorkingHours) {
    //     iframeController.copyToClipboard(
    //       plainWorkingHoursTimesToShow,
    //       plainWorkingHoursTimesToShow
    //     );
    //   } else {
    //     iframeController.copyToClipboard(
    //       plainTimeSlotsToShow,
    //       plainTimeSlotsToShow
    //     );
    //   }
    //   showToast("Copied to clipboard", "", "You can now paste into an email.");
    // }
    // setTimeout(() => {
    //   iframeController.closeMeetingLinksPopup();
    // }, 500);
  }, []);

  const handleCopyShareableGroupLinkClick = useCallback(() => {
    // // IGroupPollDocument
    // const groupPoll = {
    //   opening: slots.reduce(
    //     (min, { start }) =>
    //       !min ? start : min.getTime() < start.getTime() ? min : start,
    //     null
    //   ),
    //   expiration: slots.reduce(
    //     (max, { end }) =>
    //       !max ? end : max.getTime() > end.getTime() ? max : end,
    //     null
    //   ),
    //   eventName: linkTitleValue,
    //   location: "",
    //   duration,
    //   slug: undefined,
    //   timeZone,
    //   recurring: isRecurring || false,
    //   slots: !isRecurring
    //     ? slots.map((slot) => ({
    //         ...slot,
    //         start: slot.start.getTime(),
    //         end: slot.end.getTime(),
    //       }))
    //     : null,
    //   recurringSlots: isRecurring ? weeklyRecurringSlots : null,
    // };
    // createGroupPoll(groupPoll)
    //   .then((res) => {
    //     iframeController.notifyNewGroupPoll(res);
    //     const url = buildGroupPollUrl(res);
    //     const link = buildLink(url, linkTitleValue);
    //     iframeController.copyToClipboard(link, url);
    //   })
    //   .catch(console.error);
    // showToast("Copied to clipboard", "", "You can now paste into an email.");
    // setTimeout(() => {
    //   iframeController.closeMeetingLinksPopup();
    // }, 500);
  }, []);

  const handleCopyLink = (type) => {
    // if (type === "polls") {
    //   handleCopyShareableGroupLinkClick();
    // } else {
    //   handleCreateCopyLink(type);
    // }
  };

  const handleQuickCreateLinkClick = useCallback(() => {
    console.log("create a schedulign link now");
  }, []);

  const handleCopyMeetingLinkClick = useCallback((meetingLink) => {
    console.log("copy meeting link ", meetingLink);
  }, []);

  const handleCopyGroupPollLinkClick = useCallback((meetingLink) => {
    const url = buildGroupPollUrl(meetingLink);
    const link = buildLink(url, meetingLink.eventName);
    // iframeController.copyToClipboard(link, url);
    // iframeController.insertHTML(link);
    // setShortcutsEnabled(false);
    // setIsToastVisible(true);
  }, []);

  const handleOpenGroupPollLinkClick = useCallback((meetingLink) => {
    console.log("handleOpenGroupPollLinkClick");
  }, []);

  const handleOpenMeetingLinkClick = useCallback((meetingLink) => {
    console.log("handleOpenMeetingLinkClick");
  }, []);

  const handleDeleteMeetingLinkClick = (meetingLink) => {
    deleteMeetingLink(meetingLink.id).finally(() => {
      setMeetingLinks((prev) =>
        [...prev].filter((ml) => ml.id !== meetingLink.id)
      );
      setOneTimeLinks((prev) =>
        [...prev].filter((ml) => ml.id !== meetingLink.id)
      );
    });
  };

  const handleDeleteGroupPollLinkClick = useCallback(
    (meetingLink) => {
      deleteGroupPoll(meetingLink.id).finally(() => {
        const newGroupPolls = [...groupPolls];
        newGroupPolls.splice(newGroupPolls.indexOf(meetingLink), 1);
        setGroupPolls(newGroupPolls);
      });
    },
    [groupPolls]
  );

  return (
    <div className={classes.shareAvailabilityContainer}>
      <Tabs
        value={availabilityType}
        onChange={handleAvailabilityTypeChange}
        aria-label="scrollable force tabs example"
        variant="fullWidth"
        className={classes.eventTypesTabs}
      >
        <Tab
          value="ranges"
          label={
            <div style={{ width: 60, textTransform: "capitalize" }}>
              Time Slots
            </div>
          }
          icon={<CalendarViewDay />}
        />

        <Tab
          value="events"
          label={
            <div style={{ width: 60, textTransform: "capitalize" }}>
              Event Type
            </div>
          }
          icon={<EventNote />}
        />

        <Tab
          value="polls"
          label={
            <div style={{ width: 60, textTransform: "capitalize" }}>
              Group Poll
            </div>
          }
          icon={<Group />}
        />
        <Tab
          value="existing"
          label={
            <div style={{ width: 60, textTransform: "capitalize" }}>
              Existing Links
            </div>
          }
          icon={<Link />}
        />
      </Tabs>
      <Divider />
      {availabilityType !== "existing" && (
        <div className={classes.meetingDetailsContainer}>
          {availabilityType === "ranges" && (
            <div style={{ textAlign: "left", color: "#a1a1a1" }}>
              <Grid container>
                <Grid
                  item
                  xs={9}
                  style={{
                    lineHeight: "38px",
                    padding: "0 10px",
                    fontSize: 16,
                  }}
                >
                  <span className={classes.description}>
                    Use your working hours
                  </span>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <Switch
                    checked={isUsingWorkingHours}
                    onChange={() => {
                      setUseWorkingHoursSlotsParent(!isUsingWorkingHours);
                      setUsingWorkingHours(!isUsingWorkingHours);
                    }}
                    color="secondary"
                    inputProps={{ "aria-label": "embed link checkbox" }}
                  />
                </Grid>
              </Grid>

              {!isUsingWorkingHours && slots.length === 0 && (
                <div>
                  <div style={{ textAlign: "left", padding: 10, fontSize: 16 }}>
                    Try dragging to get started on sharing your availability.
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img
                      src={draggingTutorial}
                      style={{ maxWidth: "200px", margin: "auto" }}
                    />
                  </div>
                </div>
              )}
              {showIncludeLinkOption && (
                <div style={{ textAlign: "left", color: "#a1a1a1" }}>
                  <Grid container>
                    <Grid
                      item
                      xs={9}
                      style={{
                        lineHeight: "38px",
                        padding: "0 10px",
                        fontSize: 16,
                      }}
                    >
                      <span className={classes.description}>
                        Include scheduling link
                      </span>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "center" }}>
                      <Switch
                        checked={embedLink}
                        onChange={() => setEmbedLink(!embedLink)}
                        color="secondary"
                        inputProps={{ "aria-label": "embed link checkbox" }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
              {isUsingWorkingHours && (
                <>
                  <div>
                    <div className="dropdown">
                      <div style={{ margin: "5px 10px" }}>From</div>
                      <TextField
                        id="date"
                        type="date"
                        value={startDateString}
                        className={classes.textField}
                        onChange={(event) => setStartWHDate(event.target.value)}
                      />
                      <div style={{ margin: "5px 10px" }}>to</div>
                      <TextField
                        id="date"
                        type="date"
                        value={endDateString}
                        className={classes.textField}
                        onChange={(event) => setEndWHDate(event.target.value)}
                      />
                    </div>
                  </div>
                  {embedLink ? (
                    <>
                      <div
                        className={classes.freeTimesBox}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: timeSlotsToShow,
                        }}
                      />
                      <div style={{ textAlign: "right", fontSize: 14 }}>
                        Show & edit my working hours here.
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={classes.freeTimesBox}>
                        {plainTimeSlotsToShow.split("\n").map((i, key) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={key}>{i}</div>
                        ))}
                      </div>
                    </>
                  )}
                  <Divider style={{ margin: "10px 0" }} />
                </>
              )}
            </div>
          )}
          {availabilityType !== "ranges" &&
            availabilityType !== "existing" &&
            slots.length === 0 && (
              <div>
                <br />
                <DraggingHelpPopover />
                <Divider style={{ margin: "10px 0" }} />
              </div>
            )}
          {availabilityType === "polls" && (
            <>
              <div style={{ marginBottom: 10 }}>
                <div className={classes.header} style={{ marginTop: 10 }}>
                  <Group
                    style={{ verticalAlign: "middle", marginRight: "10" }}
                  />
                  <div style={{ verticalAlign: "middle", display: "inline" }}>
                    The group will vote for
                  </div>
                </div>
                <div style={{ margin: 15, textAlign: "left" }}>
                  <Select
                    value={recurringOption}
                    onChange={handleRecurringChange}
                    options={recurringOptions}
                    isSearchable={false}
                    menuPlacement="bottom"
                    classNamePrefix="select"
                    // @ts-ignore
                    renderOption={(option) => (
                      <span key={option.value} data-time-period={option.value}>
                        {option.label}
                      </span>
                    )}
                  />
                </div>
              </div>
            </>
          )}
          {slots.length > 0 && !isUsingWorkingHours && (
            <>
              {embedLink && !isRecurring ? (
                <>
                  <div
                    className={classes.freeTimesBox}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: timeSlotsToShow,
                    }}
                  />
                </>
              ) : (
                <>
                  <div className={classes.freeTimesBox}>
                    {plainTimeSlotsToShow.split("\n").map((i, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={key}>{i}</div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}

          {isRecurring && slots.length > 0 && availabilityType !== "ranges" && (
            <div style={{ margin: 15, textAlign: "left" }}>
              <label>How far into the future can events be scheduled?</label>
              <Select
                value={timePeriod}
                onChange={handleTimePeriodChange}
                options={timePeriodOptions}
                isSearchable={false}
                menuPlacement="bottom"
                classNamePrefix="select"
                // @ts-ignore
                renderOption={(option) => (
                  <span key={option.value} data-time-period={option.value}>
                    {option.label}
                  </span>
                )}
              />
              {timePeriod.value === TimePeriod.ROLLING ? (
                <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                  <div className="text-field ml-1">
                    <input
                      type="number"
                      value={rollingTimeAmount}
                      onChange={({ target: { value } }) =>
                        handleRollingTimeAmountChange(value)
                      }
                      min={0}
                      max={180}
                      style={{
                        width: 80,
                        borderRadius: 4,
                        border: "solid 1px #cccccc",
                        maxHeight: 38,
                      }}
                    />
                  </div>
                  <Select
                    value={rollingTimeUnit}
                    onChange={handleRollingTimeUnitChange}
                    options={rollingTimeUnitOptions}
                    isSearchable={false}
                    menuPlacement="bottom"
                    className={classes.selectDaysMonths}
                    classNamePrefix="select"
                  />
                </div>
              ) : timePeriod.value === TimePeriod.RANGE ? (
                <div className={classes.calEventDateTime}>
                  <div style={{ margin: "5px" }}>From</div>
                  <TextField
                    id="date"
                    type="date"
                    value={startDateString}
                    className={classes.textField}
                    onChange={(event) => setStartWHDate(event.target.value)}
                  />
                  to
                  <TextField
                    id="date"
                    type="date"
                    value={endDateString}
                    className={classes.textField}
                    onChange={(event) => setEndWHDate(event.target.value)}
                  />
                </div>
              ) : null}
            </div>
          )}
          {availabilityType !== "existing" && showCalendarChooserSection && (
            <div style={{ margin: "0 10px" }}>
              <div className={classes.meetingHeader}>
                Check for conflicts from
              </div>
              {calendarList && (
                <Box width={250} style={{ margin: "0 10px" }}>
                  <CalendarSelector
                    calendarIds={calendarList.map((item) => item.id)}
                    calendarList={calendarList}
                    onCalendarIdsChange={handleCalendarsChange}
                    fixedPrimary={false}
                  />
                </Box>
              )}
            </div>
          )}
          {showMeetingDetailsSection && (
            <>
              <div
                style={{
                  padding: 10,
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: "10px 0",
                  }}
                >
                  <b>Meeting Details</b>
                </div>
                <TextField
                  id="standard-basic"
                  autoComplete="off"
                  label="Title"
                  value={linkTitleValue}
                  className={classes.meetingDetailsTitle}
                  onChange={handleMeetingLinkTitleChange}
                />
                <div className={classes.meetingHeader}>Duration</div>
                <Duration value={duration} onChange={handleDurationChange} />

                <div className={classes.meetingHeader}>
                  Conferencing/Location ***
                  <LocationSelect
                    location={location}
                    onLocationChange={handleLocationChange}
                    readOnly={false}
                  />
                </div>
                <div className={classes.meetingHeader}>Relevant Calendars</div>
                <div>
                  Check for conflicts using the following calendars:
                  <CalendarSelector
                    calendarIds={calendarList.map((item) => item.id)}
                    calendarList={calendarList}
                    onCalendarIdsChange={handleCalendarsChange}
                    fixedPrimary={false}
                  />
                </div>
                <div className={classes.meetingHeader}>Other Invitees</div>

                <Box width={250}>
                  <GuestsSelector
                    contacts={contacts}
                    onChange={handleContactsChange}
                    onSearch={handleContactsSearch}
                    readOnly={false}
                  />
                </Box>
                {invalidContacts?.length > 0 && (
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "10px",
                      width: "90%",
                      wordWrap: "break-word",
                      maxHeight: "50px",
                      overflow: "auto",
                    }}
                  >
                    Calendar availability not accessible for: <br />
                    {invalidContacts?.map((guest) => (
                      <span key={guest.email}>{guest.value},</span>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {slots.length > 0 && availabilityType === "ranges" && (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              marginTop: 10,
            }}
            onClick={() => handleCopyLink("ranges")}
          >
            Copy Slots to Clipboard
          </Button>
        </>
      )}
      {slots.length > 0 && availabilityType === "polls" && (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              position: "absolute",
              bottom: -50,
              left: 0,
            }}
            onClick={() => handleCopyLink("polls")}
            disabled={
              isRecurring
                ? !weeklyRecurringSlots || !weeklyRecurringSlots.length
                : !slots || !slots.length
            }
          >
            Copy to Clipboard
          </Button>
        </>
      )}
      {slots.length > 0 &&
        embedLink &&
        availabilityType !== "ranges" &&
        availabilityType !== "polls" && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              position: "absolute",
              bottom: -50,
              left: 0,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ width: "100%", marginTop: 25 }}
              onClick={() => handleCopyLink("events")}
            >
              {existingMeetingLinkId ? (
                <>Save Changes</>
              ) : (
                <>Copy Event Link to Clipboard</>
              )}
            </Button>
          </div>
        )}

      {availabilityType === "existing" && (
        <>
          <div style={{ marginBottom: 10 }}>
            <div style={{ margin: 15, textAlign: "left" }}>
              <Select
                value={existingLinkOption}
                onChange={handleExistingLinkSelectChange}
                options={existingLinkOptions}
                isSearchable={false}
                menuPlacement="bottom"
                classNamePrefix="select"
                // @ts-ignore
                renderOption={(option) => (
                  <span key={option.value} data-time-period={option.value}>
                    {option.label}
                  </span>
                )}
              />
            </div>
          </div>
          <>
            {existingLinkOption.value === "events" ? (
              <>
                <h1>My Event Types</h1>
                <Divider />
                {meetingLinks.length === 0 ? (
                  <>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Schedule
                            color="disabled"
                            fontSize="large"
                            style={{
                              verticalAlign: "bottom",
                              marginRight: 5,
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                          />
                        }
                        title="Schedule faster with Scheduling Links!"
                      />
                      <CardContent>
                        <Typography
                          style={{ fontSize: 15 }}
                          color="textSecondary"
                        >
                          Choose your preferred meeting times and then share
                          your link to allow others to book a meeting with you
                          instantly.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="large"
                          color="primary"
                          onClick={handleQuickCreateLinkClick}
                        >
                          Create your first scheduling link now
                        </Button>
                      </CardActions>
                    </Card>
                  </>
                ) : (
                  <List dense className={classes.linksList}>
                    {meetingLinks &&
                      meetingLinks.map((meetingLink, index) => (
                        <SchedulingLinkCard
                          key={meetingLink.id}
                          meetingLink={meetingLink}
                          onCopyClick={handleCopyMeetingLinkClick}
                          onOpenClick={handleOpenMeetingLinkClick}
                          onDeleteClick={handleDeleteMeetingLinkClick}
                          index={index}
                          oneTimeLink={false}
                          groupPoll={false}
                          timeZone={timeZone}
                        />
                      ))}
                  </List>
                )}
              </>
            ) : existingLinkOption.value === "onetime" ? (
              <>
                <h1>My One Time Links</h1>
                <Divider />
                {oneTimeLinks.length === 0 ? (
                  <>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Schedule
                            color="disabled"
                            fontSize="large"
                            style={{
                              verticalAlign: "bottom",
                              marginRight: 5,
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                          />
                        }
                        title="Schedule faster with Scheduling Links!"
                      />
                      <CardContent>
                        <Typography
                          style={{ fontSize: 15 }}
                          color="textSecondary"
                        >
                          In a rush? Need a link for a ad hoc or one-off
                          meeting? <br />
                          <br />
                          Make a one time meeting link that will expire
                          automatically after the meeting scheduling period has
                          passed. <br />
                          <br />
                          One time links use your preferred meeting duration and
                          location from the settings page.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="large"
                          color="primary"
                          onClick={handleQuickCreateLinkClick}
                        >
                          Create one now
                        </Button>
                      </CardActions>
                    </Card>
                  </>
                ) : (
                  <List dense className={classes.linksList}>
                    {oneTimeLinks &&
                      oneTimeLinks.map((meetingLink, index) => (
                        <SchedulingLinkCard
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          meetingLink={meetingLink}
                          onCopyClick={handleCopyMeetingLinkClick}
                          onOpenClick={handleOpenMeetingLinkClick}
                          onDeleteClick={handleDeleteMeetingLinkClick}
                          index={index}
                          oneTimeLink
                          groupPoll={false}
                          timeZone={timeZone}
                        />
                      ))}
                  </List>
                )}
              </>
            ) : existingLinkOption.value === "polls" ? (
              <>
                <h1>My Group Polls</h1>
                <Divider />
                {groupPolls.length === 0 ? (
                  <>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Schedule
                            color="disabled"
                            fontSize="large"
                            style={{
                              verticalAlign: "bottom",
                              marginRight: 5,
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                          />
                        }
                        title="Create a group poll!"
                      />
                      <CardContent>
                        <Typography
                          style={{ fontSize: 15 }}
                          color="textSecondary"
                        >
                          Group polls are the best way to survey a group for the
                          ideal time to get together. <br />
                          Now you can easily book meetings and other events with
                          friends, colleagues and anyone else.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="large"
                          color="primary"
                          onClick={handleQuickCreateLinkClick}
                        >
                          Create one now
                        </Button>
                      </CardActions>
                    </Card>
                  </>
                ) : (
                  <List dense className={classes.linksList}>
                    {groupPolls &&
                      groupPolls.map((meetingLink, index) => (
                        <SchedulingLinkCard
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          meetingLink={meetingLink}
                          onCopyClick={handleCopyGroupPollLinkClick}
                          onOpenClick={handleOpenGroupPollLinkClick}
                          onDeleteClick={handleDeleteGroupPollLinkClick}
                          index={index}
                          oneTimeLink={false}
                          groupPoll
                          timeZone={timeZone}
                        />
                      ))}
                  </List>
                )}
              </>
            ) : null}
          </>
        </>
      )}
    </div>
  );
};

const durationOptions = [15, 30, 45, 60];

const Duration = ({ value, onChange }) => {
  const classes = useStyles();
  const [duration, setDuration] = useState(value);
  const [customDuration, setCustomDuration] = useState(value);

  useEffect(() => {
    if (value !== duration && value !== customDuration) {
      if (durationOptions.indexOf(value)) {
        setDuration(value);
        setCustomDuration(value);
      } else {
        setDuration(null);
        setCustomDuration(value);
      }
    }
  }, [value, duration, customDuration]);

  const handleDurationChange = (duration) => {
    setDuration(duration);
    setCustomDuration(duration);
    onChange(duration);
  };

  const handleCustomDurationChange = ({
    target: { value: customDuration },
  }) => {
    setDuration(null);
    setCustomDuration(customDuration);
    onChange(customDuration);
  };

  return (
    <div className={classes.duration}>
      <div style={{ display: "flex" }}>
        <div className={classes.buttonGroup}>
          {durationOptions.map((min) => (
            <div
              key={min}
              className={duration === min ? classes.itemActive : classes.item}
              onClick={() => handleDurationChange(min)}
            >
              <div className="value">{`${min}`}</div>
              <label>min</label>
            </div>
          ))}
        </div>
        <div
          className={duration === null ? classes.itemActive : classes.item}
          style={{ width: "auto", textAlign: "center" }}
        >
          <div>
            <input
              type="number"
              min={0}
              max={300}
              value={customDuration || 60}
              onChange={handleCustomDurationChange}
              style={{ border: 0, background: "transparent" }}
            />
            <br />
            <label htmlFor="custom-duration" className="no-wrap w-auto">
              custom min
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const SchedulingLinkCard = ({
  meetingLink,
  onOpenClick,
  onCopyClick,
  onDeleteClick,
  index,
  oneTimeLink,
  groupPoll,
  timeZone,
}) => {
  const classes = cardStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenClick = useCallback(() => {
    if (onOpenClick) onOpenClick(meetingLink);
    handleClose();
  }, [meetingLink, onOpenClick]);

  const handleCopyClick = useCallback(() => {
    if (onCopyClick) onCopyClick(meetingLink);
    handleClose();
  }, [meetingLink, onCopyClick]);

  const handleDeleteClick = useCallback(() => {
    if (onDeleteClick) onDeleteClick(meetingLink);
    handleClose();
  }, [meetingLink, onDeleteClick]);

  const handleEditCustomAvailabilityClick = useCallback(() => {
    if (!groupPoll) {
    }
    console.log("edit meeting link");
  }, [groupPoll]);

  return (
    <div
      key={meetingLink.id}
      data-smarty-id={meetingLink.id}
      data-smarty-type={!groupPoll ? "meeting-link" : "group-poll"}
      className={classes.listItemCard}
    >
      <ListItem style={{ marginBottom: 5 }}>
        <ListItemAvatar>
          <Avatar
            style={{
              color: "#3f51b5",
              marginRight: 10,
              cursor: "pointer",
              backgroundColor: "white",
            }}
            onClick={handleCopyClick}
          >
            <FilterNone />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <div
                onClick={handleEditCustomAvailabilityClick}
                style={{ cursor: "pointer" }}
              >
                {oneTimeLink ? (
                  <div style={{ fontSize: "12px", margin: "auto" }}>
                    {meetingLink.eventName}
                    <br />
                    {meetingLink.timePeriod === TimePeriod.ROLLING ? (
                      <>
                        &nbsp;
                        {moment(meetingLink.created)
                          .tz(timeZone)
                          .format("ddd, MMM D")}
                        &nbsp;- &nbsp;
                        {moment(meetingLink.created)
                          .tz(timeZone)
                          .add(
                            meetingLink.rollingTimeAmount,
                            meetingLink.rollingTimeUnit
                          )
                          .format("ddd, MMM D")}
                      </>
                    ) : meetingLink.timePeriod === TimePeriod.RANGE ? (
                      <>
                        &nbsp;
                        {moment(meetingLink.rangeStart)
                          .tz(timeZone)
                          .format("ddd, MMM D")}
                        &nbsp;- &nbsp;
                        {moment(meetingLink.rangeEnd)
                          .tz(timeZone)
                          .format("ddd, MMM D")}
                      </>
                    ) : meetingLink.timePeriod === TimePeriod.CUSTOM ? (
                      meetingLink.customRecurring ? (
                        meetingLink.customTimePeriod === TimePeriod.ROLLING ? (
                          <>
                            &nbsp;
                            {moment(meetingLink.created)
                              .tz(timeZone)
                              .format("ddd, MMM D")}
                            &nbsp;-&nbsp;
                            {moment(meetingLink.created)
                              .tz(timeZone)
                              .add(
                                meetingLink.customRollingTimeAmount,
                                meetingLink.customRollingTimeUnit
                              )
                              .format("ddd, MMM D")}
                          </>
                        ) : meetingLink.customTimePeriod ===
                          TimePeriod.RANGE ? (
                          <>
                            &nbsp;
                            {moment(meetingLink.customRangeStart)
                              .tz(timeZone)
                              .format("ddd, MMM D")}
                            &nbsp;- &nbsp;
                            {moment(meetingLink.customRangeEnd)
                              .tz(timeZone)
                              .format("ddd, MMM D")}
                          </>
                        ) : meetingLink.customTimePeriod ===
                          TimePeriod.INDEFINITELY ? (
                          <>&nbsp;Indefinitely</>
                        ) : null
                      ) : (
                        <>
                          &nbsp;
                          {moment(
                            Math.min(
                              ...meetingLink.customIntervals.map(
                                (interval) => interval.start
                              )
                            )
                          )
                            .tz(timeZone)
                            .format("ddd, MMM D")}
                          &nbsp;- &nbsp;
                          {moment(
                            Math.max(
                              ...meetingLink.customIntervals.map(
                                (interval) => interval.start
                              )
                            )
                          )
                            .tz(timeZone)
                            .format("ddd, MMM D")}
                        </>
                      )
                    ) : (
                      <>&nbsp;Indefinitely</>
                    )}
                  </div>
                ) : (
                  <>{meetingLink.eventName}</>
                )}
              </div>
            </>
          }
          secondary={
            <>
              <span className={classes.pos} style={{ color: "#0000008a" }}>
                <Schedule
                  color="disabled"
                  fontSize="small"
                  style={{
                    verticalAlign: "bottom",
                    marginRight: 5,
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                />
                {meetingLink.duration} minutes
              </span>
              <span className={classes.pos} style={{ color: "#0000008a" }}>
                {oneTimeLink ? (
                  ""
                ) : (
                  <>
                    <DateRange
                      color="disabled"
                      fontSize="small"
                      style={{
                        verticalAlign: "bottom",
                        marginRight: 5,
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                    {meetingLink.timePeriod === TimePeriod.ROLLING ? (
                      <>
                        &nbsp;
                        {moment(meetingLink.created)
                          .tz(timeZone)
                          .format("ddd, MMM D")}
                        &nbsp;- &nbsp;
                        {moment(meetingLink.created)
                          .tz(timeZone)
                          .add(
                            meetingLink.rollingTimeAmount,
                            meetingLink.rollingTimeUnit
                          )
                          .format("ddd, MMM D")}
                      </>
                    ) : meetingLink.timePeriod === TimePeriod.RANGE ? (
                      <>
                        &nbsp;
                        {moment(meetingLink.rangeStart)
                          .tz(timeZone)
                          .format("ddd, MMM D")}
                        &nbsp;- &nbsp;
                        {moment(meetingLink.rangeEnd)
                          .tz(timeZone)
                          .format("ddd, MMM D")}
                      </>
                    ) : meetingLink.timePeriod === TimePeriod.CUSTOM ? (
                      meetingLink.customRecurring ? (
                        meetingLink.customTimePeriod === TimePeriod.ROLLING ? (
                          <>
                            &nbsp;
                            {moment(meetingLink.created)
                              .tz(timeZone)
                              .format("ddd, MMM D")}
                            &nbsp;-
                            {/* eslint-disable-next-line max-len */}
                            &nbsp;
                            {moment(meetingLink.created)
                              .tz(timeZone)
                              .add(
                                meetingLink.customRollingTimeAmount,
                                meetingLink.customRollingTimeUnit
                              )
                              .format("ddd, MMM D")}
                          </>
                        ) : meetingLink.customTimePeriod ===
                          TimePeriod.RANGE ? (
                          <>
                            &nbsp;
                            {moment(meetingLink.customRangeStart)
                              .tz(timeZone)
                              .format("ddd, MMM D")}
                            &nbsp;- &nbsp;
                            {moment(meetingLink.customRangeEnd)
                              .tz(timeZone)
                              .format("ddd, MMM D")}
                          </>
                        ) : meetingLink.customTimePeriod ===
                          TimePeriod.INDEFINITELY ? (
                          <>&nbsp;Indefinitely</>
                        ) : null
                      ) : (
                        <>
                          &nbsp;
                          {moment(
                            Math.min(
                              ...meetingLink.customIntervals.map(
                                (interval) => interval.start
                              )
                            )
                          )
                            .tz(timeZone)
                            .format("ddd, MMM D")}
                          &nbsp;- &nbsp;
                          {moment(
                            Math.max(
                              ...meetingLink.customIntervals.map(
                                (interval) => interval.start
                              )
                            )
                          )
                            .tz(timeZone)
                            .format("ddd, MMM D")}
                        </>
                      )
                    ) : (
                      <>&nbsp;Indefinitely</>
                    )}
                  </>
                )}
              </span>
              <span className={classes.pos} style={{ color: "#0000008a" }}>
                {meetingLink.location ? (
                  <>
                    <LocationOn
                      color="disabled"
                      fontSize="small"
                      style={{
                        verticalAlign: "bottom",
                        marginRight: 5,
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                    {meetingLink.location}
                  </>
                ) : null}
              </span>
              <span className={classes.pos} style={{ color: "#0000008a" }}>
                {meetingLink.contacts && meetingLink.contacts.length ? (
                  <>
                    <GroupAdd
                      color="disabled"
                      fontSize="small"
                      style={{
                        verticalAlign: "bottom",
                        marginRight: 5,
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                    {meetingLink.contacts.map((guest) => (
                      <span key={guest.email}>{guest.email},</span>
                    ))}
                  </>
                ) : null}
              </span>
            </>
          }
        />
        <ListItemSecondaryAction style={{ top: "25px" }}>
          <IconButton aria-label="settings" onClick={handleMenu}>
            <MoreVert />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={!!anchorEl}
            onClose={handleClose}
          >
            {!groupPoll && (
              <MenuItem onClick={handleEditCustomAvailabilityClick}>
                <IconButton>
                  <Edit />
                </IconButton>
                Edit
              </MenuItem>
            )}
            <MenuItem onClick={handleOpenClick}>
              <IconButton>
                <OpenInNew />
              </IconButton>
              Open
            </MenuItem>
            <MenuItem onClick={handleDeleteClick}>
              <IconButton>
                <Delete />
              </IconButton>
              Delete
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </div>
  );
};