import { Settings$DTO, User$DTO } from "@trysmarty/shared";
import { get, post } from "./network";

export const getUser = async (username: string): Promise<User$DTO> =>
  get(`/backend/api/v1/user/${username}`);

export const saveSettings = (
  links: string[],
  settings: Settings$DTO
): Promise<{
  success: boolean;
  reason?: string;
}> =>
  post(`/backend/api/v1/settings`, {
    links,
    settings,
  });
