import { get, post } from "./network";
import { GroupPoll$ClosePollParams, GroupPoll$DTO } from "@trysmarty/shared";

export const getGroupPoll = (
  username: string,
  groupPollIdOrSlug: string,
  timeZone: string
): Promise<GroupPoll$DTO> =>
  get(
    `/backend/api/v1/scheduling/${username}/polls/${groupPollIdOrSlug}?timeZone=${timeZone}`
  );

export const voteInPoll = async (
  username: string,
  groupPollIdOrSlug: string,
  name: string,
  email: string,
  dates: Date[]
): Promise<GroupPoll$DTO> =>
  post(`/backend/api/v1/scheduling/${username}/polls/${groupPollIdOrSlug}`, {
    name,
    email,
    dates,
  });

export const closePoll = async (
  groupPollIdOrSlug: string,
  params: GroupPoll$ClosePollParams
): Promise<GroupPoll$DTO> =>
  post(`/backend/api/v1/scheduling/polls/${groupPollIdOrSlug}`, params);
