import React, { FC, ReactNode, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Theme,
} from "@material-ui/core";
import BackgroundImage from "../assets/avatarbanner.png";
import { UserContext } from "../UserContext";
import LinksDrawer from "./profile/LinksDrawer/LinksDrawer";

const useStyles = makeStyles<Theme, { bottomRoundedBorders: boolean }>(
  (theme) => ({
    primaryButton: {
      background: "#FF339A",
      color: "white",
      "&:hover": {
        background: "#b2236b",
      },
      "&:active": {
        background: "#ff5bae",
      },
    },
    "user-container-avatar-banner": {
      backgroundSize: "cover",
      display: "flex",
      borderRadius: ({ bottomRoundedBorders }) =>
        bottomRoundedBorders ? "4px 4px 0 0" : "4px",
      justifyContent: "space-around",
    },
    "margin-auto-important": {
      margin: "auto !important",
      padding: 25,
    },
    avatar: {
      gridColumnStart: 2,
      width: "fit-content",
      height: "auto",
      margin: "auto",
      marginTop: -80,
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    photo: {
      width: "fit-content",
      height: "auto",
      margin: "auto",
      borderRadius: 50,
    },
    margin: {
      marginLeft: 25,
    },
  })
);

const UserBanner: FC<{
  email: string;
  firstName?: string;
  lastName?: string;
  image?: string;
  links: string[];
  customToolbar?: ReactNode;
}> = ({ email, firstName, lastName, image, links, customToolbar }) => {
  const styles = useStyles({ bottomRoundedBorders: true });
  const { isAuthenticating, email: contextEmail } = useContext(UserContext);
  const location = window.location.href;

  if (isAuthenticating) return null;

  return (
    <>
      {email === contextEmail && !location.toLowerCase().includes("myaccount") && (
        <>
          <Typography variant="h6" style={{ display: "inline" }}>
            This is how your page looks online.{" "}
          </Typography>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/myAccount"
            style={{ display: "inline" }}
          >
            Change which social links are shown here.
          </Typography>
        </>
      )}
      <Paper className={styles.paperBox}>
        <Grid
          container
          className={styles["user-container-avatar-banner"]}
          style={{
            backgroundImage: `url(${BackgroundImage})`,
          }}
        >
          <Grid item className={styles["margin-auto-important"]}>
            <Avatar
              alt={`${firstName} ${lastName}`}
              className={styles.photo}
              src={image}
              style={{ width: "100px", height: "100px" }}
            />
          </Grid>

          <Grid
            item
            container
            direction="column"
            xs
            className={styles["margin-auto-important"]}
          >
            <Grid item>
              <Typography className={styles["user-profile-name"]}>
                {`${firstName} ${lastName}`}
              </Typography>
            </Grid>

            <Grid item>
              <LinksDrawer links={links} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={styles["margin-auto-important"]}>
          {customToolbar || null}
        </Grid>
      </Paper>
    </>
  );
};

export default UserBanner;
