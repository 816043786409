import React, { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import fullNameLogo from "../assets/fullnamelogo.png";

import { NotFoundContent } from "@trysmarty/components";

const NotFound: FC = () => {
  const location = useLocation();
  const message = useMemo(() => {
    if (location.state?.message) {
      const messages = location.state.message.split(": ");
      if (messages.length > 1) {
        return messages[1];
      }
      return messages[0];
    }
    return null;
  }, [location]);
  return (
    <NotFoundContent
      image={<img alt="" src={fullNameLogo} width={200} />}
      message={message}
    />
  );
};

export default NotFound;
