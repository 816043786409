import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props: { mobile: boolean }) => (props.mobile ? "100%" : "auto"),
    minHeight: "100vh",
    padding: theme.spacing(2),
    "& h1": {
      fontWeight: 1000,
    },
  },
  features: {
    maxWidth: 1000,
    "& h6": {
      marginTop: theme.spacing(4),
    },
  },
  button: {
    fontWeight: 1000,
    width: 240,
    height: 56,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  link: {
    display: "flex",
    lineHeight: "2em",
  },
  guide: {
    display: "flex",
    lineHeight: "2em",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& svg": {
      marginRight: theme.spacing(2),
    },
  },
  img: {
    width: "100%",
    height: "auto",
    borderRadius: theme.spacing(1),
  },
  backdrop: {
    width: "100%",
    backgroundColor: "#64b5f6",
    transform: "skew(0deg, -5deg) scale(0.85, 1.05)",
    borderRadius: "10%",
    "& > *": {
      transform: "skew(0deg, 4deg) scale(calc(1/0.85), calc(1/1.05))",
    },
  },
}));

const SignInSuccessPage: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const classes = useStyles({ mobile });

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="space-around"
        wrap="nowrap"
      >
        <Typography component="h1" variant="h3">
          {"Successfully signed in to "}
          <span style={{ color: "#ff0081" }}>Smarty</span>
        </Typography>

        <Grid>
          <Typography align="center">
            You can safely close this window
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SignInSuccessPage;
