import { get, post } from "./network";
import { ExitSurvey$DTO, Nullable } from "@trysmarty/shared";

export const getExitReasons = (): Promise<ExitSurvey$DTO[]> =>
  get(`/backend/api/v1/exit-surveys`);

export const createExitReason = async (
  mixpanelDistinctId: Nullable<string>,
  params: ExitSurvey$DTO
): Promise<void> => {
  let url = `/backend/api/v1/exit-surveys`;
  if (mixpanelDistinctId) {
    url += `?mid=${mixpanelDistinctId}`;
  }
  return post(url, params);
};
