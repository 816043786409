import mixpanel from "mixpanel-browser";
import * as FullStory from "@fullstory/browser";

const _mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
const _fullStoryOrgId = process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID;

export const initTracking = () => {
  try {
    if (_mixpanelToken) {
      mixpanel.init(_mixpanelToken);
    }
  } catch (error) {
    console.error("initAnalytics", "mixpanel", error);
  }

  try {
    if (_fullStoryOrgId) {
      FullStory.init({
        orgId: _fullStoryOrgId,
        host: "fullstory.com",
        script: "edge.fullstory.com/s/fs.js",
        debug: false,
      });
    }
  } catch (error) {
    console.error("initAnalytics", "fullstory", error);
  }
};

export const identifyUser = (username: string, email: string, name: string) => {
  try {
    if (_mixpanelToken) {
      mixpanel.identify(username);
      mixpanel.people.set({
        $name: name,
        $email: email,
      });
    }
  } catch (error) {
    console.error(error);
  }
  try {
    if (_fullStoryOrgId) {
      FullStory.identify(username, {
        displayName: name,
        email,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const anonymize = () => {
  if (_mixpanelToken) mixpanel.reset();
  if (_fullStoryOrgId) FullStory.anonymize();
};

export const trackEvent = (event, details = {}) => {
  if (_mixpanelToken) mixpanel.track(event, details);
  if (_fullStoryOrgId) FullStory.event(event, details);
};
