import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FeaturesCarousel from "../signin/FeaturesCarousel";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    gridRowGap: theme.spacing(2),
  },
  wrapper: {
    margin: 10,
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  margin: {
    marginLeft: 25,
  },
  fuchsiaButton: {
    backgroundColor: "#FF339A",
    fontSize: 18,
    width: 150,
    color: "white",
    "&:hover": {
      backgroundColor: "#b2236b",
    },
    "&:active": {
      backgroundColor: "#ff5bae",
    },
  },
  buttonFont: {
    width: 150,
    fontSize: 18,
  },
  root: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    height: (props: { mobile: boolean }) => (props.mobile ? "100%" : "auto"),
    flexGow: 1,
    padding: theme.spacing(2),
    "& h1": {
      fontWeight: 1000,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    padding: theme.spacing(4),
    marginTop: 10,
    backgroundColor: "black",
    color: "white",
  },
  imgContainer: {
    textAlign: "center",
  },
  features: {
    maxWidth: 1000,
    "& h6": {
      marginTop: theme.spacing(4),
    },
  },
  verticalAlignCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    fontWeight: 1000,
    width: "80%",
    margin: "0 10%",
    height: 56,
  },
  link: {
    display: "flex",
    lineHeight: "2em",
  },
  guide: {
    display: "flex",
    lineHeight: "2em",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& svg": {
      marginRight: theme.spacing(2),
    },
  },
  img: {
    width: "100%",
    height: "auto",
    borderRadius: theme.spacing(1),
  },
  backdrop: {
    width: "100%",
    backgroundColor: "#64b5f6",
    transform: "skew(0deg, -5deg) scale(0.85, 1.05)",
    borderRadius: "10%",
    "& > *": {
      transform: "skew(0deg, 4deg) scale(calc(1/0.85), calc(1/1.05))",
    },
  },
  stepper: {
    "& .MuiMobileStepper-dot": {
      backgroundColor: "white",
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: "rgb(255, 0, 129)",
    },
  },
}));

const MyTemplatesPage: FC = () => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const classes = useStyles({ mobile });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Paper className={classes.wrapper}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} md={4} style={{ height: "100%" }}>
              <Grid container style={{ height: "100%" }}>
                <Grid item xs={12} justifyContent="center" alignItems="center">
                  <Typography
                    component="h1"
                    variant="h3"
                    align="center"
                    className={classes.verticalAlignCenter}
                    style={{ margin: "auto", height: "25%" }}
                  >
                    Welcome &#128075;
                  </Typography>
                  <div
                    style={{ height: "50%", justifyContent: "start" }}
                    className={classes.verticalAlignCenter}
                  >
                    <Typography
                      component="h2"
                      variant="h4"
                      align="center"
                      style={{ paddingBottom: 50 }}
                    >
                      <span style={{ color: "#ff0081" }}>
                        Smarty is your shortcut!
                      </span>
                      <br />
                      <span style={{ fontSize: "18px" }}>
                        Work at light speed with time-saving features.
                      </span>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <FeaturesCarousel />
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default MyTemplatesPage;
