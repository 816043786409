import React from "react";
import moment from "moment-timezone";
import { IInterval } from "@trysmarty/shared";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import TimeSelect from "../../../components/ui/TimeSelect";

const useStyles = makeStyles(() => ({
  dayText: {
    width: 120,
    fontWeight: "bold",
  },
  darkButton: {
    background: "#7497AD",
    color: "white",
    padding: 10,
    borderRadius: 10,
    cursor: "pointer",
  },
}));

interface IComponentProps {
  workingHours: (IInterval<number>[] | null)[];
  onAddInterval: (day: number) => void;
  onRemoveInterval: (day: number, interval: number) => void;
  onUpdateStartTime: (day: number, interval: number, start: number) => void;
  onUpdateEndTime: (day: number, interval: number, end: number) => void;
}

const AvailableHours: React.FC<IComponentProps> | null = ({
  workingHours,
  onAddInterval,
  onRemoveInterval,
  onUpdateStartTime,
  onUpdateEndTime,
}: IComponentProps) => {
  const classes = useStyles();

  // const { settings } = useContext(UserContext);

  return (
    <Grid container direction="column">
      {workingHours.map(
        (workingHoursDay: IInterval<number>[] | null, dayOfWeek: number) => {
          if (workingHoursDay === null) return null;

          const handleAddInterval = (): void => {
            onAddInterval(dayOfWeek);
          };

          return (
            <Grid
              container
              item
              key={dayOfWeek}
              alignItems="center"
              justifyContent="space-between"
              style={{ marginBottom: 25 }}
            >
              <Typography className={classes.dayText}>
                {moment.weekdays(dayOfWeek)}
              </Typography>

              <Grid
                container
                item
                key={dayOfWeek}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
              >
                {workingHoursDay.map((interval, intervalIndex) => {
                  const handleRemoveInterval = (): void => {
                    onRemoveInterval(dayOfWeek, intervalIndex);
                  };

                  const handleUpdateStart = (event): void => {
                    const newStart = parseFloat(event.target.value);
                    if (newStart >= interval.end) {
                      onUpdateEndTime(dayOfWeek, intervalIndex, newStart + 0.5);
                    }
                    onUpdateStartTime(dayOfWeek, intervalIndex, newStart);
                  };

                  const handleUpdateEnd = (event): void => {
                    onUpdateEndTime(
                      dayOfWeek,
                      intervalIndex,
                      parseFloat(event.target.value)
                    );
                  };

                  const filterStartOptions = (time: number): boolean => {
                    if (intervalIndex === 0) {
                      return time < 24;
                    }
                    return (
                      time >= workingHoursDay[intervalIndex - 1].end &&
                      time < 24
                    );
                  };

                  const filterEndOptions = (time: number): boolean =>
                    time > interval.start;

                  return (
                    <Grid
                      container
                      item
                      key={intervalIndex}
                      alignItems="flex-end"
                      justifyContent="flex-end"
                    >
                      <TimeSelect
                        value={interval.start}
                        onChange={handleUpdateStart}
                        filterOptions={filterStartOptions}
                      />
                      <Typography>to</Typography>
                      <TimeSelect
                        value={interval.end}
                        onChange={handleUpdateEnd}
                        filterOptions={filterEndOptions}
                      />
                      {intervalIndex === 0 ? (
                        <Button
                          className={classes.darkButton}
                          onClick={handleAddInterval}
                          disabled={
                            workingHoursDay[workingHoursDay.length - 1].end ===
                            24
                          }
                        >
                          Add
                        </Button>
                      ) : (
                        <Button
                          className={classes.darkButton}
                          onClick={handleRemoveInterval}
                        >
                          Remove
                        </Button>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default AvailableHours as React.ComponentType<IComponentProps>;
