export const WORKING_HOURS_LABEL = "Allow Scheduling & Enable Working Hours";

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const useHours = (): { value: number; label: string }[] => {
  const hours: { value: number; label: string }[] = [];

  for (let i = 0; i <= 24; i += 0.5) {
    const meridian = i !== 24 && i >= 12 ? "pm" : "am";
    const hourLabel = i >= 12 ? i - 12 : i;
    const hour = Number.isInteger(hourLabel)
      ? `${hourLabel}:00`
      : `${~~hourLabel}:30`; // eslint-disable-line no-bitwise
    const label =
      hourLabel === 0
        ? `12:00 ${meridian}`
        : hourLabel === 0.5
        ? `12:30 ${meridian}`
        : `${hour} ${meridian}`;
    hours.push({
      value: i,
      label,
    });
  }
  return hours;
};
export const PROFILE_SAVE_ERROR =
  "We encountered some issues while saving your profile. Please try again later.";
export const BOOK_MEETING_ERROR =
  "Unable to book meeting. Please refresh and try again.";
export const DOUBLE_BOOK_ERROR =
  "The time you requested is no longer available. Please select a different time.";

export const CONFIRM_LOGOUT = {
  title: "Sign Out",
  description: "Are you sure you want to sign out?",
  confirm: "Sign out",
};

export const CONFIRM_MEETING_TIME_UNAVAILABLE = {
  description:
    "Sorry, but the meeting time you requested is no longer available. Please select a different time.",
  confirm: "OK",
};

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const TEXT_HOW_IT_WORKS = "How It Works";
export const TEXT_ABOUT_US = "About Us";
export const TEXT_PRICING = "Pricing";
