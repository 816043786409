import React, { useCallback, useState } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  Button,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import StarIcon from "@material-ui/icons/Star";

interface FavoriteLocationsSelectorProps {
  locations: string[];
  onLocationChange: (locations: string[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  box1: { display: "flex", alignItems: "center" },
  autocomplete: {
    width: "100%",
    marginRight: "5px",
  },
  addLocation: {
    width: "56px",
    height: "56px",
    backgroundColor: "#E8F7FF",
    borderColor: "#E8F7FF",
    color: "#4ABAF9",
    "&:hover": {
      backgroundColor: "darken($color-secondary-blue-light, 3%)",
    },
    "&:active-selected-listitem": {
      backgroundColor: "#4ABAF9",
      color: "#FFFFFF",
      borderColor: "#4ABAF9",
    },
  },
  box2: { display: "flex", alignItems: "center" },
  label: { margin: "2px" },
  star: { fontSize: "30px" },
  button: { color: "#4ABAF9", paddingLeft: "10px" },
}));

export const FavoriteLocationsSelector = ({
  locations = [],
  onLocationChange = () => {},
}: FavoriteLocationsSelectorProps) => {
  const styles = useStyles();
  const [selected, setSelected] = useState("");

  const {
    suggestions,
    value,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleAddLocationClick = useCallback(async () => {
    if (!locations.includes(value)) {
      onLocationChange([...locations, value]);
    }
    setValue("");
  }, [locations, onLocationChange, setValue, value]);

  const handleRemoveLocationClick = useCallback(
    async (location) => {
      //   trackEvent("handle remove location from Settings", { location });
      onLocationChange(locations.filter((l) => l !== location));
    },
    [locations, onLocationChange]
  );

  const handleSetFavoriteLocation = useCallback(
    async (location) => {
      //   trackEvent("set favorite location in Settings", { location });
      onLocationChange([location, ...locations.filter((l) => l !== location)]);
    },
    [locations, onLocationChange]
  );

  return (
    <div className={styles.root}>
      <div className={styles.box1}>
        <Autocomplete
          id="autoComplete"
          className={styles.autocomplete}
          freeSolo
          value={value}
          options={suggestions.data.map((p) => p.description)}
          getOptionLabel={(description) => description}
          onChange={(e, option) => {
            // is triggered when a location is selected from the drop down list
            e.preventDefault();
            e.stopPropagation();
            setValue(option || "", false);
            clearSuggestions();
          }}
          onInputChange={(e, option) => {
            // is triggered when the user types in the box
            setValue(option);
          }}
          renderOption={(option: string) => (
            <ListItemText
              key={option}
              title={option}
              primary={<Typography noWrap>{option}</Typography>}
            />
          )}
          renderInput={(props) => <TextField {...props} variant="outlined" />}
        />

        <Button
          id="AddLocation"
          type="button"
          onClick={handleAddLocationClick}
          className={styles.addLocation}
          disabled={!value}
        >
          <AddCircleOutlineIcon />
        </Button>
      </div>
      {locations &&
        locations.map((location, index) => (
          <div
            className={styles.box2}
            id={`location_${index}`}
            key={`${location}_${index}`}
            onMouseLeave={() => setSelected("")}
            onMouseEnter={() => setSelected(location)}
          >
            <Typography
              id={`location_label_${index}`}
              className={styles.label}
              variant="h6"
            >
              {location}
            </Typography>
            {index === 0 ? (
              <StarIcon
                id={`location_favorite_${index}`}
                className={styles.star}
              />
            ) : selected === location ? (
              <Button
                id={`location_remove_${index}`}
                type="button"
                title="add favorite location"
                onClick={() => handleSetFavoriteLocation(location)}
                hidden={selected !== location}
                variant="text"
                className={styles.button}
              >
                Make favorite
              </Button>
            ) : null}
            {selected === location && (
              <Button
                id={`location_remove_${index}`}
                type="button"
                title="remove favorite location"
                onClick={() => handleRemoveLocationClick(location)}
                variant="text"
                className={styles.button}
              >
                Delete
              </Button>
            )}
          </div>
        ))}
    </div>
  );
};
