import React, { FC, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import LinkInput from "../../../components/settings/LinkInput/LinkInput";
import { UserContext } from "../../../UserContext";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  showcaseRelevantLinks: {
    marginBottom: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  row: {
    width: "fit-content",
  },
  dragIcon: {
    opacity: 0.3,
    transition: "all 250ms",

    "&:hover, &:active": {
      opacity: 1,
    },
  },
}));

const EditLinks: FC = () => {
  const styles = useStyles();
  const { links, changeLinks } = useContext(UserContext);

  const addNewLink = (): void => {
    changeLinks([...links, ""]);
  };

  const reorderLinks = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }
    const newLinks = Array.from(links);
    const [removed] = newLinks.splice(result.source.index, 1);
    newLinks.splice(result.destination.index, 0, removed);
    changeLinks([...newLinks]);
  };

  const handleLinkRemove = useCallback(
    (idx: number) => {
      changeLinks(links.filter((item, idx1) => idx1 !== idx));
    },
    [links, changeLinks]
  );

  const handleLinkUpdate = useCallback(
    (link: string, idx: number) => {
      const newLinks = [...links];
      newLinks[idx] = link;
      changeLinks(newLinks);
    },
    [links, changeLinks]
  );

  return (
    <Grid container alignItems="center" direction="column">
      <Grid
        item
        className={styles.showcaseRelevantLinks}
        style={{ width: "100%" }}
      >
        <Typography variant="h6">Edit Links</Typography>
        Showcase relevant links about you on your page.
      </Grid>
      <Grid item>
        <DragDropContext onDragEnd={reorderLinks}>
          <Droppable droppableId="links">
            {(provided) => (
              <Grid
                container
                direction="column"
                alignContent="center"
                spacing={1}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {links.map((link: string, idx: number) => (
                  <Draggable
                    key={link}
                    draggableId={`${link}-${idx}`}
                    index={idx}
                  >
                    {(provided_) => (
                      <Grid
                        item
                        container
                        className={styles.row}
                        ref={provided_.innerRef}
                        {...provided_.draggableProps}
                        {...provided_.dragHandleProps}
                      >
                        <DragIndicatorIcon className={styles.dragIcon} />
                        <LinkInput
                          idx={idx}
                          link={link}
                          onRemove={handleLinkRemove}
                          onUpdate={handleLinkUpdate}
                        />
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <Grid item>
        <Button
          className={styles.addButton}
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={addNewLink}
        >
          Add Link
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditLinks;
