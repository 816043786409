import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import moment, { max } from "moment-timezone";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import {
  bookAppointment,
  cancelBooking,
  getAvailableSlots,
  getMeetingLinkEventParams,
  getMeetingLinkParams,
  updateBooking,
} from "../../../services/scheduling";

import MeetingOptions from "../../../components/ui/MeetingOptions/MeetingOptions";
import {
  ErrorDialog,
  EventBookedView,
  EventConfirmView,
  EventDateView,
} from "@trysmarty/components";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import {
  CircularProgress,
  InputLabel,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import {
  getBrowserTimeZone,
  IAvailableSlots,
  MeetingLink$Params,
  TimePeriod,
} from "@trysmarty/shared";
import PeopleIcon from "@material-ui/icons/People";
import { UserContext } from "../../../UserContext";
import UserBanner from "../../UserBanner";

const buildMeetingLinkUrl = (meetingLinkParams: MeetingLink$Params) =>
  `/u/${meetingLinkParams.user?.username}/meet/${
    meetingLinkParams.meetingLink.slug ?? meetingLinkParams.meetingLink.id
  }`;

const buildEventUrl = (changeToken: string) => `/events/${changeToken}`;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    maxWidth: 800,
    width: "100%",
    gridRowGap: theme.spacing(2),
    maxHeight: 800,
  },

  grid: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
  },

  wrapper: {
    height: 625,
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  loading: {
    height: 625,
  },
  pageContainer: {
    minHeight: (props: { mobile: boolean }): number =>
      props.mobile ? 700 : 380,
    position: "relative",
  },
  page: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  button: {
    width: 200,
  },
  otherHosts: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

const CalendarPage: FC = () => {
  const history = useHistory();

  const mobile = useMediaQuery("(max-width: 600px)");
  const styles = useStyles({ mobile });

  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorContent, setErrorContent] = useState("");

  const handleErrorDialogOpen = () => {
    setErrorDialogOpen(true);
  };
  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
    setErrorContent("");
  };

  const { settings } = useContext(UserContext);
  const { username, meetingLinkIdOrSlug, changeToken } = useParams<
    Record<string, string | undefined>
  >();

  const [loading, setLoading] = useState<boolean>(true);

  const [
    meetingLinkParams,
    setMeetingLinkParams,
  ] = useState<MeetingLink$Params>();
  const [availableSlots, setAvailableSlots] = useState<
    IAvailableSlots | undefined
  >();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<number>(0);
  const [eventTextDate, setEventTextDate] = useState<string>("");
  const [duration, setDuration] = useState<number>();
  const [minDate, setMinDate] = useState<Date | undefined>();
  const [maxDate, setMaxDate] = useState<Date | undefined>();
  const [timeZone, setTimeZone] = useState<string>(getBrowserTimeZone());
  const [meetingDurationText, setMeetingDurationText] = useState("");

  const [eventName, setEventName] = useState<string>("");
  const [attendees, setAttendees] = useState<string[]>([]);
  const [eventTitle, setEventTitle] = useState<string>("");
  const [location, setLocation] = useState<string | undefined>("");
  const [notes, setNotes] = useState<string | undefined>("");

  useEffect(() => {
    if (settings) {
      setDuration(settings.duration);
    }
  }, [settings]);

  const handleChangeEventName = (eventName: string) => {
    setEventName(eventName);
  };
  const handleChangeAttendees = (attendees: string[]) => {
    setAttendees([...attendees]);
  };
  const handleChangeEventTitle = (title: string) => {
    setEventTitle(title);
  };
  const handleChangeLocation = (location?: string) => {
    setLocation(location);
  };
  const handleChangeNotes = (notes: string) => {
    setNotes(notes);
  };
  const calculateAvailableSlotsInMonth = async (
    meetingLinkParams: MeetingLink$Params,
    selectedMonth: Date,
    timeZone: string,
    minDate?: Date
  ): Promise<IAvailableSlots> => {
    const startOfMonth = moment(selectedMonth).tz(timeZone).startOf("months");

    let start: moment.Moment;
    if (moment(selectedMonth).isBefore(moment(minDate))) {
      // the link starts after selected date
      start = startOfMonth;
    } else {
      start = minDate ? max(startOfMonth, moment(minDate)) : startOfMonth;
    }

    let end;
    if (meetingLinkParams.meetingLink.timePeriod === TimePeriod.RANGE) {
      start = moment(meetingLinkParams.meetingLink.rangeStart);
      end = moment(meetingLinkParams.meetingLink.rangeEnd);
    } else if (
      meetingLinkParams.meetingLink.timePeriod === TimePeriod.CUSTOM &&
      meetingLinkParams.meetingLink.customIntervals &&
      meetingLinkParams.meetingLink.customIntervals.length > 0
    ) {
      start = moment(
        Math.min(
          ...meetingLinkParams.meetingLink.customIntervals.map((x) => x.start)
        )
      ).tz(timeZone);
      end = moment(
        Math.max(
          ...meetingLinkParams.meetingLink.customIntervals.map((x) => x.end)
        )
      ).tz(timeZone);
    }

    const availableSlots = await getAvailableSlots(
      meetingLinkParams.user.username,
      timeZone,
      start.toDate(),
      end?.toDate(),
      meetingLinkParams.meetingLink.id
    );

    if (meetingLinkParams?.scheduledEvent?.start) {
      availableSlots.slots.push(
        new Date(meetingLinkParams?.scheduledEvent?.start!).getTime()
      );
      availableSlots.slots.sort((a, b) => a - b);
    }

    return availableSlots;
  };

  const fetchMeetingLinkParams = useCallback(
    async (
      username: string | null,
      meetingLinkIdOrSlug: string | null,
      changeToken: string | null
    ) => {
      let meetingLinkParams: MeetingLink$Params | undefined;
      let initialMinDate: moment.Moment | undefined;
      let initialMaxDate: moment.Moment | undefined;
      try {
        if (changeToken) {
          meetingLinkParams = await getMeetingLinkEventParams(changeToken);
        } else if (username && meetingLinkIdOrSlug) {
          meetingLinkParams = await getMeetingLinkParams(
            username,
            meetingLinkIdOrSlug
          );
        } else {
          history.push("/404");
          return;
        }
      } catch (err) {
        history.push({
          pathname: "/404",
          state: { message: `${err}` },
        });
      }

      if (!meetingLinkParams) return;
      setMeetingLinkParams(meetingLinkParams);

      switch (meetingLinkParams.meetingLink.timePeriod) {
        case TimePeriod.ROLLING:
          initialMinDate = moment(meetingLinkParams.meetingLink.created);
          initialMaxDate = moment(meetingLinkParams.meetingLink.created)
            .tz(meetingLinkParams.meetingLink.timeZone)
            .add(
              meetingLinkParams.meetingLink.rollingTimeAmount,
              meetingLinkParams.meetingLink.rollingTimeUnit
            );
          break;
        case TimePeriod.RANGE:
          initialMinDate = moment(meetingLinkParams.meetingLink.rangeStart);
          initialMaxDate = moment(meetingLinkParams.meetingLink.rangeEnd);
          break;
        case TimePeriod.CUSTOM:
          if (
            meetingLinkParams.meetingLink.customRecurring ||
            !meetingLinkParams.meetingLink.customIntervals?.length
          ) {
            switch (meetingLinkParams.meetingLink.customTimePeriod) {
              case TimePeriod.ROLLING:
                initialMinDate = moment(meetingLinkParams.meetingLink.created);
                initialMaxDate = moment(meetingLinkParams.meetingLink.created)
                  .tz(meetingLinkParams.meetingLink.timeZone)
                  .add(
                    meetingLinkParams.meetingLink.customRollingTimeAmount,
                    meetingLinkParams.meetingLink.customRollingTimeUnit
                  );
                break;
              case TimePeriod.RANGE:
                initialMinDate = moment(
                  meetingLinkParams.meetingLink.customRangeStart
                );
                initialMaxDate = moment(
                  meetingLinkParams.meetingLink.customRangeEnd
                );
                break;
              default:
                initialMinDate = moment();
                break;
            }
          } else {
            initialMinDate = moment(
              Math.min(
                ...meetingLinkParams.meetingLink.customIntervals.map(
                  (x) => x.start
                )
              )
            ).tz(timeZone);
            initialMaxDate = moment(
              Math.max(
                ...meetingLinkParams.meetingLink.customIntervals.map(
                  (x) => x.end
                )
              )
            ).tz(timeZone);
          }
          break;
        case TimePeriod.INDEFINITELY:
        default:
          initialMinDate = moment();
          break;
      }
      const newDuration = meetingLinkParams.meetingLink.duration;
      setDuration(newDuration);

      const newMinDate = max(initialMinDate, moment()).toDate();
      setMinDate(newMinDate);

      if (initialMaxDate) setMaxDate(initialMaxDate!.toDate());
      if (meetingLinkParams.meetingLink.location) {
        setLocation(meetingLinkParams.meetingLink.location);
      }

      const nextAvailableSlots = await calculateAvailableSlotsInMonth(
        meetingLinkParams,
        newMinDate,
        timeZone,
        newMinDate
      );

      setAvailableSlots(nextAvailableSlots);
      let nextAvailableSlot;
      if (nextAvailableSlots.slots.length) {
        nextAvailableSlot = new Date(Math.min(...nextAvailableSlots.slots));
        setMinDate(nextAvailableSlot);
      } else {
        history.push({
          pathname: "/404",
          state: {
            message: "Unfortunately, there are no more available times.",
          },
        });
      }

      if (meetingLinkParams.scheduledEvent && meetingLinkParams.googleEvent) {
        setEventName(meetingLinkParams.scheduledEvent.eventName);
        setAttendees([...meetingLinkParams.scheduledEvent.attendees]);
        setEventTitle(meetingLinkParams.googleEvent.summary ?? "");

        if (meetingLinkParams.googleEvent.location) {
          setLocation(meetingLinkParams.googleEvent.location);
        }

        setNotes(meetingLinkParams.googleEvent.description?.split("<p>")[0]);

        const timeZone =
          meetingLinkParams.scheduledEvent.timeZone || getBrowserTimeZone();
        const start = moment(meetingLinkParams.googleEvent.start).tz(timeZone);
        const end = moment(meetingLinkParams.googleEvent.end).tz(timeZone);

        setEventTextDate(
          `${start.format("h:mma")} - ${end.format("h:mma")}, ${start.format(
            "dddd MMMM D, YYYY"
          )}`
        );

        setSelectedDate(new Date(meetingLinkParams?.scheduledEvent?.start!));
        setSelectedTime(
          new Date(meetingLinkParams?.scheduledEvent?.start!).getTime()
        );
      } else {
        if (nextAvailableSlot) setSelectedDate(nextAvailableSlot);
        setSelectedTime(0);

        setEventName("");
        setAttendees(
          meetingLinkParams.meetingLink.contacts?.map((c) => c.email) ?? []
        );
        setEventTitle(meetingLinkParams.meetingLink.eventName ?? "");
        setLocation(meetingLinkParams.meetingLink.location ?? "");
        setNotes("");
        setEventTextDate("");
      }
    },
    [timeZone, history]
  );

  useEffect(
    () => {
      fetchMeetingLinkParams(username, meetingLinkIdOrSlug, changeToken)
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleReschedule = useCallback(async () => {
    setLoading(true);
    await fetchMeetingLinkParams(
      meetingLinkParams?.meetingLink?.username!,
      meetingLinkParams?.meetingLink?.slug ??
        meetingLinkParams?.meetingLink?.id!,
      changeToken
    );

    history.replace(`${buildEventUrl(changeToken)}/calendar`);
    setLoading(false);
  }, [fetchMeetingLinkParams, meetingLinkParams, changeToken, history]);

  const handleDurationChange = useCallback(
    async (duration: number) => {
      setDuration(duration);
      setLoading(true);
      try {
        setAvailableSlots(
          await calculateAvailableSlotsInMonth(
            meetingLinkParams!,
            selectedDate!,
            timeZone,
            minDate
          )
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [meetingLinkParams, selectedDate, minDate, timeZone]
  );

  const handleTimeZoneChange = useCallback(
    async (timeZone: string) => {
      setTimeZone(timeZone);
      setLoading(true);
      try {
        setAvailableSlots(
          await calculateAvailableSlotsInMonth(
            meetingLinkParams!,
            selectedDate!,
            timeZone,
            minDate
          )
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [meetingLinkParams, selectedDate, minDate]
  );

  const handleSelectedDateChange = useCallback(
    async (date: Date) => {
      setSelectedDate(date);
      if (!moment(date).isSame(selectedDate, "months")) {
        setLoading(true);
        try {
          setAvailableSlots(
            await calculateAvailableSlotsInMonth(
              meetingLinkParams!,
              date,
              timeZone,
              minDate
            )
          );
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [selectedDate, meetingLinkParams, timeZone, minDate]
  );

  const reset = (): void => {
    // eslint-disable-next-line no-self-assign
    window.location = window.location; // TODO improve reset after booking
  };

  useEffect(() => {
    if (!duration) return;
    if (!selectedTime) return;
    const start = moment(selectedTime).tz(timeZone);
    const end = start.clone().add(duration, "minutes");
    setMeetingDurationText(`${start.format("h:mma")} - ${end.format("h:mma")}`);
  }, [duration, selectedTime, timeZone]);

  const bookMeeting = useCallback(
    async (payload: {
      name: string;
      emails: string[];
      eventName?: string;
      location?: string;
      notes?: string;
    }): Promise<void> => {
      if (!duration) return;
      setLoading(true);
      try {
        const { success, changeToken } = await bookAppointment(timeZone, {
          ...payload,
          duration,
          time: selectedTime,
          meetingLinkIdOrSlug: meetingLinkParams?.meetingLink!.id!,
        });

        if (success) {
          const start = moment(selectedTime).tz(timeZone);
          setEventTextDate(
            `${start.format("h:mma")} - ${start
              .clone()
              .add(duration, "minutes")
              .format("h:mma")}, ${start.format("dddd MMMM D, YYYY")}`
          );

          history.replace(`${buildEventUrl(changeToken!)}`);
        }
      } catch (err) {
        const message = (err as string).toString().split(": ");
        if (message.length > 1 && message[1] === "double_booked") {
          history.push({
            pathname: "/404",
            state: {
              message:
                "Sorry, but the meeting time you requested is no longer available. Please select a different time.",
            },
          });
        } else {
          history.push({
            pathname: "/404",
            state: {
              message: "Unable to book meeting. Please refresh and try again.",
            },
          });
        }
        handleErrorDialogOpen();
      } finally {
        setLoading(false);
      }
    },
    [meetingLinkParams, duration, timeZone, selectedTime, history]
  );

  const updateBookMeeting = useCallback(
    async (payload: {
      name: string;
      emails: string[];
      eventName?: string;
      location?: string;
      notes?: string;
    }): Promise<void> => {
      if (!duration) return;
      setLoading(true);
      try {
        const { success } = await updateBooking(
          timeZone,
          meetingLinkParams?.scheduledEvent?.changeToken!,
          {
            ...payload,
            duration,
            time: selectedTime,
            meetingLinkIdOrSlug: meetingLinkParams?.meetingLink!.id!,
          }
        );

        if (success) {
          const start = moment(selectedTime).tz(timeZone);
          setEventTextDate(
            `${start.format("h:mma")} - ${start
              .clone()
              .add(duration, "minutes")
              .format("h:mma")}, ${start.format("dddd MMMM D, YYYY")}`
          );

          history.replace(
            `${buildEventUrl(meetingLinkParams?.scheduledEvent?.changeToken!)}`
          );
        }
      } catch (err) {
        const message = (err as string).toString().split(": ");

        if (message.length > 1 && message[1] === "double_booked") {
          history.push({
            pathname: "/404",
            state: {
              message:
                "Sorry, but the meeting time you requested is no longer available. Please select a different time.",
            },
          });
        } else {
          history.push({
            pathname: "/404",
            state: {
              message: "Unable to book meeting. Please refresh and try again.",
            },
          });
        }
        handleErrorDialogOpen();
      } finally {
        setLoading(false);
      }
    },
    [duration, timeZone, selectedTime, meetingLinkParams, history]
  );

  const handleDelete = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      history.replace(buildMeetingLinkUrl(meetingLinkParams!));
      await cancelBooking(changeToken);
      await fetchMeetingLinkParams(
        meetingLinkParams?.meetingLink?.username!,
        meetingLinkParams?.meetingLink?.slug ??
          meetingLinkParams?.meetingLink?.id!,
        null
      );
    } catch (err) {
      console.error(err);
      throw new Error("Error deleting meeting.");
    } finally {
      setLoading(false);
    }
  }, [changeToken, history, meetingLinkParams, fetchMeetingLinkParams]);

  const handleSelectedTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleEventConfirmBack = useCallback(async () => {
    if (isErrorDialogOpen) {
      setLoading(true);
      try {
        setSelectedTime(0);
        setAvailableSlots(
          await calculateAvailableSlotsInMonth(
            meetingLinkParams!,
            selectedDate!,
            timeZone,
            minDate
          )
        );
        handleErrorDialogClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (meetingLinkParams?.scheduledEvent) {
      history.push(
        `${buildEventUrl(
          meetingLinkParams?.scheduledEvent?.changeToken!
        )}/calendar`
      );
    } else {
      history.push(`${buildMeetingLinkUrl(meetingLinkParams!)}/calendar`);
    }
  }, [
    history,
    isErrorDialogOpen,
    meetingLinkParams,
    minDate,
    selectedDate,
    timeZone,
  ]);

  const handleConfirmTime = useCallback(() => {
    if (meetingLinkParams?.scheduledEvent) {
      history.push(
        `${buildEventUrl(
          meetingLinkParams.scheduledEvent.changeToken!
        )}/confirm`
      );
    } else {
      history.push(`${buildMeetingLinkUrl(meetingLinkParams!)}/confirm`);
    }
  }, [history, meetingLinkParams]);

  const meetingOptionsHeader = useCallback(
    () => (
      <Grid className={styles.otherHosts}>
        <MeetingOptions
          disabled={loading}
          canChangeDuration={false}
          duration={duration!}
          onDurationChange={handleDurationChange}
          timeZone={timeZone}
          onTimeZoneChange={handleTimeZoneChange}
        />
        {!selectedTime && meetingLinkParams?.meetingLink?.contacts?.length ? (
          <InputLabel>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <PeopleIcon />
              </Grid>
              <Grid item>
                <Typography
                  display="inline"
                  align="center"
                  style={{ marginLeft: 8 }}
                >
                  {meetingLinkParams?.meetingLink
                    .contacts!.map((guest) => guest.email)
                    .join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </InputLabel>
        ) : null}
      </Grid>
    ),
    [
      duration,
      handleDurationChange,
      handleTimeZoneChange,
      loading,
      meetingLinkParams,
      selectedTime,
      styles.otherHosts,
      timeZone,
    ]
  );

  if (!duration) return null;
  if (!selectedDate) return null;

  return (
    <div className={styles.root}>
      <Paper className={styles.container}>
        <UserBanner
          email={meetingLinkParams?.user.email!}
          firstName={meetingLinkParams?.user.firstName}
          lastName={meetingLinkParams?.user.lastName}
          image={meetingLinkParams?.user.image}
          links={meetingLinkParams?.user.links!}
        />
        {loading ? (
          <Fade in={loading}>
            <Grid
              className={styles.loading}
              container
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Grid>
          </Fade>
        ) : (
          <div className={styles.wrapper}>
            <Switch>
              <Route exact path="/u/:username/meet/:meetingLinkIdOrSlug">
                <Redirect
                  to={`/u/${meetingLinkParams?.user?.username}/meet/${meetingLinkIdOrSlug}/calendar`}
                />
              </Route>
              <Route
                exact
                path="/u/:username/meet/:meetingLinkIdOrSlug/calendar"
              >
                <>
                  {meetingOptionsHeader()}
                  <EventDateView
                    disabled={loading}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectedDate={selectedDate}
                    timeZone={timeZone}
                    availableSlots={availableSlots}
                    selectedTime={selectedTime}
                    onSelectedDateChange={handleSelectedDateChange}
                    onSelectedTimeChange={handleSelectedTimeChange}
                    onConfirmTime={handleConfirmTime}
                  />
                </>
              </Route>
              <Route
                exact
                path="/u/:username/meet/:meetingLinkIdOrSlug/confirm"
              >
                {!selectedTime ? (
                  <Redirect
                    to={`/u/${meetingLinkParams?.user?.username}/meet/${
                      meetingLinkParams?.meetingLink?.slug ??
                      meetingLinkParams?.meetingLink?.id
                    }`}
                  />
                ) : (
                  <>
                    {meetingOptionsHeader()}
                    <div className="event-confirm-view">
                      <EventConfirmView
                        selectedDate={selectedDate}
                        meetingDurationText={meetingDurationText}
                        onBack={handleEventConfirmBack}
                        onBookMeeting={bookMeeting}
                        onUpdateBookMeeting={updateBookMeeting}
                        contacts={
                          meetingLinkParams?.meetingLink?.contacts || []
                        }
                        eventChangeToken={changeToken}
                        eventName={eventName}
                        attendees={attendees}
                        eventTitle={eventTitle}
                        location={location}
                        notes={notes}
                        onChangeEventName={handleChangeEventName}
                        onChangeAttendees={handleChangeAttendees}
                        onChangeEventTitle={handleChangeEventTitle}
                        onChangeLocation={handleChangeLocation}
                        onChangeNotes={handleChangeNotes}
                      />
                    </div>
                  </>
                )}
              </Route>
              <Route exact path="/events/:changeToken">
                <div className="event-confirm-view">
                  <EventBookedView
                    eventTextDate={eventTextDate}
                    reset={reset}
                    timeZone={timeZone}
                    eventChangeToken={changeToken}
                    eventName={eventName}
                    location={location}
                    handleReschedule={handleReschedule}
                    handleDelete={handleDelete}
                  />
                </div>
              </Route>
              <Route exact path="/events/:changeToken/calendar">
                <>
                  {meetingOptionsHeader()}
                  <EventDateView
                    disabled={loading}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectedDate={selectedDate}
                    timeZone={timeZone}
                    availableSlots={availableSlots}
                    selectedTime={selectedTime}
                    onSelectedDateChange={handleSelectedDateChange}
                    onSelectedTimeChange={handleSelectedTimeChange}
                    onConfirmTime={handleConfirmTime}
                  />
                </>
              </Route>
              <Route exact path="/events/:changeToken/confirm">
                {!selectedTime ? (
                  <Redirect to={`${buildEventUrl(changeToken!)}`} />
                ) : (
                  <div className="event-confirm-view">
                    {meetingOptionsHeader()}
                    <EventConfirmView
                      selectedDate={selectedDate}
                      meetingDurationText={meetingDurationText}
                      onBack={handleEventConfirmBack}
                      onBookMeeting={bookMeeting}
                      onUpdateBookMeeting={updateBookMeeting}
                      contacts={meetingLinkParams?.meetingLink?.contacts || []}
                      eventChangeToken={changeToken}
                      eventName={eventName}
                      attendees={attendees}
                      eventTitle={eventTitle}
                      location={location}
                      notes={notes}
                      onChangeEventName={handleChangeEventName}
                      onChangeAttendees={handleChangeAttendees}
                      onChangeEventTitle={handleChangeEventTitle}
                      onChangeLocation={handleChangeLocation}
                      onChangeNotes={handleChangeNotes}
                    />
                  </div>
                )}
              </Route>
              <Route>
                <Redirect to="/404" />
              </Route>
            </Switch>
          </div>
        )}
      </Paper>
      <ErrorDialog
        title={null}
        content={errorContent}
        open={isErrorDialogOpen}
        onConfirm={handleEventConfirmBack}
      />
    </div>
  );
};

export default CalendarPage;
