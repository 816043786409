import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { EMAIL_REGEXP } from "../../configs/ui";
import {
  getBrowserTimeZone,
  getTimeZones,
  GroupPoll$ClosePollParams,
  GroupPoll$DTO,
  GroupPoll$Selection,
  GroupPoll$State,
  Nullable,
  User$DTO,
} from "@trysmarty/shared";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { closePoll, getGroupPoll, voteInPoll } from "../../services/groupPoll";
import moment from "moment-timezone";
import { Autocomplete } from "@material-ui/lab";
import RoomIcon from "@material-ui/icons/Room";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import PeopleIcon from "@material-ui/icons/People";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import IconButton from "@material-ui/core/IconButton";
import PublicIcon from "@material-ui/icons/Public";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Chip from "@material-ui/core/Chip";
import UserBanner from "../UserBanner";
import { getUser } from "../../services/User";
import { UserContext } from "../../UserContext";

const nextCodes = [" ", ",", "Enter", ";", "/"];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    gridRowGap: theme.spacing(2),
  },

  grid: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
  },
  titleListItem: {
    overflowY: "auto",
    height: "230px",
    "& .MuiListItem-container": {
      listStyleType: "none",
    },
  },
  gridList: {
    height: "350px",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    "& .MuiGridListTileBar-titleWrap": {
      color: "#1f678f",
    },
  },
  select: {
    width: "100%",
  },
  wrapper: {
    margin: 10,
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& .MuiInputLabel-formControl": {
      top: "15px",
      left: "5px",
    },
  },
  pageContainer: {
    minHeight: (props: { mobile: boolean }): number =>
      props.mobile ? 1000 : 500,
    position: "relative",
  },
  button: {
    width: 200,
  },
  bookedIcon: {
    backgroundColor: "#ff0081",
    width: 56,
    height: 56,
    transform: "rotate(-15deg)",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      backgroundColor: "#fff",
      width: 56,
      height: 56,
      borderRadius: theme.spacing(1),
      transform: "rotate(15deg)",
    },
  },
}));

const GroupPollPage = () => {
  const history = useHistory();
  const mobile = useMediaQuery("(max-width: 650px)");
  const styles = useStyles({ mobile });
  const { username, groupPollIdOrSlug } = useParams<Record<string, string>>();
  const [user, setUser] = useState<User$DTO>();
  const { username: currentUserName } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [groupPoll, setGroupPoll] = useState<Nullable<GroupPoll$DTO>>(null);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [userValidated, setUserValidated] = useState<boolean>(false);
  const [timeZone, setTimeZone] = useState<string>(getBrowserTimeZone());
  const [selectedDate, setSelectedDate] = useState<Nullable<Date>>(null);
  const [selectedTimes, setSelectedTimes] = useState<Date[]>([]);
  const [confirmedDate, setConfirmedDate] = useState<Nullable<Date>>(null);
  const [booked] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [emailValue, setEmailValue] = useState<string>("");
  const [emailsError, setEmailsError] = useState<boolean>(false);

  const [eventName, setEventName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const handleNameChange = ({ target: { value } }) => {
    setName(value);
  };

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const handleValidateUserClick = useCallback(() => {
    setUserValidated(true);
    const currentUserSelection = groupPoll!.selection.find(
      (s) => s.email === email
    );
    if (currentUserSelection) {
      // the user has already selected times
      setSelectedTimes(currentUserSelection.dates.map((d) => new Date(d)));
    } else {
      // it's a new user
      // @ts-ignore
      const newGroupPoll: GroupPoll$DTO = { ...groupPoll };
      newGroupPoll.selection.push({
        name,
        email,
        dates: [],
      });
      setGroupPoll(newGroupPoll);
    }
  }, [email, groupPoll, name]);

  const timeZones = useMemo(() => getTimeZones(), []);

  const handleTimezoneChange = (e, value): void => {
    setTimeZone(value.value);
  };

  const handleSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  const handleToggleTime = useCallback(
    (date: Date, isSelected: boolean) => {
      setSelectedTimes((prev) => {
        let newSelectedDates;
        if (!isSelected) {
          newSelectedDates = [...prev].filter(
            (d) => d.getTime() !== date.getTime()
          );
        } else {
          newSelectedDates = [...prev, date];
        }

        // optimistic update: update frontend while updating the backend
        // @ts-ignore
        const newGroupPoll: GroupPoll$DTO = { ...groupPoll };
        newGroupPoll.selection.find(
          (s) => s.email === email
        )!.dates = newSelectedDates;
        setGroupPoll(newGroupPoll);

        return newSelectedDates;
      });
    },
    [email, groupPoll]
  );

  const handleClosePollClick = useCallback(
    async (date: Date) => {
      try {
        const groupPollParams: GroupPoll$ClosePollParams = {
          date,
          emails,
          eventName,
          location,
          notes,
          groupPollIdOrSlug,
        };
        await closePoll(groupPollIdOrSlug, groupPollParams);
        setGroupPoll(await getGroupPoll(username, groupPollIdOrSlug, timeZone));
      } catch (error) {
        setError(error);
        history.push({
          pathname: "/404",
          state: { message: `${error}` },
        });
      }
    },
    [
      emails,
      eventName,
      location,
      notes,
      groupPollIdOrSlug,
      username,
      timeZone,
      history,
    ]
  );

  useEffect(() => {
    const init = async (): Promise<void> => {
      setLoading(true);

      // todo(hmassad) replace with NotFound component after merging backend calls into a single one
      try {
        try {
          setUser(await getUser(username));
        } catch (error) {
          history.push("/404");
        }

        const groupPoll = await getGroupPoll(
          username,
          groupPollIdOrSlug,
          timeZone
        );

        setGroupPoll(groupPoll);

        if (groupPoll.eventName) {
          setEventName(groupPoll.eventName);
        }
        if (groupPoll.location) {
          setLocation(groupPoll.location);
        }
        if (groupPoll.selection) {
          const emailAddresses: string[] = [];
          // eslint-disable-next-line
          groupPoll.selection.map((s) => {
            const emailAddress = s.email;
            emailAddresses.push(emailAddress);
          });
          setEmails(emailAddresses);
        }
      } catch (error) {
        console.error(error);
        setError(error);
        history.push({
          pathname: "/404",
          state: { message: `${error}` },
        });
      } finally {
        setLoading(false);
      }
    };
    init().catch();
    // eslint-disable-next-line
  }, []);

  const handleEventNameChange = (e): void => {
    setEventName(e.target.value);
  };

  const handleLocationChange = (e): void => {
    setLocation(e.target.value);
  };

  const handleNotesChange = (e): void => {
    setNotes(e.target.value);
  };

  const handleDeleteEmail = (email, index): void => {
    if (emails) {
      let newEmails: string[] = [];
      if (emails.indexOf(email) > -1) {
        newEmails = emails.filter((_, deletedIndex) => deletedIndex !== index);
      }
      setEmails(newEmails);
      setEmailValue("");
    }
  };

  const handleEmailConfirmPageChange = (e): void => {
    setEmailValue(e.target.value);
  };

  const updateEmail = useCallback(
    (value) => {
      const match = value.match(EMAIL_REGEXP);
      if (match && emails) {
        const email_ = match[0];
        let newEmails: string[];
        if (emails.indexOf(email_) > -1) newEmails = [...emails];
        else newEmails = [...emails, email_];
        setEmails(newEmails);
        setEmailValue("");
      }
    },
    [emails]
  );

  const handleEmailFocus = (): void => {
    setEmailsError(false);
  };

  const handleEmailKeyUp = (e): void => {
    if (nextCodes.includes(e.key)) {
      updateEmail(e.target.value);
    }
  };

  const handleEmailBlur = (e): void => {
    updateEmail(e.target.value);
  };

  const handleConfirmationOfDate = (newDate): void => {
    setConfirmedDate(newDate);
    if (groupPoll && groupPoll.selection) {
      setEmails(groupPoll.selection.map((s) => s.email));
    }
  };

  const timerRef = useRef<Nullable<NodeJS.Timeout>>();

  useEffect(() => {
    if (!userValidated) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      voteInPoll(username, groupPollIdOrSlug, name, email, selectedTimes).catch(
        (error) => {
          setError(error);
          console.error(error);
        }
      );
      timerRef.current = null;
    }, 1000);
  }, [username, groupPollIdOrSlug, name, email, userValidated, selectedTimes]);

  if (error) {
    return error.toString();
  }

  if (loading) {
    return (
      <div className={styles.root}>
        <Paper className={styles.container}>
          <div className={styles.wrapper}>
            <Fade in={loading}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Grid>
            </Fade>
          </div>
        </Paper>
      </div>
    );
  }

  if (groupPoll?.state === GroupPoll$State.Archived) {
    return (
      <div className={styles.root}>
        <Paper className={styles.container}>
          <UserBanner
            email={user!.email}
            firstName={user!.firstName}
            lastName={user!.lastName}
            image={user!.image}
            links={user!.links}
          />
          <div className={styles.wrapper}>
            <Grid
              style={{ height: 300 }}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              wrap="nowrap"
            >
              <div className="booked-event-details">
                {eventName && (
                  <h3 style={{ textAlign: "center" }}>
                    <CheckCircleOutlineIcon
                      width={6}
                      height={6}
                      style={{ marginRight: 15, color: "rgb(30 103 143)" }}
                    />
                    {eventName} - Poll closed
                  </h3>
                )}
                <div style={{ fontSize: 18 }}>
                  <CalendarTodayIcon
                    style={{ marginRight: 15, color: "#4ABAF9" }}
                  />
                  {moment(groupPoll.selectedDate).tz(timeZone).format("LLLL")} -{" "}
                  {moment(groupPoll.selectedDate)
                    .add(groupPoll.duration, "minutes")
                    .format("h:mm a")}
                </div>
                <div>
                  <PublicIcon
                    width={6}
                    height={6}
                    style={{ marginRight: 15, color: "#4ABAF9" }}
                  />
                  {timeZone}
                </div>
              </div>
              <div className={styles.bookedIcon}>
                <CheckBoxIcon viewBox="4 4 16 16" htmlColor="#4ABAF9" />
              </div>
              <Typography gutterBottom variant="h5" style={{ fontWeight: 600 }}>
                Confirmed
              </Typography>
              <Typography gutterBottom>
                A calendar invitation has been sent to all relevant attendees
              </Typography>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Paper className={styles.container}>
        <UserBanner
          email={user!.email}
          firstName={user!.firstName}
          lastName={user!.lastName}
          image={user!.image}
          links={user!.links}
        />
        <div className={styles.wrapper}>
          {groupPoll && groupPoll.username === currentUserName ? (
            <>
              <Grid container>
                {confirmedDate ? (
                  <>
                    <Grid container alignItems="center">
                      <Fade in={!booked}>
                        <IconButton
                          aria-label="change date or time"
                          onClick={() => setConfirmedDate(null)}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Fade>
                      {!booked ? (
                        <>
                          <CalendarTodayIcon style={{ marginRight: 8 }} />
                          <div style={{ fontSize: 18 }}>
                            {`
                          ${moment(confirmedDate).format("LLLL")} -
                          ${moment(confirmedDate)
                            .add(groupPoll.duration, "minutes")
                            .format("h:mm a")}
                        `}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <div style={{ width: "100%" }}>
                      {!booked && (
                        <Fade in={!booked}>
                          <form noValidate autoComplete="off">
                            <Grid
                              container
                              direction="column"
                              spacing={2}
                              style={{ width: "100%" }}
                            >
                              <Grid
                                item
                                container
                                direction="column"
                                spacing={1}
                              >
                                <Grid item container spacing={1}>
                                  {emails &&
                                    emails.map((email_) => (
                                      <Grid key={email_} item />
                                    ))}
                                </Grid>
                                <Grid item />
                              </Grid>
                              <Grid item>
                                <TextField
                                  id="eventName"
                                  label="Event Title"
                                  type="input"
                                  helperText="Enter a name for your event."
                                  fullWidth
                                  value={eventName}
                                  onChange={handleEventNameChange}
                                />
                              </Grid>
                              <Grid
                                item
                                container
                                direction="column"
                                spacing={1}
                              >
                                <Grid item>
                                  <PeopleIcon /> Attendees: <br />
                                </Grid>
                                <Grid item container spacing={1}>
                                  {emails &&
                                    emails.map((email_, index) => (
                                      <Grid key={email_} item>
                                        <Fade in>
                                          <Chip
                                            icon={<AccountCircleOutlinedIcon />}
                                            label={email_}
                                            onDelete={() =>
                                              handleDeleteEmail(email_, index)
                                            }
                                            color="primary"
                                            variant="outlined"
                                          />
                                        </Fade>
                                      </Grid>
                                    ))}
                                </Grid>
                                <Grid item>
                                  <TextField
                                    id="emails"
                                    type="email"
                                    label="Attendee Email(s)"
                                    value={emailValue}
                                    onChange={handleEmailConfirmPageChange}
                                    onKeyUp={handleEmailKeyUp}
                                    onFocus={handleEmailFocus}
                                    onBlur={handleEmailBlur}
                                    fullWidth
                                    error={emailsError}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <TextField
                                  id="location"
                                  label="Location"
                                  type="input"
                                  helperText="Please enter the meeting location."
                                  fullWidth
                                  value={location}
                                  onChange={handleLocationChange}
                                />
                              </Grid>
                              <Grid item className="event-description-field">
                                <TextField
                                  id="notes"
                                  type="input"
                                  multiline
                                  rows={2}
                                  label="Any notes to add?"
                                  fullWidth
                                  value={notes}
                                  onChange={handleNotesChange}
                                />
                              </Grid>
                              <Grid item>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className="dark-button"
                                    onClick={() => setConfirmedDate(null)}
                                  >
                                    Back
                                  </div>
                                  <div
                                    className="primary-button"
                                    onClick={() =>
                                      handleClosePollClick(confirmedDate)
                                    }
                                  >
                                    Schedule Event
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </form>
                        </Fade>
                      )}
                      {booked && (
                        <Fade in={booked}>
                          <Grid
                            style={{ height: 300 }}
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            wrap="nowrap"
                          >
                            <div className="booked-event-details">
                              {eventName && (
                                <div
                                  style={{ fontSize: 18, textAlign: "center" }}
                                >
                                  <CheckCircleOutlineIcon
                                    width={6}
                                    height={6}
                                    style={{
                                      marginRight: 15,
                                      color: "rgb(30 103 143)",
                                    }}
                                  />
                                  {eventName}
                                </div>
                              )}
                              <div style={{ fontSize: 18 }}>
                                <CalendarTodayIcon
                                  style={{ marginRight: 15, color: "#4ABAF9" }}
                                />
                                {moment(confirmedDate).format("LLLL")}
                              </div>
                              <div>
                                <PublicIcon
                                  width={6}
                                  height={6}
                                  style={{ marginRight: 15, color: "#4ABAF9" }}
                                />
                                {timeZone}
                              </div>
                            </div>
                            <div>
                              <CheckBoxIcon
                                viewBox="4 4 16 16"
                                htmlColor="#4ABAF9"
                              />
                            </div>
                            <Typography
                              gutterBottom
                              variant="h5"
                              style={{ fontWeight: 600 }}
                            >
                              Confirmed
                            </Typography>
                            <Typography gutterBottom>
                              A calendar invitation has been sent to your email
                              address
                            </Typography>
                            <Button>OK</Button>
                          </Grid>
                        </Fade>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {groupPoll.selection.length > 0 ? (
                      <div style={{ width: "100%" }}>
                        <h3>Choose a date to create an event</h3>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <b>Date:</b>
                                </TableCell>
                                <TableCell>
                                  <b>Time:</b>
                                </TableCell>
                                <TableCell align="center">
                                  <b># of people who chose this time:</b>
                                </TableCell>
                                {groupPoll.recurring ? (
                                  <>
                                    <TableCell align="right" />
                                  </>
                                ) : (
                                  <>
                                    <TableCell align="right">
                                      <b>Create Event?</b>
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {groupPoll?.selection
                                .reduce(
                                  (
                                    slots: { date: Date; count: number }[],
                                    s: GroupPoll$Selection
                                  ) => {
                                    s.dates.forEach((selectedDate) => {
                                      const sss = slots.find(
                                        (d) =>
                                          new Date(d.date).getTime() ===
                                          new Date(selectedDate).getTime()
                                      );
                                      if (sss) {
                                        sss.count++;
                                      } else {
                                        slots.push({
                                          date: new Date(selectedDate),
                                          count: 1,
                                        });
                                      }
                                    });
                                    return slots;
                                  },
                                  []
                                )
                                .sort((a, b) => b.count - a.count)
                                .map((s) => (
                                  <TableRow key={s.date.getTime()}>
                                    <TableCell component="th" scope="row">
                                      {groupPoll.recurring ? (
                                        <>
                                          {moment(s.date)
                                            .tz(timeZone)
                                            .format("dddd")}
                                        </>
                                      ) : (
                                        <>
                                          {moment(s.date)
                                            .tz(timeZone)
                                            .format("dddd, MMMM Do YYYY")}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {moment(s.date)
                                        .tz(timeZone)
                                        .format("h:mm a")}{" "}
                                      -{" "}
                                      {moment(s.date)
                                        .add(groupPoll.duration, "minutes")
                                        .format("h:mm a")}
                                    </TableCell>
                                    <TableCell align="right">
                                      {s.count}
                                    </TableCell>
                                    <TableCell align="right">
                                      {groupPoll.recurring ? (
                                        <></>
                                      ) : (
                                        <Button
                                          onClick={() =>
                                            handleConfirmationOfDate(s.date)
                                          }
                                        >
                                          Select
                                        </Button>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <h3>No one has chosen any of the below times:</h3>
                        <Grid container alignItems="center">
                          <Grid item sm={6} xs={12}>
                            <div>
                              <h3>Dates</h3>
                              <GridList className={styles.gridList} cols={2.5}>
                                {groupPoll!
                                  .slots!.reduce((days: Date[], slot) => {
                                    const key = moment(slot)
                                      .tz(timeZone)
                                      .startOf("days");
                                    if (
                                      !days.some((x) => moment(x).isSame(key))
                                    ) {
                                      days.push(key.toDate());
                                    }
                                    days.sort(
                                      (a, b) => a.getTime() - b.getTime()
                                    );
                                    return days;
                                  }, [])
                                  .map((date) => {
                                    const isSelected =
                                      selectedDate &&
                                      date.getTime() === selectedDate.getTime();
                                    return (
                                      <GridListTile
                                        key={date.getTime()}
                                        onClick={() => handleSelectedDate(date)}
                                        style={{
                                          width: "150px",
                                          margin: "10px",
                                          height: "75px",
                                          textAlign: "center",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <GridListTileBar
                                          title={`${moment(date)
                                            .tz(timeZone)
                                            .format("dddd")}${
                                            isSelected ? "*" : ""
                                          }`}
                                          subtitle={
                                            groupPoll!.recurring
                                              ? ""
                                              : moment(date)
                                                  .tz(timeZone)
                                                  .format("MM/DD/YY")
                                          }
                                          style={{
                                            backgroundColor: "white",
                                            border: "solid 1px #1f678f",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      </GridListTile>
                                    );
                                  })}
                              </GridList>
                            </div>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <h3>Times</h3>
                            <div
                              className={styles.titleListItem}
                              style={{ height: "350px" }}
                            >
                              {groupPoll!
                                // transform into moment
                                .slots!.map((slot) => moment(slot).tz(timeZone))
                                // slots for selectedDate
                                .filter((slot) =>
                                  slot.isSame(
                                    moment(selectedDate).tz(timeZone),
                                    "days"
                                  )
                                )
                                .map((slot) => {
                                  // find users who chose this slot
                                  const users: string[] = groupPoll!.selection.reduce(
                                    (
                                      _users: string[],
                                      s: GroupPoll$Selection
                                    ) => {
                                      if (
                                        _users.indexOf(s.email) < 0 &&
                                        s.dates?.some((d) => slot.isSame(d))
                                      )
                                        _users.push(s.email);
                                      return _users;
                                    },
                                    []
                                  );
                                  const allUsersCount = groupPoll!.selection
                                    .length;
                                  return (
                                    <ListItem key={slot.valueOf()}>
                                      <ListItemText
                                        primary={
                                          groupPoll!.recurring
                                            ? moment(slot.toISOString())
                                                .tz(timeZone)
                                                .format("h:mm a on dddd")
                                            : moment(slot.toISOString())
                                                .tz(timeZone)
                                                .format("LLLL")
                                        }
                                        secondary={`${users.length}/${allUsersCount} chose this`}
                                      />
                                    </ListItem>
                                  );
                                })}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2} alignItems="center">
                {!userValidated && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: "25px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    Fill in your name and email to start voting
                  </Grid>
                )}
                <Grid item sm={6} xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <Input
                      id="name"
                      autoComplete="name"
                      disabled={userValidated}
                      value={name}
                      onChange={handleNameChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <Input
                      id="email"
                      autoComplete="email"
                      disabled={userValidated}
                      value={email}
                      onChange={handleEmailChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {!userValidated ? (
                <div
                  style={{
                    marginTop: "25px",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidateUserClick}
                  >
                    OK
                  </Button>
                </div>
              ) : (
                <>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={6} xs={12}>
                      <Autocomplete
                        className={`${styles.select}`}
                        id="userTimezone"
                        value={
                          timeZones.find(
                            (option) => option && option.value === timeZone
                          ) || undefined
                        }
                        onChange={handleTimezoneChange}
                        disableClearable
                        openOnFocus
                        options={timeZones}
                        getOptionLabel={(option) =>
                          option ? option.label : ""
                        }
                        renderInput={(params): any => (
                          <TextField
                            {...params}
                            label={
                              <Grid container alignItems="center">
                                <RoomIcon width={6} height={6} />
                                <Typography> Timezone </Typography>
                              </Grid>
                            }
                            margin="normal"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <InputLabel>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <PeopleIcon />
                          </Grid>
                          <Grid item>
                            <Typography display="inline" align="center">
                              {groupPoll!.selection
                                .map((s) =>
                                  s.name ? `${s.name} (${s.email})` : s.email
                                )
                                .join(", ")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </InputLabel>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={6} xs={12}>
                      <div>
                        <h3>Dates</h3>
                        <GridList className={styles.gridList} cols={2.5}>
                          {groupPoll!
                            .slots!.reduce((days: Date[], slot) => {
                              const key = moment(slot)
                                .tz(timeZone)
                                .startOf("days");
                              if (!days.some((x) => moment(x).isSame(key))) {
                                days.push(key.toDate());
                              }
                              days.sort((a, b) => a.getTime() - b.getTime());
                              return days;
                            }, [])
                            .map((date) => {
                              const isSelected =
                                selectedDate &&
                                date.getTime() === selectedDate.getTime();
                              return (
                                <GridListTile
                                  key={date.getTime()}
                                  onClick={() => handleSelectedDate(date)}
                                  style={{
                                    width: "150px",
                                    margin: "10px",
                                    height: "75px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <GridListTileBar
                                    title={`${moment(date)
                                      .tz(timeZone)
                                      .format("dddd")}${isSelected ? "*" : ""}`}
                                    subtitle={
                                      groupPoll!.recurring
                                        ? ""
                                        : moment(date)
                                            .tz(timeZone)
                                            .format("MM/DD/YY")
                                    }
                                    style={{
                                      background: "white",
                                      border: "solid 1px #1f678f",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </GridListTile>
                              );
                            })}
                        </GridList>
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <h3>Times</h3>
                      <div
                        className={styles.titleListItem}
                        style={{ height: "350px" }}
                      >
                        {groupPoll!
                          // transform into moment
                          .slots!.map((slot) => moment(slot).tz(timeZone))
                          // slots for selectedDate
                          .filter((slot) =>
                            slot.isSame(
                              moment(selectedDate).tz(timeZone),
                              "days"
                            )
                          )
                          .map((slot) => {
                            // find users who chose this slot
                            const users: string[] = groupPoll!.selection.reduce(
                              (_users: string[], s: GroupPoll$Selection) => {
                                if (
                                  _users.indexOf(s.email) < 0 &&
                                  s.dates?.some((d) => slot.isSame(d))
                                )
                                  _users.push(s.email);
                                return _users;
                              },
                              []
                            );
                            const allUsersCount = groupPoll!.selection.length;
                            const selected = selectedTimes.some((st) =>
                              slot.isSame(moment(st))
                            );
                            return (
                              <ListItem
                                style={{ cursor: "pointer" }}
                                key={slot.valueOf()}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleToggleTime(slot.toDate(), !selected);
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={selected}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleToggleTime(
                                        slot.toDate(),
                                        !selected
                                      );
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    groupPoll!.recurring
                                      ? moment(slot.toISOString())
                                          .tz(timeZone)
                                          .format("h:mm a on dddd")
                                      : moment(slot.toISOString())
                                          .tz(timeZone)
                                          .format("LLLL")
                                  }
                                  secondary={`${users.length}/${allUsersCount} chose this`}
                                />
                              </ListItem>
                            );
                          })}
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default GroupPollPage;
