import React, { FC, useState, useContext, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment-timezone";
import LocationSelect from "./Location";
import {
  Select,
  MenuItem,
  OutlinedInput,
  useMediaQuery,
  TextField,
  Typography,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  Language,
  Repeat,
  AddLocation,
  VideoCall,
  ExpandMore,
  AddAlert,
  Close,
  Add,
  Work,
} from "@material-ui/icons";
import { IInterval } from "@trysmarty/shared";
import { UserContext } from "../../../UserContext";

function quillConfigFactory() {
  return {
    modules: {
      toolbar: [
        [
          { color: [] },
          "bold",
          "italic",
          "underline",
          { list: "ordered" },
          { list: "bullet" },
          "link",
        ],
      ],
    },
  };
}

const useStyles = makeStyles((theme) => ({
  dottedSpaced: {
    height: "5px",
    textAlign: "center",
    lineHeight: "100px",
    background: "linear-gradient(90deg,#d8d8d8 50%,hsla(0,0%,100%,0) 0)",
    backgroundPosition: "top,100%,bottom,0",
    backgroundRepeat: "repeat-x,repeat-y",
    backgroundSize: "10px 1px,1px 10px",
    fontSize: "1px",
    marginTop: "20px",
  },
  meetingHeader: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "10px 0 5px",
    textAlign: "left",
  },
  createEventContainer: {
    margin: "0 10px",
    padding: "0 10px",
    "& .quill": {
      background: "white",
    },
  },
  calEventTitle: {
    width: "100%",
    "& .MuiInputBase-root": {
      margin: "0 !important",
      borderBottom: "solid 1px black !important",
      borderRadius: 0,
      border: 0,
      "& .MuiInputBase-input": {
        color: "black",
      },
    },
  },
  calEventAttendees: {
    width: "100%",
    marginBottom: 10,
    "& .MuiOutlinedInput-input": {
      padding: 10,
    },
  },
  headingCalAttendees: {
    fontSize: 14,
    textAlign: "left",
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeadingCalAttendees: {
    fontSize: 14,
    textAlign: "left",
  },
  calEventDateTime: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiFormControl-root, .MuiInput-formControl": {
      border: 0,
      fontSize: 14,
      padding: 0,
      margin: "0px !important",
    },
  },
  conferencingSelect: {
    width: "100%",
    padding: "10px",
    textAlign: "left",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

interface CalendarItem {
  name: string;
  id: string;
  color: string;
  primary?: boolean;
  accessRole: string;
}

interface IComponentProps {
  slot: IInterval<Date> | undefined;
  slots?: IInterval<Date>[];
  calendarList: CalendarItem[];
}

export const CreateEventMode: FC<IComponentProps> = ({
  slot,
  calendarList,
}: IComponentProps) => {
  const mobile = useMediaQuery("(max-width: 650px)");
  const classes = useStyles({ mobile });
  const [location, setLocation] = useState("");
  const handleLocationChange = (value) => {
    setLocation(value);
  };
  const { modules } = useMemo(quillConfigFactory, []);
  const [description, setDescription] = useState(
    "<br/><br/><br/>Created with <a href='https://smarty.ai'>Smarty</a>"
  );

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const { settings } = useContext(UserContext);
  const [calEventColor, setCalEventColor] = React.useState(
    "Current Default Color"
  );
  const [allDay, setAllDay] = React.useState(false);
  const [calListSelected, setCalListSelected] = React.useState("");
  const [freeBusy, setFreeBusy] = React.useState("busy");
  const [visbility, setVisibility] = React.useState("default");
  const [conferencing, setConferencing] = React.useState("GoogleMeet");
  const [guestPermissions, setGuestPermissions] = React.useState({
    modifyEvents: false,
    inviteOthers: true,
    seeGuestList: true,
  });

  const handleFreeBusy = (event) => {
    setFreeBusy(event.target.value);
  };

  const handleVisbility = (event) => {
    setVisibility(event.target.value);
  };

  useEffect(
    () => {
      if (calendarList.length > 0) {
        setCalListSelected(calendarList[0].name);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendarList]
  );

  const handleCalListSelect = (event) => {
    setCalListSelected(event.target.value);
  };

  const handleCalEventColorChange = (event) => {
    setCalEventColor(event.target.value);
  };

  const handleGuestPermissionsChange = (event) => {
    setGuestPermissions({
      ...guestPermissions,
      [event.target.name]: event.target.checked,
    });
  };

  const toggleAllDayChange = () => {
    setAllDay(!allDay);
  };

  const handleConferencingChange = (event) => {
    setConferencing(event.target.value);
  };

  return (
    <div
      className={classes.createEventContainer}
      style={{
        margin: "0 10px",
        padding: "0 10px",
      }}
    >
      {slot && (
        <form noValidate>
          <TextField
            id="standard-basic"
            placeholder="Enter title"
            className={classes.calEventTitle}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "table" }}>
              <Typography
                variant="h6"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                Date + Time
              </Typography>
            </div>
            <div>
              All day?
              <Switch
                checked={allDay}
                onChange={toggleAllDayChange}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </div>
          <div style={{ display: "table" }}>
            <Typography
              variant="caption"
              style={{ display: "table-cell", verticalAlign: "middle" }}
            >
              Start
            </Typography>
          </div>
          <div className={classes.calEventDateTime}>
            <TextField
              id="date"
              type="date"
              value={moment(slot.start).format("YYYY-MM-DD")}
              className={classes.textField}
            />
            <TextField
              id="time"
              type="time"
              value={moment(slot.start).format("hh:mm")}
              className={classes.textField}
            />
          </div>
          <div style={{ display: "table" }}>
            <Typography
              variant="caption"
              style={{ display: "table-cell", verticalAlign: "middle" }}
            >
              End
            </Typography>
          </div>
          <div className={classes.calEventDateTime}>
            <TextField
              id="date"
              type="date"
              value={moment(slot.end).format("YYYY-MM-DD")}
              className={classes.textField}
            />
            <TextField
              id="time"
              type="time"
              value={moment(slot.end).format("hh:mm")}
              className={classes.textField}
            />
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                <Repeat />
              </div>
            </div>
            <div style={{ display: "table" }}>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                Does not repeat
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                <Language />
              </div>
            </div>
            <div style={{ display: "table" }}>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                {settings?.timezone}
              </div>
            </div>
          </div>
          <div className={classes.dottedSpaced} />
          
          <div className={classes.meetingHeader}>
            Conferencing/Location ***
            <LocationSelect
              location={location}
              onLocationChange={handleLocationChange}
              readOnly={false}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "table" }}>
              <Typography
                variant="h6"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                Location
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                <AddLocation />
              </div>
            </div>
            <div style={{ display: "table", width: "100%" }}>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <TextField
                  id="standard-basic"
                  placeholder="Location"
                  className={classes.calEventTitle}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                <VideoCall />
              </div>
            </div>
            <div style={{ display: "table", width: "100%" }}>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={conferencing}
                  onChange={handleConferencingChange}
                  className={classes.conferencingSelect}
                >
                  <MenuItem value="GoogleMeet">Google Meet</MenuItem>
                  <MenuItem value="OtherLink">Other conferencing link</MenuItem>
                  <MenuItem value="Phone">Phone</MenuItem>
                  <MenuItem value="NoConferencing">No conferencing</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className={classes.dottedSpaced} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "table" }}>
              <Typography
                variant="h6"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                Attendees
              </Typography>
            </div>
          </div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="Add attendees"
            className={classes.calEventAttendees}
            autoComplete="off"
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.headingCalAttendees}>
                <b>Guests can:</b>
              </Typography>
              <Typography className={classes.secondaryHeadingCalAttendees}>
                {guestPermissions.modifyEvents ? "Modify events, " : ""}
                {guestPermissions.modifyEvents ? <br /> : ""}
                {guestPermissions.inviteOthers ? "Invite others, " : ""}
                {guestPermissions.inviteOthers ? <br /> : ""}
                {guestPermissions.seeGuestList ? "See guest list " : ""}
                {guestPermissions.seeGuestList ? <br /> : ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormLabel
                component="legend"
                style={{
                  width: "36%",
                  fontSize: "14px",
                  textAlign: "left",
                  margin: "25px 10px 0 0",
                }}
              >
                Select guest permissions:
              </FormLabel>
              <FormGroup style={{ textAlign: "left" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={guestPermissions.modifyEvents}
                      onChange={handleGuestPermissionsChange}
                      name="modifyEvents"
                    />
                  }
                  label="Modify events"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={guestPermissions.inviteOthers}
                      onChange={handleGuestPermissionsChange}
                      name="inviteOthers"
                    />
                  }
                  label="Invite others"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={guestPermissions.seeGuestList}
                      onChange={handleGuestPermissionsChange}
                      name="seeGuestList"
                    />
                  }
                  label="See guest list"
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <div className={classes.dottedSpaced} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "table" }}>
              <Typography
                variant="h6"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                Description
              </Typography>
            </div>
          </div>
          <ReactQuill
            readOnly={false}
            modules={modules}
            value={description}
            onChange={handleDescriptionChange}
          />
          <div className={classes.dottedSpaced} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "table" }}>
              <Typography
                variant="h6"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                Calendar + Event Color
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <Select
              value={calEventColor}
              input={<OutlinedInput />}
              onChange={handleCalEventColorChange}
            >
              <MenuItem value="tomato">Tomato</MenuItem>
              <MenuItem value="flamingo">Flamingo</MenuItem>
              <MenuItem value="tangerine">Tangerine</MenuItem>
              <MenuItem value="banana">Banana</MenuItem>
              <MenuItem value="sage">Sage</MenuItem>
              <MenuItem value="sage">Basil</MenuItem>
              <MenuItem value="sage">Peacock</MenuItem>
              <MenuItem value="sage">Blueberry</MenuItem>
              <MenuItem value="sage">Lavender</MenuItem>
              <MenuItem value="sage">Grape</MenuItem>
              <MenuItem value="sage">Graphite</MenuItem>
            </Select>
            <Select
              defaultValue={calendarList[0].name}
              value={calListSelected}
              onChange={handleCalListSelect}
            >
              {calendarList?.map((cal, index) => (
                <MenuItem value={index}>{cal.name}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.dottedSpaced} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "table" }}>
              <Typography
                variant="h6"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                Reminders
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                <AddAlert />
              </div>
            </div>
            <div>Notification 30 minutes before</div>
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  marginRight: 10,
                }}
              >
                <Close style={{ fontSize: 12 }} />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                <Add />
              </div>
            </div>
            <div>Add additional reminders</div>
          </div>
          <div className={classes.dottedSpaced} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "table" }}>
              <Typography
                variant="h6"
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                Availability + Visibility
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "table" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                <Work />
              </div>
            </div>
            <div>
              <Select
                defaultValue="busy"
                value={freeBusy}
                input={<OutlinedInput />}
                onChange={handleFreeBusy}
              >
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="busy">Busy</MenuItem>
              </Select>
            </div>
            <div>
              <Select
                defaultValue="busy"
                value={visbility}
                input={<OutlinedInput />}
                onChange={handleVisbility}
              >
                <MenuItem value="default">Default</MenuItem>
                <MenuItem value="private">Private</MenuItem>
                <MenuItem value="public">Public</MenuItem>
              </Select>
            </div>
          </div>
          <div className={classes.dottedSpaced} />
        </form>
      )}
    </div>
  );
};
