export class RequestError extends Error {
  constructor(status: number, message: string) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.name = "RequestError";
    this.status = status;
  }

  private status: number;
}

export default RequestError;
