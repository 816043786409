export enum GroupPoll$State {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Archived = "ARCHIVED",
}

export interface GroupPoll$Selection {
  email: string;
  name: string;
  dates: Date[];
}

export interface GroupPoll$DTO {
  id: string;
  username: string;
  created: Date;
  updated: Date;
  state: GroupPoll$State;
  opening?: Date;
  expiration?: Date;
  eventName: string;
  location?: string;
  duration?: number;
  slug?: string;
  slots: string[];
  selection: GroupPoll$Selection[];
  selectedDate: Date;
  recurring: Boolean;
}

export interface GroupPoll$ClosePollParams {
  groupPollIdOrSlug: string;
  date: Date;
  emails: string[];
  eventName: string;
  location?: string;
  notes?: string;
}
